define('genesis-residual-system/authenticators/custom', ['exports', 'ember', 'ember-simple-auth/authenticators/base', 'genesis-residual-system/config/environment'], function (exports, _ember, _emberSimpleAuthAuthenticatorsBase, _genesisResidualSystemConfigEnvironment) {
	exports['default'] = _emberSimpleAuthAuthenticatorsBase['default'].extend({
		tokenEndpoint: _genesisResidualSystemConfigEnvironment['default'].serverAuthEndpoint,
		refreshTokenEndpoint: _genesisResidualSystemConfigEnvironment['default'].serverRefreshTokenEndpoint,
		expiresIn: _genesisResidualSystemConfigEnvironment['default'].checkSessionToken * 3600 * 1000,
		_refreshTokenTimeout: null,

		init: function init() {
			this.headers = _genesisResidualSystemConfigEnvironment['default']['ember-simple-auth'].headers;
		},

		restore: function restore(data) {
			var _this = this;

			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				var now = new Date().getTime();

				if (!_ember['default'].isEmpty(data.expiresAt) && data.expiresAt < now) {
					_this._refreshAccessToken(_this.expiresIn, data.refreshToken).then(resolve, reject);
				} else {
					if (_ember['default'].isEmpty(data.accessToken)) {
						reject(new Error('Token is expired'));
					} else {
						_this._scheduleAccessTokenRefresh(_this.expiresIn, data.expiresAt, data.refreshToken);
						resolve(data);
					}
				}
			});
		},

		authenticate: function authenticate(data) {
			var _this2 = this;

			return new _ember['default'].RSVP.Promise(function (resolve, reject) {

				var payload = _this2._resolveAuthPayload(data);

				_this2._makeRequest(_genesisResidualSystemConfigEnvironment['default'].serverAuthEndpoint, payload).then(function (_ref) {
					var auth = _ref.auth;

					_ember['default'].run(function () {
						if (!_this2._validateToken(auth)) {
							reject('Token is missing in server response.');
						}

						var expiresAt = _this2._absolutizeExpirationTime(_this2.expiresIn);
						_this2._scheduleAccessTokenRefresh(_this2.expiresIn, expiresAt, auth.refreshToken);

						if (!_ember['default'].isEmpty(expiresAt)) {
							auth = _ember['default'].merge(auth, { user: data.customerId, expiresAt: expiresAt });
						}

						resolve(auth);
					});
				}, function (xhr) {
					_ember['default'].run(null, reject, xhr.responseText);
				});
			});
		},

		invalidate: function invalidate() {
			_ember['default'].run.cancel(this._refreshTokenTimeout);
			delete this._refreshTokenTimeout;

			return new _ember['default'].RSVP.resolve();
		},

		_makeRequest: function _makeRequest(url, data) {
			return _ember['default'].$.ajax({
				url: url,
				method: 'POST',
				headers: this.headers,
				contentType: 'application/json',
				dataType: 'json',
				data: JSON.stringify(data)
			});
		},

		_scheduleAccessTokenRefresh: function _scheduleAccessTokenRefresh(expiresIn, expiresAt, refreshToken) {
			var now = new Date().getTime();
			var offset = (Math.floor(Math.random() * 5) + 5) * 1000;

			if (_ember['default'].isEmpty(expiresAt) && !_ember['default'].isEmpty(expiresIn)) {
				expiresAt = new Date(now + expiresIn).getTime();
			}

			if (!_ember['default'].isEmpty(refreshToken) && !_ember['default'].isEmpty(expiresAt) && expiresAt > now - offset) {
				_ember['default'].run.cancel(this._refreshTokenTimeout);
				delete this._refreshTokenTimeout;

				this._refreshTokenTimeout = _ember['default'].run.later(this, this._refreshAccessToken, expiresIn, refreshToken, expiresAt - now - offset);
			}
		},

		_refreshAccessToken: function _refreshAccessToken(expiresIn, refreshToken) {
			var _this3 = this;

			var data = { refreshToken: refreshToken };

			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				_this3._makeRequest(_this3.refreshTokenEndpoint, data).then(function (_ref2) {
					var auth = _ref2.auth;

					_ember['default'].run(function () {
						var expiresAt = _this3._absolutizeExpirationTime(expiresIn);
						var sessionData = _ember['default'].merge(auth, { expiresAt: expiresAt });

						_this3._scheduleAccessTokenRefresh(expiresIn, null, refreshToken);
						_this3.trigger('sessionDataUpdated', sessionData);

						resolve(data);
					});
				}, function (xhr, status, error) {
					_ember['default'].Logger.warn('Access token could not be refreshed - server responded with ' + error + '.');
					reject();
				});
			});
		},

		_validateToken: function _validateToken(data) {
			return !_ember['default'].isEmpty(data.accessToken) && !_ember['default'].isEmpty(data.refreshToken);
		},

		_absolutizeExpirationTime: function _absolutizeExpirationTime(expiresIn) {
			if (!_ember['default'].isEmpty(expiresIn)) {
				return new Date(new Date().getTime() + expiresIn).getTime();
			}
		},

		_resolveAuthPayload: function _resolveAuthPayload(data) {
			if (data.oneTimePassword) {
				return { customerId: data.customerId, oneTimePassword: data.oneTimePassword };
			}
			if (data.ssoToken) {
				return { ssoToken: data.ssoToken };
			}
			return data;
		}
	});
});