define('genesis-residual-system/mixins/routes/dashboards/residuals-mixin', ['exports', 'ember', 'moment', 'genesis-residual-system/mixins/navbar-mixin', 'genesis-residual-system/mixins/sidebar-mixin', 'genesis-residual-system/highcharts/merchant-count-highchart', 'genesis-residual-system/mixins/update-model-mixin'], function (exports, _ember, _moment, _genesisResidualSystemMixinsNavbarMixin, _genesisResidualSystemMixinsSidebarMixin, _genesisResidualSystemHighchartsMerchantCountHighchart, _genesisResidualSystemMixinsUpdateModelMixin) {
	exports['default'] = _ember['default'].Mixin.create(_genesisResidualSystemMixinsNavbarMixin['default'], _genesisResidualSystemMixinsSidebarMixin['default'], _genesisResidualSystemMixinsUpdateModelMixin['default'], {
		buildModel: function buildModel(officeHeader, officeInfo, residualMonths, params) {
			var model = {};
			model.selectedDate = params.accountingPeriod;
			model.currentDate = params.accountingPeriod;
			model.residualMonths = residualMonths;
			model.officeHeader = officeHeader;
			model.officeInfo = officeInfo;
			model.secondaryNavbar = this.getSecondaryNavbarConfig(officeInfo, params);
			model.sidebarTiles = this.getSidebarTilesConfig(officeHeader, officeInfo);
			model.sidebarTotal = this.getSidebarTotalConfig(officeHeader, officeInfo);
			model.sidebarAccountsInfo = this.getSidebarAccountsInfoConfig(officeHeader);
			model.merchantCountChart = _genesisResidualSystemHighchartsMerchantCountHighchart['default'].create({ data: officeHeader.merchantCountReport }).getHighchartConfig();
			model.exportData = [];
			model.timer = '2:00';

			return model;
		},
		getResidualsFilterParams: function getResidualsFilterParams(params) {
			var accountingPeriod = (0, _moment['default'])().format('YYYY-MM');

			return {
				accountingPeriod: params.date ? params.date : accountingPeriod,
				filter: {
					accountingPeriod: params.date ? params.date : accountingPeriod,
					officeId: this.get('session.data.authenticated.user')
				}
			};
		},
		getFilterParams: function getFilterParams(months, transition) {
			var filterParams = {
				accountingPeriod: _moment['default'].utc(months[0].month).format('YYYY-MM')
			};

			if (transition.queryParams.date) {
				filterParams.accountingPeriod = transition.queryParams.date;
			}

			return {
				accountingPeriod: filterParams.accountingPeriod,
				router: transition.targetName,
				params: transition.queryParams,
				type: transition.params[transition.targetName].type,
				officeHeaderFilterParams: filterParams,
				officeInfoFilterParams: filterParams
			};
		}
	});
});