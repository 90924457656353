define('genesis-residual-system/mixins/routes/dashboards/residuals/third-party-residual-mixin', ['exports', 'ember', 'moment', 'genesis-residual-system/mixins/export-data/third-party-residuals-overview-export-data-mixin', 'genesis-residual-system/mixins/data-table/third-party-residuals-data-table-mixin', 'genesis-residual-system/mixins/intro-js/third-party-intro-js-mixin'], function (exports, _ember, _moment, _genesisResidualSystemMixinsExportDataThirdPartyResidualsOverviewExportDataMixin, _genesisResidualSystemMixinsDataTableThirdPartyResidualsDataTableMixin, _genesisResidualSystemMixinsIntroJsThirdPartyIntroJsMixin) {
	exports['default'] = _ember['default'].Mixin.create(_genesisResidualSystemMixinsExportDataThirdPartyResidualsOverviewExportDataMixin['default'], _genesisResidualSystemMixinsDataTableThirdPartyResidualsDataTableMixin['default'], _genesisResidualSystemMixinsIntroJsThirdPartyIntroJsMixin['default'], {
		getTilesConfig: function getTilesConfig(_ref, params) {
			var thirdParty = _ref.thirdParty;

			var currentDate = (0, _moment['default'])(params.accountingPeriod, 'YYYY-MM').format('MMMM YYYY'),
			    previousMonth = (0, _moment['default'])(params.accountingPeriod, 'YYYY-MM').subtract(1, 'months').format('MMMM YYYY'),
			    previousYear = (0, _moment['default'])(params.accountingPeriod, 'YYYY-MM').subtract(1, 'years').format('MMMM YYYY');

			var ACHDate = thirdParty.achDate,
			    thirdPartyResidualACHDateData = undefined;

			if (thirdParty.achInfo) {
				thirdPartyResidualACHDateData = thirdParty.achInfo.map(function (item) {
					return { title: 'ACH Date:', value: item.achDate };
				});
			} else {
				if (!ACHDate || (0, _moment['default'])(ACHDate, 'MMM DD YYYY').format('MM/DD/YYYY') === 'Invalid date') {
					ACHDate = 'N/A';
				} else {
					ACHDate = (0, _moment['default'])(ACHDate, 'MMM DD YYYY').format('MM/DD/YYYY');
				}
				thirdPartyResidualACHDateData = [{ title: 'ACH Date:', value: ACHDate }];
			}

			return [{
				picture: params.type.toLowerCase(),
				secData: thirdPartyResidualACHDateData
			}, {
				title: 'Residuals Payout ' + currentDate,
				previousMonth: previousMonth,
				previousYear: previousYear,
				value: thirdParty.totalPayout,
				percentChangeMoM: thirdParty.monthOverMonthChange,
				percentChangeYoY: thirdParty.yearOverYearChange
			}, {
				title: '# of Deals: ' + thirdParty.numberOfDeals,
				previousMonth: previousMonth,
				previousYear: previousYear,
				mainData: { title: 'Average Payout Per Deal: ', value: thirdParty.averagePayoutPerDeal },
				percentChangeMoM: thirdParty.avgPayoutMonthOverMonthChange,
				percentChangeYoY: thirdParty.avgPayoutYearOverYearChange
			}];
		},
		buildModel: function buildModel(thirdPartyDetails, residualMonths, params) {
			var model = {};

			model.currentDate = params.accountingPeriod;
			model.selectedDate = params.accountingPeriod;
			model.type = params.type;
			model.residualMonths = residualMonths;
			model.tiles = this.getTilesConfig(thirdPartyDetails.list, params);
			model.respTabs = this.getResponsiveTabsConfig(thirdPartyDetails, params);
			model.exportData = this.getThirdPartyResidualsOverviewExportDataConfig(thirdPartyDetails.list, params);
			model.introJS = this.getIntroJSConfig();

			return model;
		},
		getFilterParams: function getFilterParams(params) {
			var filterParams = {
				accountingPeriod: params.date,
				name: params.type
			};

			return {
				merchantId: params.merchantId,
				type: params.type,
				accountingPeriod: params.date,
				thirdPartyDetailsFilterParams: filterParams
			};
		}
	});
});