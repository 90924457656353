define('genesis-residual-system/mirage/fixtures/merchant-header', ['exports'], function (exports) {
	var merchantHeader = {
		merchantPortfolio: {
			'id': '0020167949',
			'name': 'GAUCHOS VILLAGE',
			'salesRepId': 'PPROS',
			'split': 'Echo / Apple Pay Option 1 - 55% Split',
			'productType': 'Harbortouch POS Hospitality',
			'approvalDate': '2015-10-21T11:49:30.100Z',
			'isRejected': false,
			'statementUrl': 'https://statements.harbortouch.com/eStatement.aspx?MerchantID=0020167949&YYYYMM=202001&verCode=de42b80734'
		},
		merchantPayout: {
			'authIncome': 0.24,
			'expense': 2032.13,
			'fixedExpense': 10.99,
			'fixedIncome': 0,
			'income': 4242.67,
			'miscIncome': 71.28,
			'monthOverMonthPercentChange': -40.16,
			'processingIncome': 4171.15,
			'totalIncome': 1204.807,
			'yearOverYearPercentChange': 161.29
		},
		profitInterchangeCategories: [{
			'cardType': 'VISA',
			'category': 'Regulated CPS Restaurant - CK',
			'profit': 1051.36,
			'salesCount': 238,
			'volume': 27336.39
		}, {
			'cardType': 'VISA',
			'category': 'Electronic - Sig. Pref.',
			'profit': 474.95,
			'salesCount': 87,
			'volume': 12349.23
		}, {
			'cardType': 'VISA',
			'category': 'EIRF',
			'profit': 393.53,
			'salesCount': 63,
			'volume': 10232.21
		}, {
			'cardType': 'AMERICAN EXPRESS',
			'category': 'AM Restaurant Tier 3          ',
			'profit': 285.45,
			'salesCount': 23,
			'volume': 7422.03
		}, {
			'cardType': 'VISA',
			'category': 'Regulated Business Card - Card Present Debit',
			'profit': 226.4,
			'salesCount': 29,
			'volume': 5886.67
		}, {
			'cardType': 'VISA',
			'category': 'Signature Rewards 2',
			'profit': 198.27,
			'salesCount': 34,
			'volume': 5155.17
		}, {
			'cardType': 'MASTERCARD',
			'category': 'LF Merit 3 - CK',
			'profit': 136.33,
			'salesCount': 25,
			'volume': 3544.7
		}, {
			'cardType': 'MASTERCARD',
			'category': 'World Elite T&E',
			'profit': 135.19,
			'salesCount': 20,
			'volume': 3515.04
		}, {
			'cardType': 'VISA',
			'category': 'CPS Restaurant',
			'profit': 122.7,
			'salesCount': 18,
			'volume': 3190.4
		}, {
			'cardType': 'AMERICAN EXPRESS',
			'category': 'AM Restaurant Tier 2          ',
			'profit': 93.76,
			'salesCount': 28,
			'volume': 2437.87
		}],
		cardTypeVolumes: [{
			'cardType': 'Visa',
			'monthOverMonthPercentChange': -46.71,
			'volume': 76058.09,
			'yearOverYearPercentChange': -4.89
		}, {
			'cardType': 'Mastercard',
			'monthOverMonthPercentChange': -27.29,
			'volume': 20498.65,
			'yearOverYearPercentChange': -6.96
		}, {
			'cardType': 'Discover',
			'monthOverMonthPercentChange': 25.5,
			'volume': 1933.25,
			'yearOverYearPercentChange': 9.37
		}, {
			'cardType': 'American Express',
			'monthOverMonthPercentChange': -72.56,
			'volume': 9963.67,
			'yearOverYearPercentChange': -20.86
		}, {
			'cardType': 'Debit Card',
			'monthOverMonthPercentChange': 0,
			'volume': 0,
			'yearOverYearPercentChange': 0
		}, {
			'cardType': 'EBT',
			'monthOverMonthPercentChange': 0,
			'volume': 0,
			'yearOverYearPercentChange': 0
		}, {
			'cardType': 'Total',
			'monthOverMonthPercentChange': -48.05,
			'volume': 108453.66,
			'yearOverYearPercentChange': -6.8
		}]
	};

	exports['default'] = merchantHeader;
});