define('genesis-residual-system/models/office-info', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		bonuses: _emberData['default'].attr(''),
		miscAdjustments: _emberData['default'].attr(''),
		salesRepReport: _emberData['default'].attr(''),
		thirdPartyReport: _emberData['default'].attr(''),

		bonusSortDefinition: ['type'],
		bonusAmountSortDefinition: ['amount:desc'],
		volumeSortDefinition: ['volume:desc'],
		merchantsSortDefinition: ['merchantCount:desc'],

		sortSalesRepReportByMerchants: _ember['default'].computed.sort('salesRepReport', 'merchantsSortDefinition'),
		sortBonusesByAmount: _ember['default'].computed.sort('bonuses', 'bonusAmountSortDefinition'),
		sortThirdPartyReportByVolume: _ember['default'].computed.sort('thirdPartyReport', 'volumeSortDefinition'),

		uniqMiscAdjustmentTypes: [{ value: 'Bonus', text: 'Monthly Bonuses' }, { value: 'Bonus Retraction', text: 'Bonus Retractions' }, { value: 'Miscellaneous', text: 'Miscellaneous' }, { value: 'Revenue Share', text: 'Revenue Share' }],
		miscAdjustmentAmount: _ember['default'].computed.mapBy('miscAdjustments', 'amount'),
		totalMiscAdjustment: _ember['default'].computed.sum('miscAdjustmentAmount'),
		filterRevenueShareMiscAdjustments: _ember['default'].computed.filterBy('miscAdjustments', 'type', 'Revenue Share'),
		revenueShareMiscAdjustments: _ember['default'].computed.mapBy('filterRevenueShareMiscAdjustments', 'amount'),
		totalRevenueShareMiscAdjustments: _ember['default'].computed.sum('revenueShareMiscAdjustments'),
		filterBonusRetractionMiscAdjustments: _ember['default'].computed.filterBy('miscAdjustments', 'type', 'Bonus Retraction'),
		bonusRetractionMiscAdjustments: _ember['default'].computed.mapBy('filterBonusRetractionMiscAdjustments', 'amount'),
		totalBonusMiscAdjustments: _ember['default'].computed.sum('bonusMiscAdjustments'),
		filterBonusMiscAdjustments: _ember['default'].computed.filterBy('miscAdjustments', 'type', 'Bonus'),
		bonusMiscAdjustments: _ember['default'].computed.mapBy('filterBonusMiscAdjustments', 'amount'),
		totalBonusRetractionMiscAdjustments: _ember['default'].computed.sum('bonusRetractionMiscAdjustments'),
		filterMiscellaneousMiscAdjustments: _ember['default'].computed.filterBy('miscAdjustments', 'type', 'Miscellaneous'),
		miscellaneousMiscAdjustments: _ember['default'].computed.mapBy('filterMiscellaneousMiscAdjustments', 'amount'),
		totalMiscellaneousMiscAdjustments: _ember['default'].computed.sum('miscellaneousMiscAdjustments'),

		thirdPartyPayout: _ember['default'].computed.mapBy('thirdPartyReport', 'payout'),
		totalThirdPartyPayout: _ember['default'].computed.sum('thirdPartyPayout'),

		sortBonuses: _ember['default'].computed.sort('bonuses', 'bonusSortDefinition'),
		bonusTypes: _ember['default'].computed.mapBy('sortBonuses', 'type'),
		uniqBonusTypes: _ember['default'].computed.uniq('bonusTypes')
	});
});