define('genesis-residual-system/components/secondary-navbar', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		setActiveItem: function setActiveItem(item, selectedItem, subitem) {
			if (selectedItem === subitem || selectedItem === item) {
				if (subitem) {
					_ember['default'].set(subitem, 'cssClass', 'active');
				}
				_ember['default'].set(item, 'cssClass', 'active');
			}
		},
		resetMenu: function resetMenu(navbar, selectedItem) {
			var _iteratorNormalCompletion = true;
			var _didIteratorError = false;
			var _iteratorError = undefined;

			try {
				for (var _iterator = navbar[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
					var item = _step.value;

					_ember['default'].set(item, 'cssClass', '');

					this.setActiveItem(item, selectedItem);

					if (item.submenu) {
						var _iteratorNormalCompletion2 = true;
						var _didIteratorError2 = false;
						var _iteratorError2 = undefined;

						try {
							for (var _iterator2 = item.submenu[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
								var subitem = _step2.value;

								_ember['default'].set(subitem, 'cssClass', '');

								this.setActiveItem(item, selectedItem, subitem);
							}
						} catch (err) {
							_didIteratorError2 = true;
							_iteratorError2 = err;
						} finally {
							try {
								if (!_iteratorNormalCompletion2 && _iterator2['return']) {
									_iterator2['return']();
								}
							} finally {
								if (_didIteratorError2) {
									throw _iteratorError2;
								}
							}
						}
					}
				}
			} catch (err) {
				_didIteratorError = true;
				_iteratorError = err;
			} finally {
				try {
					if (!_iteratorNormalCompletion && _iterator['return']) {
						_iterator['return']();
					}
				} finally {
					if (_didIteratorError) {
						throw _iteratorError;
					}
				}
			}
		},
		actions: {
			clickMenu: function clickMenu(navbar, item) {
				if (item.submenu === undefined) {
					this.resetMenu(navbar, item);
				}
			},
			setDate: function setDate(date) {
				this.sendAction('pickedDate', date);
			}
		}
	});
});