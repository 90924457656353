define('genesis-residual-system/highcharts/highchart', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Object.create({
		data: null,
		getGradientConfig: function getGradientConfig(id) {
			return {
				tagName: 'linearGradient',
				id: id,
				x1: 0,
				y1: 0,
				x2: 0,
				y2: 1,
				children: [{
					tagName: 'stop',
					offset: 0
				}, {
					tagName: 'stop',
					offset: 1
				}]
			};
		},
		getDefsConfig: function getDefsConfig() {
			return {
				gradient0: this.getGradientConfig('gradient-0'),
				gradient1: this.getGradientConfig('gradient-1')
			};
		},
		getThemeConfig: function getThemeConfig() {
			return {
				chart: {
					backgroundColor: {
						linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
						stops: [[0, 'rgb(96, 96, 96)'], [1, 'rgb(16, 16, 16)']]
					}
				}
			};
		},
		getAxisLabelsConfig: function getAxisLabelsConfig() {
			return {
				style: {
					fontSize: '13px'
				}
			};
		},
		getXAxisLabelsConfig: function getXAxisLabelsConfig() {
			return this.getAxisLabelsConfig();
		},
		getYAxisLabelsConfig: function getYAxisLabelsConfig() {
			return this.getAxisLabelsConfig();
		},
		getXAxisConfig: function getXAxisConfig() {
			return {
				type: 'category',
				labels: this.getXAxisLabelsConfig()
			};
		},
		getYAxisConfig: function getYAxisConfig() {
			return {
				title: null,
				labels: this.getYAxisLabelsConfig()
			};
		},
		getChartDataConfig: function getChartDataConfig() {
			return [{
				colorByPoint: false,
				data: this.getChartData()
			}];
		},
		getChartData: function getChartData() {
			var _this = this;

			return this.data.map(function (item) {
				return _this.getChartDataFormat(item);
			});
		},
		getChartDataFormat: function getChartDataFormat(item) {
			return item;
		},
		getChartConfig: function getChartConfig() {
			var config = {
				type: 'column',
				height: 266,
				style: {
					fontFamily: 'SofiaPro'
				}
			};

			if (this.get('inverted')) {
				config.inverted = this.get('inverted');
			}
			if (this.get('marginLeft')) {
				config.marginLeft = this.get('marginLeft');
			}

			return config;
		},
		getTooltipConfig: function getTooltipConfig() {
			return {
				style: {
					fontSize: '14px'
				},
				formatter: function formatter() {
					return this.key + ': ' + this.y;
				}
			};
		},
		getLegendConfig: function getLegendConfig() {
			return {
				enabled: false
			};
		},
		getChartOptionsConfig: function getChartOptionsConfig() {
			return {
				chart: this.getChartConfig(),
				title: {
					text: ''
				},
				xAxis: this.getXAxisConfig(),
				yAxis: this.getYAxisConfig(),
				legend: this.getLegendConfig(),
				tooltip: this.getTooltipConfig(),
				exporting: {
					enabled: false
				}
			};
		},
		getHighchartConfig: function getHighchartConfig() {
			return {
				chartOptions: this.getChartOptionsConfig(),
				theme: this.getThemeConfig(),
				chartData: this.getChartDataConfig(),
				defs: this.getDefsConfig()
			};
		}
	});
});