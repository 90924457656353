define('genesis-residual-system/routes/login', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin) {

	var NO_ACCESS_ERROR = 'You do not have access to this endpoint';

	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {
		activate: function activate() {
			document.title = 'Login';
			_ember['default'].$('body').addClass('login');
		},
		beforeModel: function beforeModel(transition) {
			var _transition$queryParams = transition.queryParams;
			var customerId = _transition$queryParams.customerId;
			var token = _transition$queryParams.token;

			var isAuthenticated = this.get('session.isAuthenticated');

			if (isAuthenticated && customerId && token) {
				this.get('session').invalidate();
			}

			return this._super.apply(this, arguments);
		},
		model: function model(params, transition) {
			var _transition$queryParams2 = transition.queryParams;
			var customerId = _transition$queryParams2.customerId;
			var token = _transition$queryParams2.token;
			var oneTimePassword = _transition$queryParams2.oneTimePassword;
			var message = _transition$queryParams2.message;
			var sessionExpired = _transition$queryParams2.sessionExpired;

			if (customerId && token) {
				var credentials = { customerId: customerId, ssoToken: token };

				return this.authenticate(credentials);
			}

			if (customerId && oneTimePassword) {
				var credentials = { customerId: customerId, oneTimePassword: oneTimePassword };

				return this.authenticate(credentials);
			} else if (sessionExpired) {
				return { message: message ? message : 'Sorry, your session has expired. Please log in again.' };
			}

			return {};
		},
		afterModel: function afterModel() {
			_ember['default'].$('.loading').fadeOut();
		},
		authenticate: function authenticate(credentials) {
			return this.get('session').authenticate('authenticator:custom', credentials)['catch'](function (reason) {
				var _JSON$parse = JSON.parse(reason);

				var errorMessage = _JSON$parse.message;

				var noAccess = errorMessage.includes(NO_ACCESS_ERROR);

				return { login: credentials.customerId, errors: [errorMessage], noAccess: noAccess };
			});
		},
		actions: {
			login: function login(params) {
				var _this = this;

				var credentials = { customerId: params.login, password: params.password };

				this.authenticate(credentials).then(function (res) {
					if (res) {
						_this.set('currentModel.errors', res.errors);
						_this.set('currentModel.noAccess', res.noAccess);
					}
				});
			}
		}
	});
});