define('genesis-residual-system/components/data-table', ['exports', 'ember', 'genesis-residual-system/helpers/format-money', 'genesis-residual-system/helpers/format-number', 'genesis-residual-system/mixins/resize-sidebar-mixin'], function (exports, _ember, _genesisResidualSystemHelpersFormatMoney, _genesisResidualSystemHelpersFormatNumber, _genesisResidualSystemMixinsResizeSidebarMixin) {
	exports['default'] = _ember['default'].Component.extend(_genesisResidualSystemMixinsResizeSidebarMixin['default'], {
		tagName: 'div',
		id: 'test',
		pageSizeChanged: _ember['default'].observer('tab.pageSize', function () {
			var _this = this;

			var pageSizeDropdown = this.$('[name="pageSizeMenuHidden' + this.get('id') + '"]');
			pageSizeDropdown.each(function (index, item) {
				if (_this.$(item).val() !== _this.get('tab.pageSize')) {
					_this.$(_this.$('[aria-labelledby="dropdownMenu' + _this.get('id') + '"] a[data-id="' + _this.get('tab.pageSize') + '"]')[index]).click();
				}
			});
		}),
		pageChanged: _ember['default'].observer('tab.page', function () {
			var currentPage = this.table.page.info().page;
			if (currentPage !== this.get('tab.page')) {
				this.table.page(this.get('tab.page')).draw('page');
			}
		}),
		filterChanged: _ember['default'].observer('tab.selectedFilter', function () {
			var filter = this.get('tab.selectedFilter');
			var value = this.$('#' + this.get('id') + filter.id).closest('.input-group').find('.form-control').val();

			if (value !== filter.value && this.table) {
				this.$('[id*="' + filter.id + '"]').closest('.input-group').find('.form-control').val(filter.value);
				if (filter.column) {
					if (filter.value) {
						this.table.column(filter.column).search('^' + filter.value + '$', true, false).draw();
					} else {
						this.table.column(filter.column).search(filter.value).draw();
					}
				} else {
					this.table.search(filter.value).draw();
				}
			}
		}),
		initConfig: function initConfig() {
			var _this2 = this;

			var options = {};
			if (this.get('tab.hasTotal')) {
				(function () {
					var totalColumns = _this2.get('tab.totalColumns');
					options.fnFooterCallback = function (row, data, start, end, display) {
						/**
           * Calculate Total
           * @param {array} array The data
           * @param {key} key The key
           * @returns {number} The total
           */
						function calculateTotal(array, key) {
							var total = 0;
							var _iteratorNormalCompletion = true;
							var _didIteratorError = false;
							var _iteratorError = undefined;

							try {
								for (var _iterator = display[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
									var item = _step.value;

									total += array[item][key];
								}
							} catch (err) {
								_didIteratorError = true;
								_iteratorError = err;
							} finally {
								try {
									if (!_iteratorNormalCompletion && _iterator['return']) {
										_iterator['return']();
									}
								} finally {
									if (_didIteratorError) {
										throw _iteratorError;
									}
								}
							}

							return total;
						}

						for (var columnIndex = 0; columnIndex < totalColumns.length; columnIndex++) {
							var key = Object.keys(totalColumns[columnIndex])[0],
							    value = totalColumns[columnIndex][key],
							    column = this.api().column(key);

							if (!column.data()) {
								return;
							}

							if (display.length === data.length) {
								column.footer().innerHTML = value.formatMoney ? '' + (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([value.sum]) : '' + (0, _genesisResidualSystemHelpersFormatNumber.formatNumber)(value);
							} else {
								var mData = column.header().innerText.replace(/\s+/g, '').replace(/^\w/, function (c) {
									return c.toLowerCase();
								});
								var total = calculateTotal(data, mData);
								column.footer().innerHTML = value.formatMoney ? '' + (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([total]) : '' + (0, _genesisResidualSystemHelpersFormatNumber.formatNumber)(total);
							}
						}
					};
				})();
			}
			options.dom = '<f><t><"pull-right" p><"pull-right pageSize" l>';
			if (this.get('tab.orderColumn')) {
				options.order = [[this.get('tab.orderColumn'), 'desc']];
			} else {
				options.order = [];
			}

			options.language = {
				'lengthMenu': 'Display <div class="dropdown btn-group basicSelectDefaultDropdownFromTablet" data-toggle="dropdown">' + '<button class="btn btn-white dropdown-toggle" data-toggle="dropdown" data-label-placement="">25</button>' + ('<button class="btn btn-white dropdown-toggle" id="dropdownMenu' + this.get('id') + '" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">') + '<span class="caret"></span>' + '</button>' + ('<select name="pageSizeMenuHidden' + this.get('id') + '">') + '<option value="25">25</option>' + '<option value="50">50</option>' + '<option value="100">100</option>' + '<option value="-1">View All</option>' + '</select>' + ('<ul class="dropdown-menu pull-right" aria-labelledby="dropdownMenu' + this.get('id') + '">') + '<li class="active"><a data-id="25">25</a></li>' + '<li><a data-id="50">50</a></li>' + '<li><a data-id="100">100</a></li>' + '<li><a data-id="-1">View All</a></li>' + '</ul></div>'

			};

			options.aoColumns = this.get('tab.columns');
			options.aaData = this.get('tab.data');
			options.fnCreatedRow = this.get('tab.createdRowCallback');
			options.bAutoWidth = false;
			options.bDeferRender = true;
			options.pageLength = 25;
			options.columnDefs = this.get('tab.columnDefs');
			options.retrieve = true;
			return options;
		},
		didRender: function didRender() {
			var _this3 = this;

			this._super.apply(this, arguments);

			var options = this.initConfig(),
			    tableId = this.get('id');
			this.table = this.$('#' + tableId).DataTable(options);

			this.$('#' + tableId).off('click', 'a').on('click', 'a', function (e) {
				var $target = _this3.$(e.currentTarget);
				_this3.sendAction('redirectAction', $target.data('href'));
			});
			this.table.off('page.dt').on('page.dt', function () {
				var currentPage = _this3.table.page.info().page;
				_this3.set('tab.page', currentPage);
			});
			this.table.off('draw.dt').on('draw.dt', function () {
				_this3.setSidebarHeight();
			});

			this.$('.dropdown-menu li a').click(function (e) {
				var $el = _this3.$(e.currentTarget),
				    value = $el.data('id');
				var text = $el.text();

				$el.closest('.dropdown').find('li').removeClass('active');
				$el.parent().addClass('active');

				var hasCaret = $el.closest('.dropdown').find('.btn:first-child').find('.caret').length > 0;
				if (hasCaret) {
					$el.closest('.dropdown').find('.btn:first-child .value').text(text);
				} else {
					$el.closest('.dropdown').find('.btn:first-child').text(text);
				}
				$el.closest('.dropdown').find('select').val(value).trigger('change', true);

				_this3.set('tab.pageSize', value);
			});

			this.$('.dropdown select').change(function (e, isDropdownMenuChange) {
				if (isDropdownMenuChange) {
					return;
				}
				var $el = _this3.$(e.currentTarget),
				    value = $el.val(),
				    text = $el.find('option:selected').text();

				$el.closest('.dropdown').find('.btn:first-child').text(text);

				_this3.set('tab.pageSize', value);
			});

			if (this.get('tab.filters')) {
				var selectedFilter = this.get('tab.filters').filter(function (item) {
					return item.selected !== undefined;
				});
				if (selectedFilter.length) {
					this.applyFilter(selectedFilter[0].id, selectedFilter[0].column);
				}
			}
		},
		applyFilter: function applyFilter(id, column) {
			var value = this.$('[id*="' + id + '"]').closest('.input-group').find('.form-control').val();
			if (column) {
				if (value) {
					this.table.column(column).search('^' + value + '$', true, false).draw();
				} else {
					this.table.column(column).search(value).draw();
				}
			} else {
				this.table.search(value).draw();
			}
			this.set('tab.selectedFilter', { id: id, value: value, column: column });
		},
		actions: {
			filter: function filter(id, column) {
				this.applyFilter(id, column);
			},
			filterSubmit: function filterSubmit(event) {
				this.$(event.target).parent().find('button').click();
			}
		}
	});
});