define('genesis-residual-system/mixins/routes/dashboards/residuals/processing-details-mixin', ['exports', 'ember', 'genesis-residual-system/mixins/export-data/merchant-residual-detail-export-data-mixin', 'genesis-residual-system/mixins/data-table/processing-details-data-table-mixin', 'genesis-residual-system/mixins/intro-js/processing-details-intro-js-mixin'], function (exports, _ember, _genesisResidualSystemMixinsExportDataMerchantResidualDetailExportDataMixin, _genesisResidualSystemMixinsDataTableProcessingDetailsDataTableMixin, _genesisResidualSystemMixinsIntroJsProcessingDetailsIntroJsMixin) {
	exports['default'] = _ember['default'].Mixin.create(_genesisResidualSystemMixinsExportDataMerchantResidualDetailExportDataMixin['default'], _genesisResidualSystemMixinsDataTableProcessingDetailsDataTableMixin['default'], _genesisResidualSystemMixinsIntroJsProcessingDetailsIntroJsMixin['default'], {
		buildModel: function buildModel(merchantHeader, merchantProcessing, residualMonths, params) {
			var model = {};

			model.merchantId = params.merchantId;
			model.currentDate = params.accountingPeriod;
			model.selectedDate = params.accountingPeriod;
			model.exportData = this.getMerchantResidualDetailExportDataConfig(merchantHeader, merchantProcessing.list, params);
			model.respTabs = this.getResponsiveTabsConfig(merchantHeader, merchantProcessing, params);
			model.residualMonths = residualMonths;
			model.introJS = this.getIntroJSConfig();

			return model;
		},
		getFilterParams: function getFilterParams(params) {
			return {
				accountingPeriod: params.date,
				merchantId: params.merchantId
			};
		}
	});
});