define('genesis-residual-system/mixins/intro-js/processing-details-intro-js-mixin', ['exports', 'ember', 'genesis-residual-system/mixins/intro-js/intro-js-mixin'], function (exports, _ember, _genesisResidualSystemMixinsIntroJsIntroJsMixin) {
	exports['default'] = _ember['default'].Mixin.create(_genesisResidualSystemMixinsIntroJsIntroJsMixin['default'], {
		tutorialModel: 'merchantProcessingDetail',
		steps: _ember['default'].computed(function () {
			return [{
				intro: 'This page allows you to dig into all of a merchant\'s processing detail.'
			}, {
				element: _ember['default'].$('.nav-tabs:visible').length > 0 ? _ember['default'].$('.nav-tabs:visible')[0] : _ember['default'].$('.resp-tabs:visible')[0],
				intro: 'You can switch between the various categories using the tabs.'

			}, {
				element: _ember['default'].$('.table-responsive .dataTable:visible')[0],
				intro: 'Like the other pages the Column headers allow you to sort the table.',
				scrollTo: 'tooltip'
			}];
		})
	});
});