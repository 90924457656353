define('genesis-residual-system/models/merchant-processing', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		list: _emberData['default'].attr(''),
		authInfo: _emberData['default'].attr(''),
		fixedExpenses: _emberData['default'].attr(''),
		miscInfo: _emberData['default'].attr(''),
		monetaryInfo: _emberData['default'].attr(''),
		thirdPartyInfo: _emberData['default'].attr(''),
		sortDefinition: ['cardType'],
		sortCardTypes: _ember['default'].computed.sort('list.monetaryInfo', 'sortDefinition'),
		cardTypes: _ember['default'].computed.mapBy('sortCardTypes', 'cardType'),
		uniqCardTypes: _ember['default'].computed.uniq('cardTypes')
	});
});