define('genesis-residual-system/adapters/merchant-header', ['exports', 'genesis-residual-system/adapters/application'], function (exports, _genesisResidualSystemAdaptersApplication) {
	exports['default'] = _genesisResidualSystemAdaptersApplication['default'].extend({
		urlForQueryRecord: function urlForQueryRecord(query, modelName) {
			var baseUrl = this.buildURL();

			if (!this.get('officeId')) {
				this.send('logout', true);
				return;
			}

			return baseUrl + '/' + this.get('officeId') + '/merchants/' + query.merchantId + '/' + modelName + '?accountingPeriod=' + query.accountingPeriod;
		},
		queryRecord: function queryRecord(store, type, query) {
			var url = this.buildURL(type.modelName, null, null, 'queryRecord', query);

			return this.ajax(url, 'GET');
		}
	});
});