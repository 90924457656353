define('genesis-residual-system/adapters/residual-month', ['exports', 'genesis-residual-system/adapters/application'], function (exports, _genesisResidualSystemAdaptersApplication) {
	exports['default'] = _genesisResidualSystemAdaptersApplication['default'].extend({
		urlForQueryRecord: function urlForQueryRecord() {
			var baseUrl = this.buildURL();

			if (!this.get('officeId')) {
				this.send('logout', true);
				return;
			}

			return baseUrl + '/' + this.get('officeId') + '/residual-months';
		}
	});
});