define('genesis-residual-system/mixins/export-data/residuals-overview-export-data-mixin', ['exports', 'ember', 'moment', 'genesis-residual-system/mixins/export-data/export-data-mixin'], function (exports, _ember, _moment, _genesisResidualSystemMixinsExportDataExportDataMixin) {
	function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

	exports['default'] = _ember['default'].Mixin.create(_genesisResidualSystemMixinsExportDataExportDataMixin['default'], {
		addResidualsOverviewHeader: function addResidualsOverviewHeader() {
			return [[this.addTitleCell('Office Name')], [{ value: this.get('session.data.authenticated.user') }], [{ value: 'Residuals Overview Report' }]];
		},
		addResidualsInfo: function addResidualsInfo(officeHeader, params) {
			var _this = this;

			var currentDate = (0, _moment['default'])(params.accountingPeriod, 'YYYY-MM').format('MMMM YYYY'),
			    previousMonth = (0, _moment['default'])(params.accountingPeriod, 'YYYY-MM').subtract(1, 'months').format('MMMM YYYY'),
			    previousYear = (0, _moment['default'])(params.accountingPeriod, 'YYYY-MM').subtract(1, 'years').format('MMMM YYYY');

			var archiveDate = officeHeader.officeEarnings.archiveDate;
			var archives = officeHeader.archives;
			var ACHInfoTable = undefined;

			if (archives && archives.length > 0) {
				ACHInfoTable = [[this.addTitleCell('Residuals Payout for ' + currentDate)], [this.addTableHeaderCell('ACH Date'), this.addTableHeaderCell('Amount')]].concat(_toConsumableArray(archives.map(function (item) {
					return [_this.addTableCell(item.archiveDate), _this.addTableCell(item.amount, true)];
				})), [[]]);
			} else {
				archiveDate = archiveDate ? (0, _moment['default'])(archiveDate, 'MMM DD YYYY').format('MM/DD/YYYY') : 'N/A';
				ACHInfoTable = [];
			}

			return [[this.addEmptyCell(), this.addTableHeaderCell('Residuals Payout for ' + currentDate), this.addTableHeaderCell(currentDate + ' Volume'), this.addTableHeaderCell('Active Merchants for ' + currentDate), this.addTableHeaderCell('Total Bonus Payout')], [this.addTableHeaderCell('Amount'), this.addTableCell(officeHeader.officeEarnings.currentResidual, true), this.addTableCell(officeHeader.officeEarnings.volume, true), this.addTableCell(officeHeader.officeEarnings.merchantCount), this.addTableCell(officeHeader.officeEarnings.bonusTotal, true)], [this.addTableHeaderCell('ACH Date'), this.addTableCell(archiveDate), this.addEmptyCell(), this.addEmptyCell(), this.addEmptyCell()], [this.addTableHeaderCell('Month Over Month'), this.addTableCell(officeHeader.officeEarnings.monthOverMonthResidualPercentChange + '% over ' + previousMonth), this.addTableCell(officeHeader.officeEarnings.monthOverMonthVolumePercentChange + '% over ' + previousMonth), this.addTableCell(officeHeader.officeEarnings.monthOverMonthMerchantPercentChange + '% over ' + previousMonth), this.addTableCell(officeHeader.officeEarnings.monthOverMonthBonusPercentChange + '% over ' + previousMonth)], [this.addTableHeaderCell('Year Over Year'), this.addTableCell(officeHeader.officeEarnings.yearOverYearResidualPercentChange + '% over ' + previousYear), this.addTableCell(officeHeader.officeEarnings.yearOverYearVolumePercentChange + '% over ' + previousYear), this.addTableCell(officeHeader.officeEarnings.yearOverYearMerchantPercentChange + '% over ' + previousYear), this.addTableCell(officeHeader.officeEarnings.yearOverYearBonusPercentChange + '% over ' + previousYear)], []].concat(_toConsumableArray(ACHInfoTable));
		},
		addResidualsTable: function addResidualsTable(officeHeader) {
			var attributes = [{ key: 'product', name: 'Product' }, { key: 'residual', name: 'Net Profit', formatMoney: true }, { key: 'merchantCount', name: 'Merchants' }];

			return this.addTable(officeHeader.productResiduals, attributes);
		},
		addResidualsOverviewList: function addResidualsOverviewList(officeHeader, params) {
			var list = [].concat(_toConsumableArray(this.addResidualsOverviewHeader()), [[]], _toConsumableArray(this.addResidualsInfo(officeHeader, params)));

			list.push.apply(list, _toConsumableArray(this.addVolumeByCardTypeTable(officeHeader.monthlyVolume, params)));
			list.push([]);
			list.push.apply(list, _toConsumableArray(this.addResidualsTable(officeHeader)));

			return list;
		},
		addMerchantPortfolioList: function addMerchantPortfolioList(merchantList) {
			var attributes = [{ key: 'id', name: 'Merchant ID' }, { key: 'name', name: 'Merchant Name' }, { key: 'payoutAmount', name: 'Payout Amount', formatMoney: true }, { key: 'volume', name: 'Volume', formatMoney: true }, { key: 'sales', name: 'Sales' }, { key: 'refunds', name: 'Refunds' }, { condition: 'isRejected', conditionTrue: 'payoutAmount', conditionFalse: 0, name: 'Reject Amount', formatMoney: true }, { key: 'salesRepId', name: 'Salesrep ID' }, { key: 'split', name: 'Split' }];

			return this.addTable(merchantList.list, attributes);
		},
		addSalesRepList: function addSalesRepList(officeInfo) {
			var attributes = [{ key: 'id', name: 'Salesrep ID' }, { key: 'name', name: 'Salesrep Name' }, { key: 'merchantCount', name: '# of Accounts' }, { key: 'totalPayout', name: 'Total Payout', formatMoney: true }];

			return this.addTable(officeInfo.get('sortSalesRepReportByMerchants'), attributes);
		},
		addMiscAdjustmentList: function addMiscAdjustmentList(officeInfo) {
			var attributes = [{ key: 'description', name: 'Description' }, { key: 'type', name: 'Type' }, { key: 'amount', name: 'Amount', formatMoney: true }];

			return this.addTable(officeInfo.data.miscAdjustments, attributes);
		},
		addBonusesList: function addBonusesList(officeInfo) {
			var attributes = [{ key: 'merchantId', name: 'Merchant ID' }, { key: 'merchantName', name: 'Merchant Name' }, { key: 'type', name: 'Bonus Type' }, { key: 'amount', name: 'Payout Amount', formatMoney: true }, { key: 'datePaid', name: 'Payout Date', dateFormat: 'MM/DD/YYYY' }, { key: 'salesRepId', name: 'Salesrep ID' }];

			return this.addTable(officeInfo.get('sortBonusesByAmount'), attributes);
		},
		addThirdPartyResidualsList: function addThirdPartyResidualsList(officeInfo) {
			var attributes = [{ key: 'vendor', name: 'Name' }, { key: 'payout', name: 'Payout Amount', formatMoney: true }, { key: 'volume', name: 'Volume', formatMoney: true }, { key: 'merchantCount', name: '# of Merchants' }];

			return this.addTable(officeInfo.get('sortThirdPartyReportByVolume'), attributes);
		},
		getResidualsOverviewExportDataConfig: function getResidualsOverviewExportDataConfig(officeHeader, merchantList, officeInfo, params) {
			var conf = {
				title: 'Download Excel',
				format: 'xlsx',
				fileName: 'Residuals Overview.xlsx',
				cols: {
					'Overview': [{ wch: 21 }, { wch: 34 }, { wch: 24 }, { wch: 34 }, { wch: 24 }],
					'Merchant Portfolio': [{ wch: 12 }, { wch: 27 }, { wch: 14 }, { wch: 12 }, { wch: 13 }, { wch: 13 }, { wch: 13 }, { wch: 12 }, { wch: 27 }],
					'Sales Rep Report': [{ wch: 11 }, { wch: 21 }, { wch: 13 }, { wch: 12 }],
					'Misc. Adjustments': [{ wch: 107 }, { wch: 14 }, { wch: 9 }],
					'Bonuses': [{ wch: 12 }, { wch: 27 }, { wch: 23 }, { wch: 14 }, { wch: 20 }, { wch: 12 }],
					'Third Party Residuals': [{ wch: 11 }, { wch: 14 }, { wch: 7 }, { wch: 14 }]
				},
				data: {
					'Overview': [],
					'Merchant Portfolio': [],
					'Sales Rep Report': [],
					'Misc. Adjustments': [],
					'Bonuses': [],
					'Third Party Residuals': []
				}
			};

			conf.data.Overview = this.addResidualsOverviewList(officeHeader, params);
			conf.data['Merchant Portfolio'] = this.addMerchantPortfolioList(merchantList);
			conf.data['Sales Rep Report'] = this.addSalesRepList(officeInfo);
			conf.data['Misc. Adjustments'] = this.addMiscAdjustmentList(officeInfo);
			conf.data.Bonuses = this.addBonusesList(officeInfo);
			conf.data['Third Party Residuals'] = this.addThirdPartyResidualsList(officeInfo);

			return [conf];
		}
	});
});