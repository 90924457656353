define('genesis-residual-system/mirage/fixtures/third-party-details', ['exports'], function (exports) {
	var thirdPartyDetails = {
		thirdParty: {
			'totalPayout': 3.0300,
			'avgPayoutMonthOverMonthChange': 1.0000,
			'avgPayoutYearOverYearChange': -17.5100,
			'yearOverYearChange': -45.0000,
			'monthOverMonthChange': 1.0000,
			'achDate': 'Feb 25 2020 12:00AM',
			'averagePayoutPerDeal': 1.5150,
			'numberOfDeals': 2
		},
		merchants: [{
			'monthlyMinIncome': 0,
			'id': '0014132427',
			'name': 'JABULA INTERNATIONAL NETWORK - NORTH AMERICA',
			'miscIncome': 0.0000,
			'monthlyFeeIncome': 2.5000,
			'salesRepId': 'BJORD',
			'totalIncome': 2.5000,
			'transIncome': 0.0000,
			'transactionCount': 0
		}, {
			'monthlyMinIncome': 0,
			'id': '0020897772',
			'name': 'RIGHT/SOURCE',
			'miscIncome': 0.0300,
			'monthlyFeeIncome': 0.5000,
			'salesRepId': 'LANE9',
			'totalIncome': 0.5300,
			'transIncome': 0.0150,
			'transactionCount': 0
		}]
	};

	exports['default'] = thirdPartyDetails;
});