define('genesis-residual-system/routes/dashboards/residuals/merchants', ['exports', 'ember', 'genesis-residual-system/mixins/residuals-route-mixin', 'genesis-residual-system/mixins/routes/dashboards/residuals/merchants-mixin'], function (exports, _ember, _genesisResidualSystemMixinsResidualsRouteMixin, _genesisResidualSystemMixinsRoutesDashboardsResidualsMerchantsMixin) {
	exports['default'] = _ember['default'].Route.extend(_genesisResidualSystemMixinsResidualsRouteMixin['default'], _genesisResidualSystemMixinsRoutesDashboardsResidualsMerchantsMixin['default'], {
		queryParams: {
			date: {
				refreshModel: true
			},
			tabId: {
				refreshModel: true
			},
			type: {
				refreshModel: true
			}
		},
		model: function model(params) {
			var _this = this;

			_ember['default'].$('.loading').fadeIn();

			var model = this.modelFor('dashboards.residuals.index'),
			    residualFilterParams = this.getResidualsFilterParams(params);
			this.set('tabId', params.tabId);

			if (model) {
				return this.buildModel(model.officeHeader, model.merchantList, {
					list: model.officeInfo,
					totalThirdPartyPayout: model.officeInfo.get('totalThirdPartyPayout'),
					bonusesFilterData: model.officeInfo.get('uniqBonusTypes'),
					miscAdjustmentsFilterData: model.officeInfo.get('uniqMiscAdjustmentTypes')
				}, model.residualMonths, residualFilterParams);
			}

			return this.store.queryRecord('residual-month', {}).then(function (residualMonth) {
				var filterParams = _this.getFilterParams(residualFilterParams);

				return _ember['default'].RSVP.hash({
					officeHeader: _this.store.queryRecord('office-header', filterParams.officeHeaderFilterParams),
					merchantList: _this.store.queryRecord('merchant-list', filterParams.merchantListFilterParams),
					officeInfo: _this.store.queryRecord('office-info', filterParams.officeInfoFilterParams)
				}).then(function (response) {
					return _this.buildModel(response.officeHeader.data, {
						list: response.merchantList.get('sortByVolumeList'),
						filterData: response.merchantList.get('uniqSalesRep')
					}, {
						list: response.officeInfo,
						totalThirdPartyPayout: response.officeInfo.get('totalThirdPartyPayout'),
						bonusesFilterData: response.officeInfo.get('uniqBonusTypes'),
						miscAdjustmentsFilterData: response.officeInfo.get('uniqMiscAdjustmentTypes')
					}, residualMonth.data.list, filterParams);
				});
			});
		},
		actions: {
			setDate: function setDate(date) {
				var _this2 = this;

				_ember['default'].$('.loading').fadeIn();
				var params = this.getOfficeAndMerchantsFilterParams(this.currentModel, date),
				    requests = this.getOfficeAndMerchantsRequests(this.store, params);

				_ember['default'].RSVP.hash(requests).then(function (response) {
					var model = _this2.buildModel(response.officeHeader.data, {
						list: response.merchantList.data.list,
						filterData: response.merchantList.get('uniqSalesRep')
					}, {
						list: response.officeInfo,
						totalThirdPartyPayout: response.officeInfo.get('totalThirdPartyPayout'),
						bonusesFilterData: response.officeInfo.get('uniqBonusTypes'),
						miscAdjustmentsFilterData: response.officeInfo.get('uniqMiscAdjustmentTypes')
					}, response.residualMonths.data.list, params);
					_this2.updateModel(model);
					var sidebarFilterParams = _this2.getSidebarFilterParams(params);
					_this2.send('updateSidebar', response.officeHeader.data, response.officeInfo, response.residualMonths.data.list, sidebarFilterParams);
					_this2.send('updateExportDataConfig', model.exportData);
					_ember['default'].$('.loading').fadeOut();
				});
			},
			redirect: function redirect(url) {
				this.transitionTo(url);
			}
		}
	});
});