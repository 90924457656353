define('genesis-residual-system/mixins/volume-by-card-types-mixin', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
	exports['default'] = _ember['default'].Mixin.create({
		getCardTypesConfig: function getCardTypesConfig(monthlyVolume, params) {
			return {
				tableCSS: 'basicTable ' + (monthlyVolume.length > 0 ? 'basicTableWithTotal' : ''),
				title: 'Volume by Card Type',
				header: ['Card Type', (0, _moment['default'])(params.accountingPeriod, 'YYYY-MM').format('MMMM YYYY'), 'MoM Change', 'YoY Change'],
				body: monthlyVolume.map(function (item) {
					return [{ value: item.cardType.toLowerCase(), align: 'text-center', imageType: item.cardType !== 'Total' }, { value: item.volume, align: 'text-right', formatMoney: true }, { value: item.monthOverMonthPercentChange, align: 'text-right', formatPercent: true, cssClass: item.monthOverMonthPercentChange < 0 ? 'redtext' : 'greentxt' }, { value: item.yearOverYearPercentChange, align: 'text-right', formatPercent: true, cssClass: item.yearOverYearPercentChange < 0 ? 'redtext' : 'greentxt' }];
				})
			};
		}
	});
});