define('genesis-residual-system/adapters/office-header', ['exports', 'genesis-residual-system/adapters/application'], function (exports, _genesisResidualSystemAdaptersApplication) {
	exports['default'] = _genesisResidualSystemAdaptersApplication['default'].extend({
		urlForQueryRecord: function urlForQueryRecord(query, modelName) {
			var baseUrl = this.buildURL();

			if (!this.get('officeId')) {
				this.send('logout', true);
				return;
			}

			return baseUrl + '/' + this.get('officeId') + '/' + modelName;
		}
	});
});