define('genesis-residual-system/mirage/fixtures/office-info', ['exports'], function (exports) {
	var officeInfo = {
		'bonuses': [{
			'amount': 50,
			'type': 'LHBMS_Bonus',
			'datePaid': '2019-06-03T04:00:53.227Z',
			'merchantId': '0021644454',
			'merchantName': 'BARRONS',
			'id': 'PPRO8'
		}],
		'miscAdjustments': [{
			'amount': 806.18,
			'description': 'CREDIT FOR TABLESIDE DEMO PER BRIAN JONES',
			'type': 'Miscellaneous'
		}, {
			'amount': 672,
			'description': 'Paying ISO 201912 LH BMS residuals',
			'type': 'Revenue Share'
		}, {
			'amount': 2.5,
			'description': 'TabbedOut Residual for MID: 0020117038 - DBA: PARKWAY GRILLE',
			'type': 'Revenue Share'
		}, {
			'amount': 2.5,
			'description': 'TabbedOut Residual for MID: 0016911824 - DBA: SOLE RISTORANTE ITALIANO',
			'type': 'Revenue Share'
		}, {
			'amount': 243.24,
			'description': 'Assc. Rate Fee Income',
			'type': 'Revenue Share'
		}, {
			'amount': 166.56,
			'description': 'Network Access Fee Income',
			'type': 'Revenue Share'
		}, {
			'amount': 11.25,
			'description': 'Monthly Service Fee Income',
			'type': 'Revenue Share'
		}, {
			'amount': 25,
			'description': "Online Ordering Monthly Fee Commission for 0016997615 - RICO'S PIZZA",
			'type': 'Revenue Share'
		}, {
			'amount': 12,
			'description': 'Online Ordering Monthly Fee Commission for 0021535877 - 55TH & PARK RESTAURANT & LOUNGE',
			'type': 'Revenue Share'
		}],
		'salesRepReport': [{
			'merchantCount': 5,
			'name': 'BRITTANY JORDAN',
			'id': 'BJORD',
			'totalPayout': 1829.51
		}, {
			'merchantCount': 1,
			'name': 'CHRISTOPHER LAUGHERY',
			'id': 'PPRO3',
			'totalPayout': 141.459
		}, {
			'merchantCount': 1,
			'name': 'ERIC HARRISON',
			'id': 'PPROE',
			'totalPayout': 191.46
		}, {
			'merchantCount': 56,
			'name': 'ERIC VANBUSKIRK',
			'id': 'PPROS',
			'totalPayout': 10897.552
		}, {
			'merchantCount': 1,
			'name': 'ERNEST HARNDEN',
			'id': 'PPRO6',
			'totalPayout': 172.4275
		}, {
			'merchantCount': 2,
			'name': 'JEFFREY KOMARA',
			'id': 'PPRO9',
			'totalPayout': 187.006
		}, {
			'merchantCount': 3,
			'name': 'JOE ENGLISH',
			'id': 'PPRO7',
			'totalPayout': 474.5445
		}, {
			'merchantCount': 1,
			'name': 'JOSEPH STILLWAGON',
			'id': 'PPRO2',
			'totalPayout': 230.6985
		}, {
			'merchantCount': 3,
			'name': 'KEVIN USELDINGER',
			'id': 'PPROH',
			'totalPayout': 438.375
		}, {
			'merchantCount': 1,
			'name': 'KWEE KUNTJARA',
			'id': 'PPRO4',
			'totalPayout': 571.977
		}, {
			'merchantCount': 5,
			'name': 'Lane Courkamp',
			'id': '00OUR',
			'totalPayout': 264.802
		}, {
			'merchantCount': 15,
			'name': 'LANE COURKAMP',
			'id': 'LANE1',
			'totalPayout': 398.2565
		}, {
			'merchantCount': 33,
			'name': 'LANE COURKAMP',
			'id': 'LANE4',
			'totalPayout': 1383.1525
		}, {
			'merchantCount': 2,
			'name': 'LANE COURKAMP',
			'id': 'LANE6',
			'totalPayout': 66.216
		}, {
			'merchantCount': 1,
			'name': 'LANE COURKAMP',
			'id': 'LANE9',
			'totalPayout': 19.05
		}, {
			'merchantCount': 1,
			'name': 'LANE COURKAMP',
			'id': 'LANEC',
			'totalPayout': 576.968
		}, {
			'merchantCount': 1,
			'name': 'LANE COURKAMP',
			'id': 'PEG00',
			'totalPayout': 1041.4
		}, {
			'merchantCount': 3,
			'name': 'LANE COURKAMP',
			'id': 'PEG99',
			'totalPayout': 104.38
		}, {
			'merchantCount': 2,
			'name': 'LISA CARR',
			'id': 'PPRO5',
			'totalPayout': 272.5985
		}, {
			'merchantCount': 13,
			'name': 'LISA CARR',
			'id': 'PPRO8',
			'totalPayout': 872.1455
		}, {
			'merchantCount': 3,
			'name': 'MARK PALMER',
			'id': 'PEG02',
			'totalPayout': 248.495
		}, {
			'merchantCount': 3,
			'name': 'MARK PALMER',
			'id': 'PEG66',
			'totalPayout': 1173.485
		}, {
			'merchantCount': 1,
			'name': 'MARTIN BRENERMAN',
			'id': 'PPROA',
			'totalPayout': 366.3705
		}, {
			'merchantCount': 2,
			'name': 'ROBERT BARTON',
			'id': 'PEG01',
			'totalPayout': 510.425
		}, {
			'merchantCount': 8,
			'name': 'RON WARREN',
			'id': 'PPRO1',
			'totalPayout': 1739.6165
		}, {
			'merchantCount': 2,
			'name': 'RYAN EMBLEM',
			'id': 'RYANE',
			'totalPayout': 115.35
		}, {
			'merchantCount': 8,
			'name': 'SCOTT PUTERBAUGH',
			'id': 'PEG03',
			'totalPayout': 1516.8
		}, {
			'merchantCount': 2,
			'name': 'VINCE PLANT',
			'id': 'PEG04',
			'totalPayout': 434.395
		}],
		'thirdPartyReport': [{
			'merchantCount': 2,
			'payout': 3.03,
			'vendor': 'Authorize.Net',
			'volume': 0
		}, {
			'merchantCount': 35,
			'payout': 217.65,
			'vendor': 'HT Gift Cards',
			'volume': 0
		}]
	};

	exports['default'] = officeInfo;
});