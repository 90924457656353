define('genesis-residual-system/components/dynamic-high-charts', ['exports', 'ember', 'ember-highcharts/components/high-charts'], function (exports, _ember, _emberHighchartsComponentsHighCharts) {
	var get = _ember['default'].get;
	exports['default'] = _emberHighchartsComponentsHighCharts['default'].extend({
		callback: function callback(chart) {

			if (!chart.series.any(function (item) {
				return item.data.length > 0;
			})) {
				chart.series.forEach(function (serie) {
					serie.remove();
				});
			}
		},

		didReceiveAttrs: function didReceiveAttrs() {
			if (!(get(this, 'content') && get(this, 'chart'))) {
				return;
			}

			var chart = get(this, 'chart');
			var noData = chart.get('noData');

			if (noData != null) {
				noData.remove();
			}

			this.hideUnusedSeries(chart, get(this, 'content').length);

			this.resetChartCounters(chart);

			this.updateSeries(chart, get(this, 'content'));

			this.updateAxis(chart, this.get('chartOptions'));

			chart.setTitle(this.get('chartOptions').title, null, false);

			return chart.redraw();
		},

		hideUnusedSeries: function hideUnusedSeries(chart, newLength) {
			// If there are less series in the content then in the series, hide the unused series.
			var currentSerieCount = chart.series.length;

			for (var i = newLength; i < currentSerieCount; i++) {
				chart.series[newLength].remove(false);
			}
		},

		resetChartCounters: function resetChartCounters(chart) {
			chart.colorCounter = chart.series.length;
			chart.symbolCounter = chart.series.length;
		},

		updateSeries: function updateSeries(chart, content) {
			var _this = this;

			content.forEach(function (series, idx) {
				if (chart.series[idx]) {
					chart.series[idx].update(_this.getSeriesOptions(chart, series), false);
					return chart.series[idx].setData(series.data, false);
				}
				return chart.addSeries(series, false);
			});
		},

		updateAxis: function updateAxis(chart, chartOptions) {
			chart.yAxis[0].setCategories(chartOptions.yAxis.categories, false);

			chart.xAxis[0].setCategories(chartOptions.xAxis.categories, false);

			chart.xAxis[0].setExtremes(chartOptions.xAxis.min, chartOptions.xAxis.max, false);
		},

		getSeriesOptions: function getSeriesOptions(chart, series) {
			var type = series.type || chart.userOptions.chart.type;
			return { name: series.name, type: type, color: series.color };
		}
	});
});