define('genesis-residual-system/mixins/routes/dashboards/residuals/merchants-mixin', ['exports', 'ember', 'genesis-residual-system/mixins/export-data/residuals-overview-export-data-mixin', 'genesis-residual-system/mixins/data-table/merchants-data-table-mixin', 'genesis-residual-system/mixins/intro-js/merchants-intro-js-mixin', 'genesis-residual-system/config/environment'], function (exports, _ember, _genesisResidualSystemMixinsExportDataResidualsOverviewExportDataMixin, _genesisResidualSystemMixinsDataTableMerchantsDataTableMixin, _genesisResidualSystemMixinsIntroJsMerchantsIntroJsMixin, _genesisResidualSystemConfigEnvironment) {
	exports['default'] = _ember['default'].Mixin.create(_genesisResidualSystemMixinsExportDataResidualsOverviewExportDataMixin['default'], _genesisResidualSystemMixinsDataTableMerchantsDataTableMixin['default'], _genesisResidualSystemMixinsIntroJsMerchantsIntroJsMixin['default'], {
		buildModel: function buildModel(officeHeader, merchantList, officeInfo, residualMonths, params) {
			var model = {};

			model.currentDate = params.accountingPeriod;
			model.selectedDate = params.accountingPeriod;
			model.respTabs = this.getResponsiveTabsConfig(merchantList, officeInfo, params);
			model.residualMonths = residualMonths;
			model.exportData = this.getResidualsOverviewExportDataConfig(officeHeader, merchantList, officeInfo.list, params);
			model.introJS = this.getIntroJSConfig();

			return model;
		},
		getResidualsFilterParams: function getResidualsFilterParams(params) {
			return {
				tabId: params.tabId,
				type: params.type,
				accountingPeriod: params.date,
				filter: {
					accountingPeriod: params.date,
					FeatureID: _genesisResidualSystemConfigEnvironment['default'].ResidualMonthFeatureID,
					officeId: this.get('session.data.authenticated.user')
				}
			};
		},
		getFilterParams: function getFilterParams(params) {
			return {
				tabId: params.tabId,
				type: params.type,
				accountingPeriod: params.accountingPeriod,
				officeHeaderFilterParams: { accountingPeriod: params.accountingPeriod },
				merchantListFilterParams: { accountingPeriod: params.accountingPeriod },
				officeInfoFilterParams: { accountingPeriod: params.accountingPeriod }
			};
		}
	});
});