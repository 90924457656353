define('genesis-residual-system/mirage/serializers/office-info', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	exports['default'] = _emberCliMirage.Serializer.extend({
		serialize: function serialize() {
			// This is how to call super, as Mirage borrows [Backbone's implementation of extend](http://backbonejs.org/#Model-extend)
			var json = _emberCliMirage.Serializer.prototype.serialize.apply(this, arguments);

			// Add metadata, sort parts of the response, etc.

			return json.officeInfos;
		}
	});
});