define('genesis-residual-system/components/sidebar-tile', ['exports', 'ember', 'genesis-residual-system/mixins/resize-sidebar-mixin'], function (exports, _ember, _genesisResidualSystemMixinsResizeSidebarMixin) {
	exports['default'] = _ember['default'].Component.extend(_genesisResidualSystemMixinsResizeSidebarMixin['default'], {
		setupAccordion: (function () {
			var _this = this;

			this._super.apply(this, arguments);
			this.$('.panel').on('hidden.bs.collapse', function () {
				_this.setSidebarHeight();
			});

			this.$('.panel-heading').on('click', function (e) {
				var $el = _ember['default'].$(e.target);
				if ($el.prop('tagName') !== 'A') {
					e.preventDefault();
					$el.closest('.panel-heading').find('a.accordion-toggle').click();
				}
			});
		}).on('didInsertElement'),
		didRender: function didRender() {
			this._super.apply(this, arguments);
			if (this.get('data.isExpanded')) {
				var id = this.get('data.id');
				this.$('#collapse-' + id).addClass('in');
				this.$('a.accordion-toggle').removeClass('collapsed');
			}
		},
		actions: {
			click: function click(e) {
				this.set('data.isExpanded', this.$(e.currentTarget || e.target).hasClass('collapsed'));
			}
		}
	});
});