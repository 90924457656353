define('genesis-residual-system/serializers/merchant-list', ['exports', 'genesis-residual-system/serializers/application'], function (exports, _genesisResidualSystemSerializersApplication) {
	exports['default'] = _genesisResidualSystemSerializersApplication['default'].extend({
		normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
			payload = {
				'merchant-list': { id: 0, list: payload.merchants }
			};

			return this._super(store, primaryModelClass, payload, id, requestType);
		}
	});
});