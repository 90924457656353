define('genesis-residual-system/mixins/data-table/processing-details-data-table-mixin', ['exports', 'ember', 'genesis-residual-system/helpers/format-money', 'genesis-residual-system/mixins/data-table/data-table-mixin'], function (exports, _ember, _genesisResidualSystemHelpersFormatMoney, _genesisResidualSystemMixinsDataTableDataTableMixin) {
	exports['default'] = _ember['default'].Mixin.create(_genesisResidualSystemMixinsDataTableDataTableMixin['default'], {
		getProcessingTabColumns: function getProcessingTabColumns() {
			return [{ 'sClass': 'text-center', 'mData': 'cardType', 'mRender': function mRender(data) {
					return '<span style="display:none">' + data + '</span><img src="/assets/images/' + data.toLowerCase() + '.jpg" alt="' + data + '">';
				} }, { 'sClass': 'text-left', 'mData': 'transactionCount' }, { 'sClass': 'text-right', 'mData': 'volume', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, false, 3]);
				} }, { 'sClass': 'text-left', 'mData': 'description' }, { 'sClass': 'text-right', 'mData': 'averageTicket', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, false, 3]);
				} }, { 'sClass': 'text-left', 'mData': 'icRate' }, { 'sClass': 'text-right', 'mData': 'icAmount', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, false, 3]);
				} }, { 'sClass': 'text-left', 'mData': 'discountRate' }, { 'sClass': 'text-right', 'mData': 'discountCharged', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, false, 3]);
				} }, { 'sClass': 'text-right', 'mData': 'income', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, false, 3]);
				} }];
		},
		getAuthorizationTabColumns: function getAuthorizationTabColumns() {
			return [{ 'sClass': 'text-left', 'mData': 'vendor' }, { 'sClass': 'text-center', 'mData': 'cardType', 'mRender': function mRender(data) {
					return '<img src="/assets/images/' + data.toLowerCase() + '.jpg" alt="' + data + '">';
				} }, { 'sClass': 'text-left', 'mData': 'authCount' }, { 'sClass': 'text-right', 'mData': 'merchantRate', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, false, 4]);
				} }, { 'sClass': 'text-right', 'mData': 'billedToMerchant', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, false, 3]);
				} }, { 'sClass': 'text-right', 'mData': 'isoRate', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, false, 4]);
				} }, { 'sClass': 'text-right', 'mData': 'billedToIso', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, false, 3]);
				} }, { 'sClass': 'text-right', 'mData': 'income', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, false, 3]);
				} }];
		},
		getMiscTabColumns: function getMiscTabColumns() {
			return [{ 'sClass': 'text-left', 'mData': 'description' }, { 'sClass': 'text-left', 'mData': 'itemCount' }, { 'sClass': 'text-right', 'mData': 'billedToMerchant', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, false, 3]);
				} }, { 'sClass': 'text-right', 'mData': 'expenseRate', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, false, 6]);
				} }, { 'sClass': 'text-right', 'mData': 'billedToIso', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, false, 3]);
				} }, { 'sClass': 'text-right', 'mData': 'income', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, false, 3]);
				} }];
		},
		getFixedExpenseTabColumns: function getFixedExpenseTabColumns() {
			return [{ 'sClass': 'text-left', 'mData': 'description' }, { 'sClass': 'text-left', 'mData': 'unitCount' }, { 'sClass': 'text-right', 'mData': 'income', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, false, 3]);
				} }, { 'sClass': 'text-right', 'mData': 'cost', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, false, 3]);
				} }, { 'sClass': 'text-right', 'mData': 'billedToIso', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, false, 3]);
				} }, { 'sClass': 'text-right', 'mData': 'netIncomeExpense', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, false, 3]);
				} }];
		},
		getThirdPartyPayoutTabColumns: function getThirdPartyPayoutTabColumns(params, merchantId) {
			return [{ 'sClass': 'text-left', 'mData': 'vendor', 'mRender': function mRender(data) {
					return '<a data-href="/dashboards/residuals/third-party-residual/' + data + '?date=' + params.accountingPeriod + '&merchantId=' + merchantId + '" href="javascript:void(0);">' + data + '</a>';
				} }, { 'sClass': 'text-right', 'mData': 'payout', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, false, 3]);
				} }, { 'sClass': 'text-right', 'mData': 'volume', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, false, 3]);
				} }, { 'sClass': 'text-left', 'mData': 'transactionCount' }];
		},
		getProcessingTabConfig: function getProcessingTabConfig(merchantProcessing) {
			var tab = {
				id: 1,
				tabId: 'processing',
				isActive: true,
				name: 'Processing',
				headers: ['Card Type', 'Trans', 'Volume', 'IC Category', 'AVG Ticket', 'IC Rate', 'IC Expense', 'Discount Rate', 'Discount Charged', 'Income'],
				filters: [{ name: 'Select Card Type', id: 'cardTypeId', type: 'dropdown', data: merchantProcessing.filterData }],
				note: 'Interchange Categories and Income are marked <span class="redtext">Red</span> when income is a negative value',
				columns: this.getProcessingTabColumns(),
				data: merchantProcessing.list.monetaryInfo,
				columnDefs: [{ width: '9%', targets: [2, 4, 6, 8, 9] }, { width: '5%', targets: [0, 1] }, { width: '11%', targets: [5, 7] }, { width: '23%', targets: 3 }]
			};
			return this.getTabConfig(tab);
		},
		getAuthorizationTabConfig: function getAuthorizationTabConfig(merchantProcessing) {
			var tab = {
				id: 2,
				tabId: 'authorization',
				name: 'Authorization',
				headers: ['Vendor', 'Card Type', 'No. of Auth', 'Merchant Rate', 'Merchant Income', 'ISO Cost', 'ISO Expense', 'Income'],
				columns: this.getAuthorizationTabColumns(),
				data: merchantProcessing.list.authInfo
			};
			return this.getTabConfig(tab);
		},
		getMiscTabConfig: function getMiscTabConfig(merchantProcessing) {
			var tab = {
				id: 3,
				tabId: 'miscellaneous',
				name: 'Miscellaneous',
				headers: ['Item', 'Item Count', 'Billed To Merchant', 'Expense Rate', 'Expense', 'Income'],
				columns: this.getMiscTabColumns(),
				data: merchantProcessing.list.miscInfo,
				columnDefs: [{ width: '40%', targets: 0 }, { width: '12%', targets: [1, 2, 3, 4, 5] }]
			};
			return this.getTabConfig(tab);
		},
		getFixedExpenseTabConfig: function getFixedExpenseTabConfig(merchantProcessing) {
			var tab = {
				id: 4,
				tabId: 'fixed-expense',
				name: 'Fixed Income / Expense',
				headers: ['Item', 'Item Count', 'Income', 'Expense Rate', 'Expense', 'Net Income / Expense'],
				columns: this.getFixedExpenseTabColumns(),
				data: merchantProcessing.list.fixedExpenses,
				columnDefs: [{ width: '40%', targets: 0 }, { width: '12%', targets: [1, 2, 3, 4, 5] }]
			};
			return this.getTabConfig(tab);
		},
		getThirdPartyPayoutTabConfig: function getThirdPartyPayoutTabConfig(merchantProcessing, params, merchantId) {
			var tab = {
				id: 5,
				tabId: 'third-party-payout',
				name: 'Third Party Payout',
				headers: ['Name', 'Payout Amount', 'Volume', '# of Transactions'],
				columns: this.getThirdPartyPayoutTabColumns(params, merchantId),
				data: merchantProcessing.list.thirdPartyInfo,
				columnDefs: [{ width: '25%', targets: [0, 1, 2, 3] }]
			};
			return this.getTabConfig(tab);
		},
		getResponsiveTabsConfig: function getResponsiveTabsConfig(merchantHeader, merchantProcessing, params) {
			return {
				tableCSS: 'resp-tabs-border',
				name: 'Processing Details for MID ' + merchantHeader.merchantPortfolio.id + ' - ' + merchantHeader.merchantPortfolio.name,
				tabs: [this.getProcessingTabConfig(merchantProcessing), this.getAuthorizationTabConfig(merchantProcessing), this.getMiscTabConfig(merchantProcessing), this.getFixedExpenseTabConfig(merchantProcessing), this.getThirdPartyPayoutTabConfig(merchantProcessing, params, merchantHeader.merchantPortfolio.id)]
			};
		}
	});
});