define('genesis-residual-system/components/export-data', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		s2ab: function s2ab(s) {
			var buf = new ArrayBuffer(s.length);
			var view = new Uint8Array(buf);
			for (var i = 0; i < s.length; ++i) {
				view[i] = s.charCodeAt(i);
			}
			return buf;
		},
		setSheetBoundaries: function setSheetBoundaries(range, R, C) {
			if (range.s.r > R) {
				range.s.r = R;
			}
			if (range.s.c > C) {
				range.s.c = C;
			}
			if (range.e.r < R) {
				range.e.r = R;
			}
			if (range.e.c < C) {
				range.e.c = C;
			}
		},
		setCellFormat: function setCellFormat(data, cell, R, C) {
			cell.s = {};
			if (typeof cell.v === 'number') {
				cell.t = 'n';
				if (data[R][C].formatMoney) {
					cell.s.numFmt = typeof data[R][C].formatMoney === 'string' ? data[R][C].formatMoney : '$#,##0.00';
				} else if (data[R][C].formatPercent) {
					cell.s.numFmt = '0.00"%"';
				} else if (cell.v) {
					cell.s.numFmt = '#,##';
				}
			} else if (typeof cell.v === 'boolean') {
				cell.t = 'b';
			} else if (cell.v instanceof Date) {
				cell.t = 'n';
				cell.z = window.XLSX.SSF._table[14];
				cell.v = window.datenum(cell.v);
			} else {
				cell.t = 's';
			}
			if (data[R][C].bold) {
				cell.s.font = { bold: true };
			}
			if (data[R][C].border) {
				cell.s.border = {
					top: { style: 'thin' },
					right: { style: 'thin' },
					bottom: { style: 'thin' },
					left: { style: 'thin' }
				};
			}
		},
		sheetFromArrayOfArrays: function sheetFromArrayOfArrays(data, cols) {
			var ws = { '!cols': [], '!rows': [] },
			    defaultWidthCol = 15,
			    defaultHeightRow = 21,
			    max = 10000000;
			var range = { s: { c: max, r: max }, e: { c: 0, r: 0 } };
			for (var R = 0; R < data.length; ++R) {
				for (var C = 0; C < data[R].length; ++C) {
					this.setSheetBoundaries(range, R, C);
					var cell = { v: data[R][C].value };
					if (cell.v == null) {
						continue;
					}
					var cellRef = window.XLSX.utils.encode_cell({ c: C, r: R });
					this.setCellFormat(data, cell, R, C);
					ws[cellRef] = cell;
					ws['!cols'].push({ wch: defaultWidthCol });
					ws['!rows'].push({ hpx: defaultHeightRow });
				}
			}
			if (range.s.c < max) {
				ws['!ref'] = window.XLSX.utils.encode_range(range);
			}
			if (cols) {
				ws['!cols'] = cols;
			}

			return ws;
		},
		exportToExcel: function exportToExcel(obj) {
			var _this = this;

			var data = obj.data;
			var cols = obj.cols;
			var workbookOpts = { bookType: 'xlsx', bookSST: false, type: 'binary' };
			var workbook = {
				SheetNames: [],
				Sheets: {}
			};
			var workbookOut = undefined;

			Object.keys(data).forEach(function (key) {
				var sheetName = key,
				    sheetData = data[key],
				    sheetCols = cols[key],
				    worksheet = _this.sheetFromArrayOfArrays(sheetData, sheetCols);

				workbook.SheetNames.push(sheetName);
				workbook.Sheets[sheetName] = worksheet;

				workbookOut = window.XLSX.write(workbook, workbookOpts);
			});

			/* the saveAs call downloads a file on the local machine */
			window.saveAs(new Blob([this.s2ab(workbookOut)], { type: 'application/octet-stream' }), obj.fileName);
		},
		actions: {
			'export': function _export(format) {
				var data = this.get('exportData').find(function (item) {
					return item.format === format;
				});
				if (format === 'xlsx') {
					this.exportToExcel(data);
				} else {
					throw 'Export to the ' + format + ' isn\'t implemented';
				}
			}
		}
	});
});