define('genesis-residual-system/mixins/export-data/third-party-residuals-overview-export-data-mixin', ['exports', 'ember', 'moment', 'genesis-residual-system/mixins/export-data/export-data-mixin'], function (exports, _ember, _moment, _genesisResidualSystemMixinsExportDataExportDataMixin) {
	function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

	exports['default'] = _ember['default'].Mixin.create(_genesisResidualSystemMixinsExportDataExportDataMixin['default'], {
		addThirdPartyResidualsOverviewHeader: function addThirdPartyResidualsOverviewHeader(thirdPartyDetails, params) {
			return [[this.addTitleCell('Third Party Residuals Name'), { value: params.type }], [this.addTableHeaderCell('ACH Date'), this.addTableCell(thirdPartyDetails.achDate ? (0, _moment['default'])(thirdPartyDetails.achDate, 'MMM DD YYYY').format('MM/DD/YYYY') : '', true)]];
		},
		addThirdPartyResidualsInfo: function addThirdPartyResidualsInfo(thirdPartyDetails, params) {
			var currentDate = (0, _moment['default'])(params.accountingPeriod, 'YYYY-MM').format('MMMM YYYY'),
			    previousMonth = (0, _moment['default'])(params.accountingPeriod, 'YYYY-MM').subtract(1, 'months').format('MMMM YYYY'),
			    previousYear = (0, _moment['default'])(params.accountingPeriod, 'YYYY-MM').subtract(1, 'years').format('MMMM YYYY');

			return [[this.addEmptyCell(), this.addTableHeaderCell('Residuals Payout for ' + currentDate), this.addTableHeaderCell('# of Deals: ' + thirdPartyDetails.numberOfDeals)], [this.addTableHeaderCell('Amount'), this.addTableCell(thirdPartyDetails.totalPayout, true), this.addEmptyCell()], [this.addTableHeaderCell('Average Payout Per Deal'), this.addEmptyCell(), this.addTableCell(thirdPartyDetails.averagePayoutPerDeal, true)], [this.addTableHeaderCell('Month Over Month'), this.addTableCell(thirdPartyDetails.monthOverMonthChange + '% over ' + previousMonth), this.addTableCell(thirdPartyDetails.avgPayoutMonthOverMonthChange + '% over ' + previousMonth)], [this.addTableHeaderCell('Year Over Year'), this.addTableCell(thirdPartyDetails.yearOverYearChange + '% over ' + previousYear), this.addTableCell(thirdPartyDetails.avgPayoutYearOverYearChange + '% over ' + previousYear)]];
		},
		addThirdPartyResidualOverviewList: function addThirdPartyResidualOverviewList(thirdPartyDetails, params) {
			return [].concat(_toConsumableArray(this.addThirdPartyResidualsOverviewHeader(thirdPartyDetails, params)), [[]], _toConsumableArray(this.addThirdPartyResidualsInfo(thirdPartyDetails, params)));
		},
		addPayoutPerAccountList: function addPayoutPerAccountList(merchants) {
			var attributes = [{ key: 'salesRepId', name: 'Salesrep ID' }, { key: 'id', name: 'Merchant ID' }, { key: 'name', name: 'Merchant Name' }, { key: 'transactionCount', name: 'Transactions' }, { key: 'transIncome', name: 'Trans Inc', formatMoney: true }, { key: 'monthlyFeeIncome', name: 'Monthly Fee Inc', formatMoney: true }, { key: 'monthlyMinIncome', name: 'Monthly Min Inc', formatMoney: true }, { key: 'miscIncome', name: 'Misc Income', formatMoney: true }, { key: 'totalIncome', name: 'Total Payout', formatMoney: true }];

			return this.addTable(merchants, attributes);
		},
		getThirdPartyResidualsOverviewExportDataConfig: function getThirdPartyResidualsOverviewExportDataConfig(thirdPartyDetails, params) {
			var conf = {
				title: 'Download Excel',
				format: 'xlsx',
				fileName: 'Third Party Residuals Overview.xlsx',
				cols: {
					'Overview': [{ wch: 25 }, { wch: 33 }, { wch: 25 }],
					'Payout Per Account': [{ wch: 12 }, { wch: 12 }, { wch: 47 }, { wch: 12 }, { wch: 10 }, { wch: 15 }, { wch: 15 }, { wch: 12 }, { wch: 11 }]
				},
				data: {
					'Overview': [],
					'Payout Per Account': []
				}
			};

			conf.data.Overview = this.addThirdPartyResidualOverviewList(thirdPartyDetails.thirdParty, params);
			conf.data['Payout Per Account'] = this.addPayoutPerAccountList(thirdPartyDetails.merchants);

			return [conf];
		}
	});
});