define('genesis-residual-system/initializers/resolution-service', ['exports', 'genesis-residual-system/services/resolution'], function (exports, _genesisResidualSystemServicesResolution) {
	exports.initialize = initialize;

	/**
  * Init resolution service.
  * @param {application} application The application
  */

	function initialize(application) {
		application.register('resolution:main', _genesisResidualSystemServicesResolution['default'], { singleton: true });
		application.inject('component', 'Resolution', 'service:resolution');
		application.inject('controller', 'Resolution', 'service:resolution');
	}

	exports['default'] = {
		name: 'resolution-service',
		initialize: initialize
	};
});