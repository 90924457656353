define('genesis-residual-system/mixins/data-table/third-party-residuals-data-table-mixin', ['exports', 'ember', 'genesis-residual-system/helpers/format-money', 'moment', 'genesis-residual-system/mixins/data-table/data-table-mixin'], function (exports, _ember, _genesisResidualSystemHelpersFormatMoney, _moment, _genesisResidualSystemMixinsDataTableDataTableMixin) {
	exports['default'] = _ember['default'].Mixin.create(_genesisResidualSystemMixinsDataTableDataTableMixin['default'], {
		getPayoutTabColumns: function getPayoutTabColumns() {
			var mRenderFunc = function mRenderFunc(data) {
				return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data]);
			};

			return [{ 'sClass': 'text-left', 'mData': 'salesRepId' }, { 'sClass': 'text-left', 'mData': 'id' }, { 'sClass': 'text-left', 'mData': 'name' }, { 'sClass': 'text-left', 'mData': 'transactionCount' }, { 'sClass': 'text-right', 'mData': 'transIncome', 'mRender': mRenderFunc }, { 'sClass': 'text-right', 'mData': 'monthlyFeeIncome', 'mRender': mRenderFunc }, { 'sClass': 'text-right', 'mData': 'monthlyMinIncome', 'mRender': mRenderFunc }, { 'sClass': 'text-right', 'mData': 'miscIncome', 'mRender': mRenderFunc }, { 'sClass': 'text-right', 'mData': 'totalIncome', 'mRender': mRenderFunc }];
		},
		getPayoutTabConfig: function getPayoutTabConfig(thirdPartyDetails, params) {
			var tab = {
				id: 1,
				tabId: 'payout-per-account',
				isActive: true,
				name: 'Payout Per Account',
				headers: ['Salesrep ID', 'Merchant ID', 'Merchant Name', 'Transactions', 'Trans Inc', 'Monthly Fee Inc', 'Monthly Min Inc', 'Misc Inc', 'Total Payout'],
				filters: [{ name: 'All Salesrep IDs', id: 'salesRepId', column: 0, type: 'dropdown', data: thirdPartyDetails.filterData }, { name: 'MID, Merchant Name or Salesrep ID', id: 'searchId', type: 'text', selected: params.merchantId }],
				columns: this.getPayoutTabColumns(),
				data: thirdPartyDetails.list.merchants,
				columnDefs: [{ width: '10%', targets: [0, 1] }, { width: '9%', targets: [3, 4, 5, 6, 7, 8] }, { width: '26%', targets: 2 }]
			};
			return this.getTabConfig(tab);
		},
		getResponsiveTabsConfig: function getResponsiveTabsConfig(thirdPartyDetails, params) {
			var selectedDate = (0, _moment['default'])(params.accountingPeriod, 'YYYY-MM').format('YYYYMM');
			return {
				selectedDate: selectedDate,
				tabs: [this.getPayoutTabConfig(thirdPartyDetails, params)]
			};
		}
	});
});