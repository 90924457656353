define('genesis-residual-system/routes/dashboards/residuals/index', ['exports', 'ember', 'genesis-residual-system/mixins/residuals-route-mixin', 'genesis-residual-system/mixins/routes/dashboards/residuals/index-mixin'], function (exports, _ember, _genesisResidualSystemMixinsResidualsRouteMixin, _genesisResidualSystemMixinsRoutesDashboardsResidualsIndexMixin) {
	exports['default'] = _ember['default'].Route.extend(_genesisResidualSystemMixinsResidualsRouteMixin['default'], _genesisResidualSystemMixinsRoutesDashboardsResidualsIndexMixin['default'], {
		queryParams: {
			date: {
				refreshModel: true
			},
			tabId: {
				refreshModel: true
			}
		},
		model: function model(params) {
			var _this = this;

			_ember['default'].$('.loading').fadeIn();

			var model = this.modelFor('dashboards.residuals');

			if (!model || !model.residualMonths) {
				return {};
			}

			var filterParams = this.getFilterParams(model.residualMonths, params);

			return _ember['default'].RSVP.hash({
				merchantList: this.store.queryRecord('merchant-list', filterParams.merchantListFilterParams)
			}).then(function (response) {
				return _this.buildModel(model.officeHeader, {
					list: response.merchantList.get('sortByVolumeList'),
					filterData: response.merchantList.get('uniqSalesRep')
				}, model.officeInfo, model.residualMonths, filterParams);
			});
		},
		actions: {
			setDate: function setDate(date) {
				var _this2 = this;

				_ember['default'].$('.loading').fadeIn();

				var params = this.getOfficeAndMerchantsFilterParams(this.currentModel, date),
				    requests = this.getOfficeAndMerchantsRequests(this.store, params);

				return _ember['default'].RSVP.hash(requests).then(function (response) {
					var model = _this2.buildModel(response.officeHeader.data, {
						list: response.merchantList.data.list,
						filterData: response.merchantList.get('uniqSalesRep')
					}, response.officeInfo, response.residualMonths.data.list, params);
					_this2.updateModel(model);
					var sidebarFilterParams = _this2.getSidebarFilterParams(params);
					_this2.send('updateSidebar', response.officeHeader.data, response.officeInfo, response.residualMonths.data.list, sidebarFilterParams);
					_this2.send('updateExportDataConfig', model.exportData);
					_ember['default'].$('.loading').fadeOut();
				});
			}
		}
	});
});