define('genesis-residual-system/mixins/residuals-route-mixin', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'genesis-residual-system/mixins/resize-sidebar-mixin', 'genesis-residual-system/mixins/update-model-mixin', 'moment'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _genesisResidualSystemMixinsResizeSidebarMixin, _genesisResidualSystemMixinsUpdateModelMixin, _moment) {
	exports['default'] = _ember['default'].Mixin.create(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _genesisResidualSystemMixinsResizeSidebarMixin['default'], _genesisResidualSystemMixinsUpdateModelMixin['default'], {
		activate: function activate() {
			document.title = 'Dashboard - Residuals';
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			_ember['default'].run.schedule('afterRender', this, function () {
				this.setSidebarHeight();

				if (this.get('screen.isMobile') && this.get('routeName') !== 'dashboards.residuals.index') {
					_ember['default'].$('html, body').animate({
						scrollTop: _ember['default'].$('.main').offset().top + 'px'
					}, 500);
				}

				this.send('startTutorial');
			});
		},
		afterModel: function afterModel(model, transition) {
			var _this = this;

			_ember['default'].$('body').removeClass('login');
			_ember['default'].$('.loading').fadeOut();

			transition.then(function () {
				_this.send('updateExportDataConfig', _this.currentModel.exportData);
				var filter = {
					router: transition.targetName,
					params: transition.queryParams,
					type: transition.params[transition.targetName].type
				};
				_this.send('updateSecondaryNavbar', filter);
			});
		},
		getResidualsFilterParams: function getResidualsFilterParams(date) {
			var accountingPeriod = (0, _moment['default'])(date).format('YYYY-MM');

			return {
				accountingPeriod: accountingPeriod
			};
		},
		getOfficeAndMerchantsFilterParams: function getOfficeAndMerchantsFilterParams(model, date) {
			var accountingPeriod = (0, _moment['default'])(date).format('YYYY-MM');

			return {
				accountingPeriod: accountingPeriod,
				tabId: model.respTabs ? model.respTabs.tabId : null
			};
		},
		getOfficeAndThirdPartyResidualsFilterParams: function getOfficeAndThirdPartyResidualsFilterParams(model, date) {
			var accountingPeriod = (0, _moment['default'])(date).format('YYYY-MM');

			return {
				accountingPeriod: accountingPeriod,
				type: model.type
			};
		},
		getOfficeAndMerchantFilterParams: function getOfficeAndMerchantFilterParams(model, date) {
			var accountingPeriod = (0, _moment['default'])(date).format('YYYY-MM');

			return {
				accountingPeriod: accountingPeriod,
				merchantId: model.merchantId
			};
		},
		getResidualsRequests: function getResidualsRequests(store, _ref) {
			var accountingPeriod = _ref.accountingPeriod;

			return {
				officeHeader: store.queryRecord('office-header', { accountingPeriod: accountingPeriod }),
				officeInfo: store.queryRecord('office-info', { accountingPeriod: accountingPeriod })
			};
		},
		getOfficeRequests: function getOfficeRequests(store, params) {
			return _ember['default'].$.extend({}, this.getResidualsRequests(store, params), {
				residualMonths: store.queryRecord('residual-month', {})
			});
		},
		getOfficeAndMerchantsRequests: function getOfficeAndMerchantsRequests(store, params) {
			return _ember['default'].$.extend({}, this.getOfficeRequests(store, params), {
				merchantList: store.queryRecord('merchant-list', { accountingPeriod: params.accountingPeriod })
			});
		},
		getOfficeAndThirdPartyResidualsRequests: function getOfficeAndThirdPartyResidualsRequests(store, params) {
			return _ember['default'].$.extend({}, this.getOfficeRequests(store, params), {
				thirdPartyDetails: store.queryRecord('third-party-detail', params.thirdPartyDetailsParams)
			});
		},
		getOfficeAndMerchantDetailsRequests: function getOfficeAndMerchantDetailsRequests(store, params) {
			return _ember['default'].$.extend({}, this.getOfficeRequests(store, params), {
				merchantHeader: store.queryRecord('merchant-header', params),
				merchantProcessing: store.queryRecord('merchant-processing', params)
			});
		},
		getSidebarFilterParams: function getSidebarFilterParams(params) {
			return {
				router: this.routeName,
				params: {
					tabId: this.controller.tabId,
					type: this.controller.type
				},
				accountingPeriod: params.accountingPeriod
			};
		}
	});
});