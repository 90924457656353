define('genesis-residual-system/helpers/format-money', ['exports', 'ember'], function (exports, _ember) {
	var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

	exports.formatMoney = formatMoney;

	/**
  * The format money.
  * @param {params} params The params.
  * @returns {string} The formatted string
  */

	function formatMoney(params /*, hash*/) {
		var _params = _slicedToArray(params, 3);

		var value = _params[0];
		var isAbsolute = _params[1];
		var decimalPlaces = _params[2];

		if (isAbsolute) {
			value = Math.abs(value);
		}

		if (!decimalPlaces) {
			decimalPlaces = 2;
		}

		var isNegative = value < 0;
		if (isNegative) {
			value = Math.abs(value);
		}

		value = (Math.round(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)).toFixed(decimalPlaces);

		var parts = value.split('.');
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		parts = parts.join('.');

		if (!isNegative) {
			return '$' + parts;
		}

		return '-$' + [parts];
	}

	exports['default'] = _ember['default'].Helper.helper(formatMoney);
});