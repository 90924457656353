define('genesis-residual-system/components/resp-tabs', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		didRender: function didRender() {
			var that = this;
			this.$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
				var target = that.$(e.target).attr('href');
				target = target.substr(1, target.length);
				that.$('[href="#collapse-' + target + '"]').click();
			});
		},
		actions: {
			redirect: function redirect(params) {
				this.sendAction('redirectAction', params);
			}
		}
	});
});