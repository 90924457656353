define('genesis-residual-system/components/charts-theme/default-theme', ['exports'], function (exports) {
	exports['default'] = {
		colors: ['#258be2', '#666666', '#f45b5b', '#8085e9', '#8d4654', '#7798bf', '#aaeeee', '#ff0066', '#eeaaee', '#55bf3b', '#df5353', '#7798bf', '#aaeeee'],
		chart: {
			backgroundColor: null,
			style: {
				fontFamily: "'Lucida Grande', 'Lucida Sans Unicode', Arial, Helvetica, sans-serif",
				fontSize: '12px'
			}
		},
		title: {
			style: {
				color: 'black',
				fontSize: '18px'
			}
		},
		subtitle: {
			style: {
				color: 'black'
			}
		},
		tooltip: {
			borderWidth: 1,
			style: {
				fontSize: '14px'
			}
		},
		legend: {
			itemStyle: {
				fontWeight: 'bold',
				fontSize: '14px'
			}
		},
		xAxis: {
			labels: {
				style: {
					color: '#6e6e70',
					fontSize: '12px'
				}
			},
			title: {
				style: {
					fontSize: '14px'
				}
			}
		},
		yAxis: {
			labels: {
				style: {
					color: '#6e6e70',
					fontSize: '12px'
				}
			},
			title: {
				style: {
					fontSize: '14px'
				}
			}
		},
		plotOptions: {
			series: {
				shadow: false
			},
			candlestick: {
				lineColor: '#404048'
			}
		},
		navigator: {
			xAxis: {
				gridLineColor: '#D0D0D8'
			}
		},
		rangeSelector: {
			buttonTheme: {
				fill: 'white',
				stroke: '#C0C0C8',
				'stroke-width': 1,
				states: {
					select: {
						fill: '#D0D0D8'
					}
				}
			}
		},
		scrollbar: {
			trackBorderColor: '#C0C0C8'
		},
		background2: '#E0E0E8',
		global: {
			timezoneOffset: new Date().getTimezoneOffset()
		},
		credits: {
			enabled: false
		}
	};
});