define('genesis-residual-system/mixins/session-modal-mixin', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Mixin.create({
		sessionTimeout: 121000,
		idleTimeout: 600000,
		_timerIntervalID: null,
		_tick: 1000,
		_idleTimeoutID: null,
		_startTimer: function _startTimer() {
			this._idleTimeoutID = _ember['default'].run.later(this, this._goInactive, this.idleTimeout);
		},
		_resetTimer: function _resetTimer() {
			_ember['default'].run.cancel(this._idleTimeoutID);
			delete this._idleTimeoutID;

			this._goActive();
		},
		_goInactive: function _goInactive() {
			this.send('showModal', 'components/session-modal', this.currentModel);
		},
		_goActive: function _goActive() {
			this._startTimer();
		},
		_initEvents: function _initEvents() {
			var callabck = (function () {
				this._resetTimer();
			}).bind(this);

			_ember['default'].$(window).on('mousemove', callabck);
			_ember['default'].$(window).on('mousedown', callabck);
			_ember['default'].$(window).on('keypress', callabck);
			_ember['default'].$(window).on('DOMMouseScroll', callabck);
			_ember['default'].$(window).on('mousewheel', callabck);
			_ember['default'].$(window).on('touchmove', callabck);
			_ember['default'].$(window).on('MSPointerMove', callabck);

			this._resetTimer();
		},
		_updateTimer: function _updateTimer(endTime) {
			var time = this._getTimeRemaining(endTime);
			_ember['default'].$('.timer').text(time.minutes + ':' + ('0' + time.seconds).slice(-2));

			if (time.total <= 0) {
				this._clearModalTimer();

				this._closeModal();
			} else {
				this._initModalTimer(endTime);
			}
		},
		_closeModal: function _closeModal() {
			_ember['default'].$('.modal').modal('hide');
			this.send('logout', true);
		},
		_getTimeRemaining: function _getTimeRemaining(endTime) {
			var time = endTime - new Date().getTime();
			var seconds = Math.floor(time / 1000 % 60);
			var minutes = Math.floor(time / 1000 / 60 % 60);
			return {
				'total': time,
				'minutes': minutes,
				'seconds': seconds
			};
		},

		_initModalTimer: function _initModalTimer(endTime) {
			this._clearModalTimer();

			this._timerIntervalID = _ember['default'].run.later(this, this._updateTimer, endTime, this._tick);
		},
		_clearModalTimer: function _clearModalTimer() {
			_ember['default'].run.cancel(this._timerIntervalID);
			delete this._timerIntervalID;
		},

		initModal: function initModal() {
			this._clearModalTimer();
			this._initEvents();
		},
		initTimer: function initTimer(time) {
			this._initModalTimer(this.sessionTimeout + time);
		}
	});
});