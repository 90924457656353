define('genesis-residual-system/components/modal-dialog', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		show: (function () {
			var _this = this;

			this.$('.modal').modal({ keyboard: false, backdrop: 'static' }).on('shown.bs.modal', function () {
				_this.sendAction('initAction');
			});
		}).on('didInsertElement'),
		actions: {
			ok: function ok() {
				this.$('.modal').modal('hide');
				this.sendAction('okAction', this.get('okActionParams'));
			},
			cancel: function cancel() {
				this.$('.modal').modal('hide');
				this.sendAction('closeAction', this.get('closeActionParams'));
			}
		}
	});
});