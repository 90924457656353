define("genesis-residual-system/templates/dashboards/residuals/processing-details", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 165
          }
        },
        "moduleName": "genesis-residual-system/templates/dashboards/residuals/processing-details.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-lg-12 col-md-12 col-sm-12 col-xs-12");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "resp-tabs", [], ["table", ["subexpr", "@mut", [["get", "model.respTabs", ["loc", [null, [3, 24], [3, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "redirectAction", "redirect"], ["loc", [null, [3, 6], [3, 66]]], 0, 0], ["inline", "intro-js", [], ["steps", ["subexpr", "@mut", [["get", "model.steps", ["loc", [null, [7, 17], [7, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "start-if", ["subexpr", "@mut", [["get", "model.startTutorial", ["loc", [null, [7, 38], [7, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "tooltip-position", "auto", "scroll-to-element", "false", "on-exit", "introOnComplete", "on-complete", "introOnComplete"], ["loc", [null, [7, 0], [7, 165]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});