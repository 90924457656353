define('genesis-residual-system/helpers/format-number', ['exports', 'ember'], function (exports, _ember) {
  exports.formatNumber = formatNumber;

  /**
   * The format number.
   * @param {value} value The params.
   * @returns {string} The formatted string
   */

  function formatNumber(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  exports['default'] = _ember['default'].Helper.helper(formatNumber);
});