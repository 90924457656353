define('genesis-residual-system/serializers/office-info', ['exports', 'genesis-residual-system/serializers/application'], function (exports, _genesisResidualSystemSerializersApplication) {
	exports['default'] = _genesisResidualSystemSerializersApplication['default'].extend({
		normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
			payload.id = 0;
			payload = {
				'office-info': payload
			};

			return this._super(store, primaryModelClass, payload, id, requestType);
		}
	});
});