define('genesis-residual-system/components/date-picker', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
	exports['default'] = _ember['default'].Component.extend({
		tagName: 'a',
		classNames: ['monthPickerLink'],
		href: '#',
		init: function init() {
			this._super.apply(this, arguments);
		},
		setupBootstrapDatepicker: (function () {
			var _this = this;

			this._super.apply(this, arguments);
			var months = this.get('availableMonths'),
			    startDate = months[months.length - 1].month || '-1y',
			    endDate = months[0].month || '0d',
			    currentDate = this.get('currentDate');
			this.$('.monthPicker').val(_moment['default'].utc(currentDate, 'YYYY-MM').format('MMMM YYYY')).datepicker({
				format: 'MM yyyy',
				startView: 'months',
				minViewMode: 'months',
				maxViewMode: 'years',
				orientation: 'auto',
				autoclose: true,
				startDate: _moment['default'].utc(startDate).format('MMMM YYYY'),
				endDate: _moment['default'].utc(endDate).format('MMMM YYYY')
			}).on('changeDate', function (ev) {
				_this.sendAction('pickedDate', ev.date);
			});
			_ember['default'].$('.monthPickerLink').click(function () {
				_this.$('.monthPicker').datepicker('show');
			});
		}).on('didInsertElement')
	});
});