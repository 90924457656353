define('genesis-residual-system/mixins/routes/dashboards/residuals/merchant-mixin', ['exports', 'ember', 'moment', 'genesis-residual-system/helpers/format-money', 'genesis-residual-system/mixins/export-data/merchant-residual-detail-export-data-mixin', 'genesis-residual-system/mixins/volume-by-card-types-mixin', 'genesis-residual-system/mixins/intro-js/merchant-intro-js-mixin'], function (exports, _ember, _moment, _genesisResidualSystemHelpersFormatMoney, _genesisResidualSystemMixinsExportDataMerchantResidualDetailExportDataMixin, _genesisResidualSystemMixinsVolumeByCardTypesMixin, _genesisResidualSystemMixinsIntroJsMerchantIntroJsMixin) {
	exports['default'] = _ember['default'].Mixin.create(_genesisResidualSystemMixinsExportDataMerchantResidualDetailExportDataMixin['default'], _genesisResidualSystemMixinsVolumeByCardTypesMixin['default'], _genesisResidualSystemMixinsIntroJsMerchantIntroJsMixin['default'], {
		getInfoHeader: function getInfoHeader(_ref, params) {
			var merchantPortfolio = _ref.merchantPortfolio;
			var merchantPayout = _ref.merchantPayout;

			var currentDate = (0, _moment['default'])(params.accountingPeriod, 'YYYY-MM').format('MMMM YYYY'),
			    previousMonth = (0, _moment['default'])(params.accountingPeriod, 'YYYY-MM').subtract(1, 'months').format('MMMM YYYY'),
			    previousYear = (0, _moment['default'])(params.accountingPeriod, 'YYYY-MM').subtract(1, 'years').format('MMMM YYYY'),
			    openedLessThanYear = new Date().setYear(new Date().getFullYear() - 1) < new Date(merchantPortfolio.approvalDate),
			    percentagePos = merchantPortfolio.split.indexOf('%'),
			    split = merchantPortfolio.split.substring(percentagePos - 2, percentagePos),
			    grossRevenue = merchantPayout.income - merchantPayout.expense;

			return {
				info: {
					id: merchantPortfolio.id,
					name: merchantPortfolio.name,
					salesRepId: merchantPortfolio.salesRepId,
					split: merchantPortfolio.split,
					productType: merchantPortfolio.productType,
					approvalDate: { value: (0, _moment['default'])(merchantPortfolio.approvalDate), highlightCSS: openedLessThanYear ? 'approvalDateYoung' : 'approvalDateOld' },
					popoverApprovalDate: {
						content: openedLessThanYear ? '<small><b>Open less than a year</b></small>' : '<small><b>Open for a year or more</b></small>',
						placement: 'top'
					}
				},
				payouts: {
					currentDate: currentDate,
					previousMonth: previousMonth,
					previousYear: previousYear,
					income: merchantPayout.income,
					expense: merchantPayout.expense,
					grossRevenue: grossRevenue,
					split: split,
					incomeAfterSplit: grossRevenue * split / 100,
					fixedExpense: merchantPayout.fixedExpense,
					fixedIncome: merchantPayout.fixedIncome,
					totalIncome: merchantPayout.totalIncome,
					rejected: merchantPortfolio.isRejected,
					popoverTotalIncome: {
						content: '<small><b>Residuals withheld due to processing fee rejects</b></small>',
						placement: 'top'
					},
					percentChangeMoM: merchantPayout.monthOverMonthPercentChange,
					percentChangeYoY: merchantPayout.yearOverYearPercentChange,
					popoverIncome: {
						content: '<table class="table table-bordered payouts">' + '<tr><td class="text-left income-split text-capitalize"><b>Processing </b></td>' + ('<td class="text-right text-capitalize"><b>' + (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([merchantPayout.processingIncome]) + '</b></td></tr>') + '<tr><td class="text-left income-split text-capitalize"><b>Authorization</b></td>' + ('<td class="text-right text-capitalize"><b>' + (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([merchantPayout.authIncome]) + '</b></td></tr>') + '<tr><td class="text-left income-split text-capitalize"><b>Miscellaneous</b></td>' + ('<td class="text-right text-capitalize"><b>' + (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([merchantPayout.miscIncome]) + '</b></td></tr></table>'),
						placement: 'top'
					}
				}
			};
		},
		getCategoriesConfig: function getCategoriesConfig(categories) {
			return {
				tableCSS: 'basicTable ' + (categories.length > 0 ? 'categoriesTable' : ''),
				title: 'Top 10 IC Categories by Profit',
				header: ['Card Type', 'Category', 'Profit', 'Sales', 'Volume'],
				body: categories.map(function (item) {
					return [{ value: item.cardType.toLowerCase(), align: 'text-center', imageType: true }, { value: item.category, align: 'text-center' }, { value: item.profit, align: 'text-right', formatMoney: true }, { value: item.salesCount, align: 'text-right' }, { value: item.volume, align: 'text-right', formatMoney: true }];
				})
			};
		},
		buildModel: function buildModel(merchantHeader, merchantProcessing, residualMonths, params) {
			var model = {};

			model.currentDate = params.accountingPeriod;
			model.selectedDate = params.accountingPeriod;
			model.merchantId = merchantHeader.merchantPortfolio.id;
			model.volume = this.getCardTypesConfig(merchantHeader.cardTypeVolumes, params);
			model.infoHeader = this.getInfoHeader(merchantHeader, params);
			model.categories = this.getCategoriesConfig(merchantHeader.profitInterchangeCategories);
			model.exportData = this.getMerchantResidualDetailExportDataConfig(merchantHeader, merchantProcessing.list, params);
			model.residualMonths = residualMonths;
			model.merchantHeader = merchantHeader;
			model.merchantProcessing = merchantProcessing;
			model.introJS = this.getIntroJSConfig();

			return model;
		},
		getFilterParams: function getFilterParams(params) {
			var filterParams = {
				accountingPeriod: params.date,
				merchantId: params.merchantId
			};

			return {
				accountingPeriod: params.date,
				merchantFilterParams: filterParams
			};
		}
	});
});