define('genesis-residual-system/models/office-header', ['exports', 'ember-data'], function (exports, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		officeEarnings: _emberData['default'].attr(''),
		monthlyVolume: _emberData['default'].attr(''),
		productResiduals: _emberData['default'].attr(''),
		archives: _emberData['default'].attr(''),
		merchantCountReport: _emberData['default'].attr(''),
		industryBreakdowns: _emberData['default'].attr(''),
		brand: _emberData['default'].attr('')
	});
});