define('genesis-residual-system/mixins/routes/dashboards/residuals/index-mixin', ['exports', 'ember', 'moment', 'genesis-residual-system/mixins/export-data/residuals-overview-export-data-mixin', 'genesis-residual-system/mixins/volume-by-card-types-mixin', 'genesis-residual-system/mixins/intro-js/index-intro-js-mixin', 'genesis-residual-system/highcharts/portfolio-by-industry-highchart', 'genesis-residual-system/highcharts/product-mix-highchart', 'genesis-residual-system/highcharts/sales-rep-performance-highchart'], function (exports, _ember, _moment, _genesisResidualSystemMixinsExportDataResidualsOverviewExportDataMixin, _genesisResidualSystemMixinsVolumeByCardTypesMixin, _genesisResidualSystemMixinsIntroJsIndexIntroJsMixin, _genesisResidualSystemHighchartsPortfolioByIndustryHighchart, _genesisResidualSystemHighchartsProductMixHighchart, _genesisResidualSystemHighchartsSalesRepPerformanceHighchart) {
	exports['default'] = _ember['default'].Mixin.create(_genesisResidualSystemMixinsExportDataResidualsOverviewExportDataMixin['default'], _genesisResidualSystemMixinsVolumeByCardTypesMixin['default'], _genesisResidualSystemMixinsIntroJsIndexIntroJsMixin['default'], {
		buildModel: function buildModel(officeHeader, merchantList, officeInfo, residualMonths, params) {
			var model = {};
			model.currentDate = params.accountingPeriod;
			model.selectedDate = params.accountingPeriod;
			model.monthlyVolume = this.getCardTypesConfig(officeHeader.monthlyVolume, params);
			model.productMixChart = _genesisResidualSystemHighchartsProductMixHighchart['default'].create({ data: officeHeader.productResiduals }).getHighchartConfig();
			model.portfolioByIndustryChart = _genesisResidualSystemHighchartsPortfolioByIndustryHighchart['default'].create({ data: officeHeader.industryBreakdowns }).getHighchartConfig();
			model.salesRepPerformanceChart = _genesisResidualSystemHighchartsSalesRepPerformanceHighchart['default'].create({ data: officeInfo.get('salesRepReport') }).getHighchartConfig();
			model.residualMonths = residualMonths;
			model.exportData = this.getResidualsOverviewExportDataConfig(officeHeader, merchantList, officeInfo, params);
			model.officeHeader = officeHeader;
			model.officeInfo = officeInfo;
			model.merchantList = merchantList;
			model.introJS = this.getIntroJSConfig();

			return model;
		},
		getFilterParams: function getFilterParams(months, params) {
			if (!params.date) {
				params.date = _moment['default'].utc(months[0].month).format('YYYY-MM');
			}
			var filterParams = {
				accountingPeriod: params.date
			};

			return {
				accountingPeriod: params.date,
				merchantListFilterParams: filterParams
			};
		}
	});
});