define('genesis-residual-system/routes/dashboards/residuals/third-party-residual', ['exports', 'ember', 'genesis-residual-system/mixins/residuals-route-mixin', 'genesis-residual-system/mixins/routes/dashboards/residuals/third-party-residual-mixin'], function (exports, _ember, _genesisResidualSystemMixinsResidualsRouteMixin, _genesisResidualSystemMixinsRoutesDashboardsResidualsThirdPartyResidualMixin) {
	exports['default'] = _ember['default'].Route.extend(_genesisResidualSystemMixinsResidualsRouteMixin['default'], _genesisResidualSystemMixinsRoutesDashboardsResidualsThirdPartyResidualMixin['default'], {
		queryParams: {
			date: {
				refreshModel: true
			},
			merchantId: {
				refreshModel: true
			}
		},
		model: function model(params) {
			var _this = this;

			_ember['default'].$('.loading').fadeIn();

			var model = this.modelFor('dashboards.residuals');
			if (!model) {
				this.transitionTo('dashboards.residuals');
				return {};
			}

			var filterParams = this.getFilterParams(params);
			return _ember['default'].RSVP.hash({
				thirdPartyDetails: this.store.queryRecord('third-party-detail', filterParams.thirdPartyDetailsFilterParams)
			}).then(function (response) {
				return _this.buildModel({
					list: response.thirdPartyDetails.data,
					filterData: response.thirdPartyDetails.get('uniqSalesRep')
				}, model.residualMonths, filterParams);
			});
		},
		actions: {
			setDate: function setDate(date) {
				var _this2 = this;

				_ember['default'].$('.loading').fadeIn();

				var params = this.getOfficeAndThirdPartyResidualsFilterParams(this.currentModel, date),
				    requests = this.getOfficeAndThirdPartyResidualsRequests(this.store, params);

				_ember['default'].RSVP.hash(requests).then(function (response) {
					var model = _this2.buildModel({
						list: response.thirdPartyDetails.data,
						filterData: response.thirdPartyDetails.get('uniqSalesRep')
					}, response.residualMonths.data.list, params);
					_this2.updateModel(model);
					var sidebarFilterParams = _this2.getSidebarFilterParams(params);
					_this2.send('updateSidebar', response.officeHeader.data, response.officeInfo, response.residualMonths.data.list, sidebarFilterParams);
					_this2.send('updateExportDataConfig', model.exportData);

					_ember['default'].$('.loading').fadeOut();
				});
			}
		}
	});
});