define("genesis-residual-system/templates/components/sidebar-tile", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 49
              },
              "end": {
                "line": 17,
                "column": 127
              }
            },
            "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "data.subTitle", ["loc", [null, [17, 110], [17, 127]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 12
            },
            "end": {
              "line": 20,
              "column": 12
            }
          },
          "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-md-12 col-sm-12 col-xs-12");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("strong");
          dom.setAttribute(el3, "class", "subTitle");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]), 0, 0);
          return morphs;
        },
        statements: [["block", "link-to", [["get", "data.link", ["loc", [null, [17, 60], [17, 69]]], 0, 0, 0, 0], ["subexpr", "query-params", [], ["date", ["get", "date", ["loc", [null, [17, 89], [17, 93]]], 0, 0, 0, 0], "tabId", ["get", "data.id", ["loc", [null, [17, 100], [17, 107]]], 0, 0, 0, 0]], ["loc", [null, [17, 70], [17, 108]]], 0, 0]], [], 0, null, ["loc", [null, [17, 49], [17, 139]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 26,
                    "column": 32
                  },
                  "end": {
                    "line": 26,
                    "column": 157
                  }
                },
                "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["content", "item.title", ["loc", [null, [26, 143], [26, 157]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 28
                },
                "end": {
                  "line": 27,
                  "column": 28
                }
              },
              "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["block", "link-to", [["get", "item.link", ["loc", [null, [26, 43], [26, 52]]], 0, 0, 0, 0], ["get", "item.params.segment", ["loc", [null, [26, 53], [26, 72]]], 0, 0, 0, 0], ["subexpr", "query-params", [], ["date", ["get", "date", ["loc", [null, [26, 92], [26, 96]]], 0, 0, 0, 0], "tabId", ["get", "item.params.tab", ["loc", [null, [26, 103], [26, 118]]], 0, 0, 0, 0], "type", ["get", "item.params.type", ["loc", [null, [26, 124], [26, 140]]], 0, 0, 0, 0]], ["loc", [null, [26, 73], [26, 141]]], 0, 0]], [], 0, null, ["loc", [null, [26, 32], [26, 169]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 28,
                    "column": 32
                  },
                  "end": {
                    "line": 28,
                    "column": 137
                  }
                },
                "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["content", "item.title", ["loc", [null, [28, 123], [28, 137]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 28
                },
                "end": {
                  "line": 29,
                  "column": 28
                }
              },
              "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["block", "link-to", [["get", "item.link", ["loc", [null, [28, 43], [28, 52]]], 0, 0, 0, 0], ["subexpr", "query-params", [], ["date", ["get", "date", ["loc", [null, [28, 72], [28, 76]]], 0, 0, 0, 0], "tabId", ["get", "item.params.tab", ["loc", [null, [28, 83], [28, 98]]], 0, 0, 0, 0], "type", ["get", "item.params.type", ["loc", [null, [28, 104], [28, 120]]], 0, 0, 0, 0]], ["loc", [null, [28, 53], [28, 121]]], 0, 0]], [], 0, null, ["loc", [null, [28, 32], [28, 149]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 24
              },
              "end": {
                "line": 30,
                "column": 24
              }
            },
            "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "item.params.segment", ["loc", [null, [25, 34], [25, 53]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [25, 28], [29, 35]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 24
              },
              "end": {
                "line": 32,
                "column": 24
              }
            },
            "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "item.title", ["loc", [null, [31, 28], [31, 42]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 20
              },
              "end": {
                "line": 36,
                "column": 20
              }
            },
            "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createElement("span");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element7, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element7, [0]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["col-md-5 col-sm-4 col-xs-4 text-right redtext ", ["get", "item.textColor", ["loc", [null, [35, 84], [35, 98]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "format-money", [["get", "item.value", ["loc", [null, [35, 123], [35, 133]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 108], [35, 135]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 39,
                  "column": 28
                },
                "end": {
                  "line": 39,
                  "column": 82
                }
              },
              "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "popover-info", [], ["data", ["subexpr", "@mut", [["get", "item.popover", ["loc", [null, [39, 68], [39, 80]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [39, 48], [39, 82]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 20
              },
              "end": {
                "line": 41,
                "column": 20
              }
            },
            "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element6, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
            morphs[2] = dom.createMorphAt(element6, 3, 3);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["col-md-5 col-sm-4 col-xs-4 text-right ", ["get", "item.textColor", ["loc", [null, [37, 76], [37, 90]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "format-money", [["get", "item.value", ["loc", [null, [38, 49], [38, 59]]], 0, 0, 0, 0]], [], ["loc", [null, [38, 34], [38, 61]]], 0, 0], ["block", "if", [["get", "item.popover", ["loc", [null, [39, 34], [39, 46]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [39, 28], [39, 89]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child4 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 44,
                  "column": 28
                },
                "end": {
                  "line": 46,
                  "column": 28
                }
              },
              "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col-md-12 col-sm-12 col-xs-12 text-left small redtext");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("% over Last Year");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "item.percentChangeYoY", ["loc", [null, [45, 99], [45, 124]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 46,
                  "column": 28
                },
                "end": {
                  "line": 48,
                  "column": 28
                }
              },
              "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col-md-12 col-sm-12 col-xs-12 text-left small text-success");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("% over Last Year");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "item.percentChangeYoY", ["loc", [null, [47, 104], [47, 129]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 28
                },
                "end": {
                  "line": 52,
                  "column": 28
                }
              },
              "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col-md-12 col-sm-12 col-xs-12 text-left small redtext");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("% over Last Month");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "item.percentChangeMoM", ["loc", [null, [51, 99], [51, 124]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 52,
                  "column": 28
                },
                "end": {
                  "line": 54,
                  "column": 28
                }
              },
              "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col-md-12 col-sm-12 col-xs-12 text-left small text-success");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("% over Last Month");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "item.percentChangeMoM", ["loc", [null, [53, 104], [53, 129]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 20
              },
              "end": {
                "line": 56,
                "column": 20
              }
            },
            "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "top-offset");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element5, 1, 1);
            morphs[1] = dom.createMorphAt(element5, 3, 3);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "compare-operation", [["get", "item.percentChangeYoY", ["loc", [null, [44, 53], [44, 74]]], 0, 0, 0, 0], "<", 0], [], ["loc", [null, [44, 34], [44, 81]]], 0, 0]], [], 0, 1, ["loc", [null, [44, 28], [48, 35]]]], ["block", "if", [["subexpr", "compare-operation", [["get", "item.percentChangeMoM", ["loc", [null, [50, 53], [50, 74]]], 0, 0, 0, 0], "<", 0], [], ["loc", [null, [50, 34], [50, 81]]], 0, 0]], [], 2, 3, ["loc", [null, [50, 28], [54, 35]]]]],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 12
            },
            "end": {
              "line": 58,
              "column": 12
            }
          },
          "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-md-7 col-sm-8 col-xs-8");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element8, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(element8, 3, 3);
          morphs[2] = dom.createMorphAt(element8, 4, 4);
          return morphs;
        },
        statements: [["block", "if", [["get", "item.link", ["loc", [null, [24, 30], [24, 39]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [24, 24], [32, 31]]]], ["block", "if", [["subexpr", "compare-operation", [["get", "item.value", ["loc", [null, [34, 45], [34, 55]]], 0, 0, 0, 0], "<", 0], [], ["loc", [null, [34, 26], [34, 62]]], 0, 0]], [], 2, 3, ["loc", [null, [34, 20], [41, 27]]]], ["block", "if", [["subexpr", "and", [["subexpr", "compare-operation", [["get", "item.percentChangeYoY", ["loc", [null, [42, 50], [42, 71]]], 0, 0, 0, 0], "typeof", "number"], [], ["loc", [null, [42, 31], [42, 90]]], 0, 0], ["subexpr", "compare-operation", [["get", "item.percentChangeMoM", ["loc", [null, [42, 110], [42, 131]]], 0, 0, 0, 0], "typeof", "number"], [], ["loc", [null, [42, 91], [42, 150]]], 0, 0]], [], ["loc", [null, [42, 26], [42, 151]]], 0, 0]], [], 4, null, ["loc", [null, [42, 20], [56, 27]]]]],
        locals: ["item"],
        templates: [child0, child1, child2, child3, child4]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 78,
                  "column": 40
                },
                "end": {
                  "line": 78,
                  "column": 145
                }
              },
              "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["content", "item.title", ["loc", [null, [78, 131], [78, 145]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 80,
                  "column": 36
                },
                "end": {
                  "line": 82,
                  "column": 36
                }
              },
              "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col-md-5 col-sm-4 col-xs-4 text-right redtext");
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]), 0, 0);
              return morphs;
            },
            statements: [["inline", "format-money", [["get", "item.value", ["loc", [null, [81, 118], [81, 128]]], 0, 0, 0, 0]], [], ["loc", [null, [81, 103], [81, 130]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 82,
                  "column": 36
                },
                "end": {
                  "line": 84,
                  "column": 36
                }
              },
              "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col-md-5 col-sm-4 col-xs-4 text-right");
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]), 0, 0);
              return morphs;
            },
            statements: [["inline", "format-money", [["get", "item.value", ["loc", [null, [83, 110], [83, 120]]], 0, 0, 0, 0]], [], ["loc", [null, [83, 95], [83, 122]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 75,
                "column": 28
              },
              "end": {
                "line": 86,
                "column": 28
              }
            },
            "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n                                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-md-7 col-sm-8 col-xs-8");
            var el3 = dom.createTextNode("\n                                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
            morphs[1] = dom.createMorphAt(element0, 3, 3);
            return morphs;
          },
          statements: [["block", "link-to", [["get", "item.link", ["loc", [null, [78, 51], [78, 60]]], 0, 0, 0, 0], ["subexpr", "query-params", [], ["date", ["get", "date", ["loc", [null, [78, 80], [78, 84]]], 0, 0, 0, 0], "tabId", ["get", "item.params.tab", ["loc", [null, [78, 91], [78, 106]]], 0, 0, 0, 0], "type", ["get", "item.params.type", ["loc", [null, [78, 112], [78, 128]]], 0, 0, 0, 0]], ["loc", [null, [78, 61], [78, 129]]], 0, 0]], [], 0, null, ["loc", [null, [78, 40], [78, 157]]]], ["block", "if", [["subexpr", "compare-operation", [["get", "item.value", ["loc", [null, [80, 61], [80, 71]]], 0, 0, 0, 0], "<", 0], [], ["loc", [null, [80, 42], [80, 78]]], 0, 0]], [], 1, 2, ["loc", [null, [80, 36], [84, 43]]]]],
          locals: ["item"],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 59,
              "column": 12
            },
            "end": {
              "line": 90,
              "column": 12
            }
          },
          "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "subSection");
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "panel-heading row");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "col-lg-8 col-md-7 col-sm-8 col-xs-8");
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h3");
          dom.setAttribute(el4, "class", "panel-title");
          var el5 = dom.createTextNode("\n                              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("a");
          dom.setAttribute(el5, "class", "accordion-toggle collapsed");
          dom.setAttribute(el5, "data-toggle", "collapse");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                              ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "col-lg-4 col-md-5 col-sm-4 col-xs-4 text-right");
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "panel-collapse collapse row");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "panel-body");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element2, [1, 1, 1]);
          var element4 = dom.childAt(element1, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element3, 'href');
          morphs[1] = dom.createAttrMorph(element3, 'onclick');
          morphs[2] = dom.createMorphAt(element3, 1, 1);
          morphs[3] = dom.createMorphAt(dom.childAt(element2, [3, 1]), 0, 0);
          morphs[4] = dom.createAttrMorph(element4, 'id');
          morphs[5] = dom.createMorphAt(dom.childAt(element4, [1]), 1, 1);
          return morphs;
        },
        statements: [["attribute", "href", ["concat", ["#collapse-", ["get", "data.subSection.id", ["loc", [null, [64, 109], [64, 127]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["click"], [], ["loc", [null, [null, null], [64, 157]]], 0, 0], 0, 0, 0, 0], ["content", "data.subSection.title", ["loc", [null, [65, 32], [65, 57]]], 0, 0, 0, 0], ["inline", "format-money", [["get", "data.subSection.value", ["loc", [null, [70, 49], [70, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [70, 34], [70, 72]]], 0, 0], ["attribute", "id", ["concat", ["collapse-", ["get", "data.subSection.id", ["loc", [null, [73, 40], [73, 58]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "data.subSection.mainData", ["loc", [null, [75, 36], [75, 60]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [75, 28], [86, 37]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 93,
            "column": 6
          }
        },
        "moduleName": "genesis-residual-system/templates/components/sidebar-tile.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "panel panel-default container-fluid");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "panel-heading row");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-lg-7 col-md-7 col-sm-8 col-xs-8");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4, "class", "panel-title");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "class", "accordion-toggle collapsed");
        dom.setAttribute(el5, "data-toggle", "collapse");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-lg-5 col-md-5 col-sm-4 col-xs-4 text-right");
        var el4 = dom.createElement("span");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "panel-collapse collapse row");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "panel-body");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [0]);
        var element10 = dom.childAt(element9, [1]);
        var element11 = dom.childAt(element10, [1, 1, 1]);
        var element12 = dom.childAt(element9, [3]);
        var element13 = dom.childAt(element12, [1]);
        var morphs = new Array(8);
        morphs[0] = dom.createAttrMorph(element11, 'href');
        morphs[1] = dom.createAttrMorph(element11, 'onclick');
        morphs[2] = dom.createMorphAt(element11, 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element10, [3, 0]), 0, 0);
        morphs[4] = dom.createAttrMorph(element12, 'id');
        morphs[5] = dom.createMorphAt(element13, 1, 1);
        morphs[6] = dom.createMorphAt(element13, 2, 2);
        morphs[7] = dom.createMorphAt(element13, 3, 3);
        return morphs;
      },
      statements: [["attribute", "href", ["concat", ["#collapse-", ["get", "data.id", ["loc", [null, [5, 95], [5, 102]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["click"], [], ["loc", [null, [null, null], [5, 132]]], 0, 0], 0, 0, 0, 0], ["content", "data.title", ["loc", [null, [6, 20], [6, 34]]], 0, 0, 0, 0], ["inline", "format-money", [["get", "data.value", ["loc", [null, [10, 89], [10, 99]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 74], [10, 101]]], 0, 0], ["attribute", "id", ["concat", ["collapse-", ["get", "data.id", ["loc", [null, [12, 24], [12, 31]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "data.subTitle", ["loc", [null, [14, 18], [14, 31]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [14, 12], [20, 19]]]], ["block", "each", [["get", "data.mainData", ["loc", [null, [21, 20], [21, 33]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [21, 12], [58, 21]]]], ["block", "if", [["get", "data.subSection", ["loc", [null, [59, 18], [59, 33]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [59, 12], [90, 19]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});