define('genesis-residual-system/components/popover-info', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		tagName: 'a',
		makeContainerClickableForIOS: function makeContainerClickableForIOS() {
			// Check if it is iOS
			var isiOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false;

			if (isiOS === true) {

				// Store -webkit-tap-highlight-color as this gets set to rgba(0, 0, 0, 0) in the next part of the code
				var tempCSS = _ember['default'].$('a').css('-webkit-tap-highlight-color');

				_ember['default'].$('body').css('cursor', 'pointer') // Make iOS honour the click event on body
				.css('-webkit-tap-highlight-color', 'rgba(0, 0, 0, 0)'); // Stops content flashing when body is clicked

				// Re-apply cached CSS
				_ember['default'].$('a').css('-webkit-tap-highlight-color', tempCSS);
			}
		},
		setupBootstrapPopover: (function () {
			this.$('[data-toggle="popover"]').popover({
				content: this.get('data.content'),
				placement: this.get('data.placement'),
				html: true
			});

			this.makeContainerClickableForIOS();
		}).on('didInsertElement')
	});
});