define('genesis-residual-system/serializers/residual-month', ['exports', 'genesis-residual-system/serializers/application'], function (exports, _genesisResidualSystemSerializersApplication) {
	exports['default'] = _genesisResidualSystemSerializersApplication['default'].extend({
		normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
			payload = {
				'residual-months': { id: 0, list: payload.months }
			};

			return this._super(store, primaryModelClass, payload, id, requestType);
		}
	});
});