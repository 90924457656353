define('genesis-residual-system/mixins/export-data/export-data-mixin', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
	function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

	exports['default'] = _ember['default'].Mixin.create({
		addEmptyCell: function addEmptyCell() {
			return { value: '', border: true };
		},
		addTitleCell: function addTitleCell(value) {
			return { value: value, bold: true };
		},
		addTableHeaderCell: function addTableHeaderCell(value) {
			return { value: value, bold: true, border: true };
		},
		addTableCell: function addTableCell(value) {
			var formatMoney = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];
			var formatPercent = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

			return { value: value, border: true, formatMoney: formatMoney, formatPercent: formatPercent };
		},
		addHeaderRow: function addHeaderRow(data) {
			var row = [];
			var _iteratorNormalCompletion = true;
			var _didIteratorError = false;
			var _iteratorError = undefined;

			try {
				for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
					var item = _step.value;

					row.push(this.addTableHeaderCell(item));
				}
			} catch (err) {
				_didIteratorError = true;
				_iteratorError = err;
			} finally {
				try {
					if (!_iteratorNormalCompletion && _iterator['return']) {
						_iterator['return']();
					}
				} finally {
					if (_didIteratorError) {
						throw _iteratorError;
					}
				}
			}

			return row;
		},
		addRow: function addRow(data) {
			var row = [];
			var _iteratorNormalCompletion2 = true;
			var _didIteratorError2 = false;
			var _iteratorError2 = undefined;

			try {
				for (var _iterator2 = data[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
					var item = _step2.value;

					row.push(this.addTableCell(item.value, item.formatMoney, item.formatPercent));
				}
			} catch (err) {
				_didIteratorError2 = true;
				_iteratorError2 = err;
			} finally {
				try {
					if (!_iteratorNormalCompletion2 && _iterator2['return']) {
						_iterator2['return']();
					}
				} finally {
					if (_didIteratorError2) {
						throw _iteratorError2;
					}
				}
			}

			return row;
		},
		addTable: function addTable(data, attributes) {
			var list = [];

			list.push(this.addHeaderRow(attributes.map(function (item) {
				return item.name;
			})));

			var _iteratorNormalCompletion3 = true;
			var _didIteratorError3 = false;
			var _iteratorError3 = undefined;

			try {
				for (var _iterator3 = data[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
					var item = _step3.value;

					var row = [];

					var _iteratorNormalCompletion4 = true;
					var _didIteratorError4 = false;
					var _iteratorError4 = undefined;

					try {
						for (var _iterator4 = attributes[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
							var attribute = _step4.value;

							if (attribute.condition) {
								var conditionTrue = item.hasOwnProperty(attribute.conditionTrue) ? item[attribute.conditionTrue] : attribute.conditionTrue;
								var conditionFalse = item.hasOwnProperty(attribute.conditionFalse) ? item[attribute.conditionFalse] : attribute.conditionFalse;

								row.push({
									value: item[attribute.condition] ? conditionTrue : conditionFalse,
									formatMoney: attribute.formatMoney,
									formatPercent: attribute.formatPercent
								});
							} else {
								row.push({
									value: attribute.dateFormat ? (0, _moment['default'])(item[attribute.key]).format(attribute.dateFormat) : item[attribute.key],
									formatMoney: attribute.formatMoney,
									formatPercent: attribute.formatPercent
								});
							}
						}
					} catch (err) {
						_didIteratorError4 = true;
						_iteratorError4 = err;
					} finally {
						try {
							if (!_iteratorNormalCompletion4 && _iterator4['return']) {
								_iterator4['return']();
							}
						} finally {
							if (_didIteratorError4) {
								throw _iteratorError4;
							}
						}
					}

					list.push(this.addRow(row));
				}
			} catch (err) {
				_didIteratorError3 = true;
				_iteratorError3 = err;
			} finally {
				try {
					if (!_iteratorNormalCompletion3 && _iterator3['return']) {
						_iterator3['return']();
					}
				} finally {
					if (_didIteratorError3) {
						throw _iteratorError3;
					}
				}
			}

			return list;
		},
		addVolumeByCardTypeTable: function addVolumeByCardTypeTable(data, params) {
			var currentDate = (0, _moment['default'])(params.accountingPeriod, 'YYYY-MM').format('MMMM YYYY');

			var attributes = [{ key: 'cardType', name: 'Card Type' }, { key: 'volume', name: currentDate, formatMoney: true }, { key: 'monthOverMonthPercentChange', name: 'MoM Change', formatPercent: true }, { key: 'yearOverYearPercentChange', name: 'YoY Change', formatPercent: true }];

			return [[this.addTitleCell('Volume by Card Type')]].concat(_toConsumableArray(this.addTable(data, attributes)));
		}
	});
});