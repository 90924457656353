define('genesis-residual-system/routes/dashboards/residuals', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'genesis-residual-system/mixins/session-modal-mixin', 'genesis-residual-system/mixins/routes/dashboards/residuals-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _genesisResidualSystemMixinsSessionModalMixin, _genesisResidualSystemMixinsRoutesDashboardsResidualsMixin) {
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _genesisResidualSystemMixinsSessionModalMixin['default'], _genesisResidualSystemMixinsRoutesDashboardsResidualsMixin['default'], {
		activate: function activate() {
			document.title = 'Dashboard - Residuals';
			window.scrollTo(0, 0);
		},
		model: function model(params, transition) {
			var _this = this;

			_ember['default'].$('.loading').fadeIn();

			return this.store.queryRecord('residual-month', {}).then(function (residualMonth) {
				var months = residualMonth.data.list;

				if (months.length === 0) {
					_this.noResiduals = true;
					return {};
				}

				var filterParams = _this.getFilterParams(months, transition);

				return _ember['default'].RSVP.hash({
					officeHeader: _this.store.queryRecord('office-header', filterParams.officeHeaderFilterParams),
					officeInfo: _this.store.queryRecord('office-info', filterParams.officeInfoFilterParams)
				}).then(function (response) {
					var model = _this.modelFor('application');

					if (model) {
						_ember['default'].set(model, 'branding', response.officeHeader.data.brand);
					}

					return _this.buildModel(response.officeHeader.data, response.officeInfo, months, filterParams);
				});
			})['catch'](function (resp) {
				if (resp.errors && resp.errors.length > 0 && resp.errors[0].status === '500') {
					_this.send('logout', true);
				}
			});
		},
		afterModel: function afterModel(model, transition) {
			var _this2 = this;

			this._super.apply(this, arguments);
			transition.then(function () {
				_this2.initModal();
			});
		},
		actions: {
			updateSidebar: function updateSidebar(officeHeader, officeInfo, residualMonths, params) {
				var model = this.buildModel(officeHeader, officeInfo, residualMonths, params);
				this.updateModel(model);
			},
			updateSecondaryNavbar: function updateSecondaryNavbar(filter) {
				var secondaryNavbar = this.getSecondaryNavbarConfig(this.currentModel.officeInfo, filter);
				this.set('currentModel.secondaryNavbar', secondaryNavbar);
			},
			updateExportDataConfig: function updateExportDataConfig(data) {
				this.set('currentModel.exportData', data);
			},
			setDate: function setDate(date) {
				var _this3 = this;

				_ember['default'].$('.loading').fadeIn();
				var params = this.getResidualsFilterParams(date),
				    requests = this.getResidualsRequests(this.store, params);

				_ember['default'].RSVP.hash(requests).then(function (response) {
					var model = _this3.buildModel(response.officeHeader.data, response.officeInfo, _this3.currentModel.residualMonths, params);
					_this3.updateModel(model);
					_ember['default'].$('.loading').fadeOut();
				});
			},
			showModal: function showModal(name, model) {
				this.render(name, {
					into: 'dashboards.residuals',
					outlet: 'modal',
					model: model
				});
			},
			removeModal: function removeModal() {
				this.disconnectOutlet({
					outlet: 'modal',
					parentView: 'dashboards.residuals'
				});
				_ember['default'].$('.loading').fadeOut();
			},
			initTimer: function initTimer() {
				this.initTimer(new Date().getTime());
			},
			extendSession: function extendSession() {
				this.send('removeModal');
				this.send('initSessionModal');
			},
			initSessionModal: function initSessionModal() {
				this.initModal();
			},
			didTransition: function didTransition() {
				if (this.noResiduals) {
					this.send('logout', true, 'There is no compensation data available to view. If you believe this is an error, please contact your Relationship Manager.');
				}
				return true; // Bubble the didTransition event
			}
		}
	});
});