define('genesis-residual-system/mixins/resize-sidebar-mixin', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Mixin.create({
		screen: _ember['default'].inject.service(),
		setSidebarHeight: function setSidebarHeight() {
			if (this.get('screen.isDesktop')) {
				_ember['default'].$('.sidebar').removeAttr('style');

				var containerHeight = _ember['default'].$(document).outerHeight(),
				    navbarHeight = _ember['default'].$('#mainNavbarWrapper').outerHeight(),
				    secondaryNavbarHeight = _ember['default'].$('#secondNavbarWrapper').outerHeight(),
				    footerHeight = _ember['default'].$('footer').outerHeight(),
				    height = containerHeight - navbarHeight - secondaryNavbarHeight - footerHeight,
				    sidebarHeight = _ember['default'].$('.sidebar').outerHeight();

				if (sidebarHeight <= height) {
					_ember['default'].$('.sidebar').css('min-height', height);
				}
			} else {
				_ember['default'].$('.sidebar').removeAttr('style');
			}
		}
	});
});