define('genesis-residual-system/mixins/intro-js/intro-js-mixin', ['exports', 'ember', 'ember-local-storage'], function (exports, _ember, _emberLocalStorage) {
	exports['default'] = _ember['default'].Mixin.create({
		tutorials: (0, _emberLocalStorage.storageFor)('tutorials'),
		tutorialModel: 'modelName',
		getIntroJSConfig: function getIntroJSConfig() {
			return {
				steps: [],
				startTutorial: false
			};
		},
		setTutorialModelStatus: function setTutorialModelStatus(passed) {
			this.set('tutorials.' + this.get('tutorialModel'), passed);
		},
		getTutorialModelStatus: function getTutorialModelStatus() {
			return this.get('tutorials.' + this.get('tutorialModel'));
		},
		actions: {
			startTutorial: function startTutorial(restart) {
				var needToStart = restart || !this.getTutorialModelStatus();
				this.setTutorialModelStatus(!needToStart);
				this.set('currentModel.introJS.steps', this.get('steps'));
				this.set('currentModel.introJS.startTutorial', needToStart);
			},
			introOnComplete: function introOnComplete() {
				this.setTutorialModelStatus(true);
				this.set('currentModel.introJS.startTutorial', false);
			}
		}
	});
});