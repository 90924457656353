define('genesis-residual-system/mixins/intro-js/merchants-intro-js-mixin', ['exports', 'ember', 'genesis-residual-system/mixins/intro-js/intro-js-mixin'], function (exports, _ember, _genesisResidualSystemMixinsIntroJsIntroJsMixin) {
	exports['default'] = _ember['default'].Mixin.create(_genesisResidualSystemMixinsIntroJsIntroJsMixin['default'], {
		tutorialModel: _ember['default'].computed('tabId', function () {
			switch (this.get('tabId')) {
				case 'merchant-portfolio':
					return 'merchantPortfolio';
				case 'misc-adjustments':
					return 'miscAdjustments';
				case 'bonuses':
					return 'bonuses';
				default:
					throw new Error('there is no implementation for this tutorial status: ' + this.get('tabId'));
			}
		}),
		steps: _ember['default'].computed('tabId', function () {
			switch (this.get('tabId')) {
				case 'merchant-portfolio':
					return this.getMerchantPortfolioTutorialSteps();
				case 'misc-adjustments':
					return this.getMiscAdjustmentsTutorialSteps();
				case 'bonuses':
					return this.getBonusesTutorialSteps();
				default:
					return [];
			}
		}),
		getMerchantPortfolioTutorialSteps: function getMerchantPortfolioTutorialSteps() {
			return _ember['default'].computed(function () {
				return [{
					intro: 'This page breaks down your portfolio by merchant.'
				}, {
					element: _ember['default'].$('.filterHeader:visible')[0],
					intro: 'You are able to select individual sales reps from the drop down to only view their portfolio. Lastly you can use the search bard to search for a merchant by MID or Name, or for a Salesrep id.',
					scrollTo: 'tooltip'
				}, {
					element: _ember['default'].$('.table-responsive .dataTable:visible')[0],
					intro: 'You can also sort your portfolio by Merchant Name, Payout Amount, Volume, Sales, Refunds, Salesrep ID or Split by clicking the appropriate column header.',
					scrollTo: 'tooltip'
				}];
			});
		},
		getMiscAdjustmentsTutorialSteps: function getMiscAdjustmentsTutorialSteps() {
			return _ember['default'].computed(function () {
				return [{
					intro: 'This page details your Miscellaneous Adjustments.'
				}, {
					element: _ember['default'].$('.filterHeader:visible')[0],
					intro: 'You are able to select individual Misc Adjustments categories using the dropdown. You are also able to search for a misc adjustment using the search bar.',
					scrollTo: 'tooltip'
				}, {
					element: _ember['default'].$('.table-responsive .dataTable:visible')[0],
					intro: 'You can also sort your Misc by description, Type, or Amount, by clicking the appropriate column header.',
					scrollTo: 'tooltip'
				}];
			});
		},
		getBonusesTutorialSteps: function getBonusesTutorialSteps() {
			return _ember['default'].computed(function () {
				return [{
					intro: 'This page breaks details the Daily Bonuses earned over the month.'
				}, {
					element: _ember['default'].$('.filterHeader:visible')[0],
					intro: 'You are able to select individual bonus types using the dropdown. You are also able to search for a bonus using the search bar.',
					scrollTo: 'tooltip'
				}, {
					element: _ember['default'].$('.table-responsive .dataTable:visible')[0],
					intro: 'You can also sort your Daily Bonuses by: MID, Merchant Name, Bonus Type, Payout Amount, Payout Date, and Salesrep ID, by clicking the appropriate column header.',
					scrollTo: 'tooltip'
				}];
			});
		}
	});
});