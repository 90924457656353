define('genesis-residual-system/app', ['exports', 'ember', 'genesis-residual-system/resolver', 'ember-load-initializers', 'genesis-residual-system/config/environment'], function (exports, _ember, _genesisResidualSystemResolver, _emberLoadInitializers, _genesisResidualSystemConfigEnvironment) {

	var App = undefined;

	_ember['default'].MODEL_FACTORY_INJECTIONS = true;

	App = _ember['default'].Application.extend({
		modulePrefix: _genesisResidualSystemConfigEnvironment['default'].modulePrefix,
		podModulePrefix: _genesisResidualSystemConfigEnvironment['default'].podModulePrefix,
		Resolver: _genesisResidualSystemResolver['default']
	});

	(0, _emberLoadInitializers['default'])(App, _genesisResidualSystemConfigEnvironment['default'].modulePrefix);

	exports['default'] = App;

	_ember['default'].LinkComponent.reopen({
		attributeBindings: ['data-toggle', 'data-placement', 'data-target']
	});
});