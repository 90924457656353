define('genesis-residual-system/mirage/fixtures/merchant-processing', ['exports'], function (exports) {
	exports['default'] = {
		'authInfo': [{
			'authCount': 865,
			'billedToIso': 23.7875,
			'billedToMerchant': 410.88,
			'cardType': 'VISA',
			'isoRate': 0.0275,
			'income': 387.0925,
			'merchantRate': 0.475,
			'vendor': 'TSYS'
		}, {
			'authCount': 443,
			'billedToIso': 12.1825,
			'billedToMerchant': 210.43,
			'cardType': 'MASTERCARD',
			'isoRate': 0.0275,
			'income': 198.2475,
			'merchantRate': 0.475,
			'vendor': 'TSYS'
		}, {
			'authCount': 17,
			'billedToIso': 0.4675,
			'billedToMerchant': 8.08,
			'cardType': 'DISCOVER',
			'isoRate': 0.0275,
			'income': 7.6125,
			'merchantRate': 0.475,
			'vendor': 'TSYS'
		}, {
			'authCount': 290,
			'billedToIso': 18.8500,
			'BilledToMerchant': 108.75,
			'cardType': 'AMERICAN EXPRESS',
			'isoRate': 0.065,
			'income': 89.9,
			'merchantRate': 0.375,
			'vendor': 'TSYS'
		}],
		'fixedExpenses': [{
			'billedToIso': 11.2560,
			'cost': 0.007,
			'description': 'Risk Management Passthrough Fee',
			'income': 0.0000,
			'netIncomeExpense': -11.2560,
			'unitCount': 1
		}, {
			'billedToIso': 0.0000,
			'cost': 0,
			'description': 'MC Per Location Fee Share',
			'income': 0.5000,
			'netIncomeExpense': 0.5000,
			'unitCount': 1
		}, {
			'billedToIso': 0.7500,
			'cost': 0.75,
			'description': 'Merchant Regulatory Compliance',
			'income': 0.0000,
			'netIncomeExpense': -0.7500,
			'unitCount': 1
		}],
		'miscInfo': [{
			'billedToIso': 58.45,
			'billedToMerchant': 58.45,
			'description': 'AM Non-swiped transaction Fee',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 55
		}, {
			'billedToIso': 78.72,
			'billedToMerchant': 0,
			'description': 'AMEX BIN cost',
			'expenseRate': 0.0025,
			'income': -78.72,
			'itemCount': 1
		}, {
			'billedToIso': 0,
			'billedToMerchant': 0,
			'description': 'Amex Network Fee',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 1
		}, {
			'billedToIso': 47.3,
			'billedToMerchant': 0,
			'description': 'Amex Network Fee',
			'expenseRate': 0,
			'income': -47.3,
			'itemCount': 1
		}, {
			'billedToIso': 31.77,
			'billedToMerchant': 0,
			'description': 'BIN Sponsorship',
			'expenseRate': 0.0004,
			'income': -31.77,
			'itemCount': 1
		}, {
			'billedToIso': 0,
			'billedToMerchant': 6.5,
			'description': 'Capture Fee (Automated)',
			'expenseRate': 0,
			'income': 6.5,
			'itemCount': 1634
		}, {
			'billedToIso': 0.33,
			'billedToMerchant': 0.33,
			'description': 'Discover Data Usage',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 17
		}, {
			'billedToIso': 0,
			'billedToMerchant': 0,
			'description': 'Discover KiloByte Fee',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 0
		}, {
			'billedToIso': 1.75,
			'billedToMerchant': 0,
			'description': 'Interchange Dues & Assessments DISC',
			'expenseRate': 0,
			'income': -1.75,
			'itemCount': 1
		}, {
			'billedToIso': 23.95,
			'billedToMerchant': 0,
			'description': 'Interchange Dues & Assessments MC',
			'expenseRate': 0,
			'income': -23.95,
			'itemCount': 1
		}, {
			'billedToIso': 76.02,
			'billedToMerchant': 0,
			'description': 'Interchange Dues & Assessments VISA',
			'expenseRate': 0,
			'income': -76.02,
			'itemCount': 1
		}, {
			'billedToIso': 0.15,
			'billedToMerchant': 0.15,
			'description': 'International Acquirer Fee',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 2
		}, {
			'billedToIso': 0.28,
			'billedToMerchant': 0.28,
			'description': 'International Service - Sales',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 2
		}, {
			'billedToIso': 8.56,
			'billedToMerchant': 8.56,
			'description': 'Mastercard Brand Usage Fee',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 439
		}, {
			'billedToIso': 2.2,
			'billedToMerchant': 2.2,
			'description': 'MC Acquirers License Fee 1',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 1
		}, {
			'billedToIso': 0,
			'billedToMerchant': 0,
			'description': 'MC Assessments Intl',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 0
		}, {
			'billedToIso': 0.35,
			'billedToMerchant': 0.35,
			'description': 'MC Cross Border US',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 1
		}, {
			'billedToIso': 0.03,
			'billedToMerchant': 0.03,
			'description': 'MC CVC2 Fee',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 13
		}, {
			'billedToIso': 0,
			'billedToMerchant': 0,
			'description': 'MC KB Tran Fee',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 0
		}, {
			'billedToIso': 0,
			'billedToMerchant': 0,
			'description': 'MC KiloByte Fee',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 0
		}, {
			'billedToIso': 2.5,
			'billedToMerchant': 2.5,
			'description': 'MC Per Location Fee',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 1
		}, {
			'billedToIso': 0,
			'billedToMerchant': 0,
			'description': 'MC Processing Integrity Fee',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 0
		}, {
			'billedToIso': 0,
			'billedToMerchant': 0,
			'description': 'Monthly Minimum',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 1
		}, {
			'billedToIso': 5.75,
			'billedToMerchant': 30,
			'description': 'Monthly Service Fee',
			'expenseRate': 0,
			'income': 24.25,
			'itemCount': 1
		}, {
			'billedToIso': 5.46,
			'billedToMerchant': 5.46,
			'description': 'VISA Acquirer Authorization Fee',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 352
		}, {
			'billedToIso': 10,
			'billedToMerchant': 10,
			'description': 'Visa Acquirer Processing FeeMDEX',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 513
		}, {
			'billedToIso': 0,
			'billedToMerchant': 0,
			'description': 'VISA BASE II TRAN FEE',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 0
		}, {
			'billedToIso': 1.55,
			'billedToMerchant': 1.55,
			'description': 'VISA BASE II TRANSMISSION FEE',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 860
		}, {
			'billedToIso': 2,
			'billedToMerchant': 2,
			'description': 'Visa FAN Fee',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 1
		}, {
			'billedToIso': 0,
			'billedToMerchant': 0,
			'description': 'Visa KiloByte Fee',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 0
		}, {
			'billedToIso': 0.4,
			'billedToMerchant': 0.4,
			'description': 'Visa Transaction Integrity',
			'expenseRate': 0,
			'income': 0,
			'itemCount': 4
		}],
		'monetaryInfo': [{
			'averageTicket': 44.7900,
			'cardType': 'VISA',
			'description': 'Regulated EIRF - CK',
			'discountCharged': 6.69,
			'discountRate': '4.215% + $0.1',
			'icAmount': 0.73,
			'icRate': '0.05% + $0.22',
			'income': 5.96,
			'transactionCount': 3,
			'volume': 134.38
		}, {
			'averageTicket': 52.2700,
			'cardType': 'VISA',
			'description': 'Non Reg CPS Restaurant - CK',
			'discountCharged': 5.21,
			'discountRate': '1.89% + $0',
			'icAmount': 2.25,
			'icRate': '1.15% + $0.15',
			'income': 2.96,
			'transactionCount': 3,
			'volume': 156.82
		}, {
			'averageTicket': 35.1400,
			'cardType': 'VISA',
			'description': 'Regulated Business Card - Card Present Debit',
			'discountCharged': 29.11,
			'discountRate': '4.215% + $0.1',
			'icAmount': 3.81,
			'icRate': '0.05% + $0.22',
			'income': 25.3,
			'transactionCount': 16,
			'volume': 562.27
		}, {
			'averageTicket': 47.3500,
			'cardType': 'VISA',
			'description': 'EIRF',
			'discountCharged': 354.77,
			'discountRate': '4.215% + $0.1',
			'icAmount': 128.42,
			'icRate': '2.3% + $0.10',
			'income': 226.35,
			'transactionCount': 108,
			'volume': 5113.87
		}, {
			'averageTicket': 42.1200,
			'cardType': 'VISA',
			'description': 'Business Enhanced - Electronic',
			'discountCharged': 38.21,
			'discountRate': '4.215% + $0.1',
			'icAmount': 15.71,
			'icRate': '2.75% + $0.15',
			'income': 22.5,
			'transactionCount': 12,
			'volume': 505.39
		}, {
			'averageTicket': 36.8000,
			'cardType': 'VISA',
			'description': 'CPS Restaurant',
			'discountCharged': 29.96,
			'discountRate': '1.89% + $0',
			'icAmount': 14.66,
			'icRate': '1.54% + $0.10',
			'income': 15.3,
			'transactionCount': 22,
			'volume': 809.62
		}, {
			'averageTicket': 9.9300,
			'cardType': 'VISA',
			'description': 'CPS Small Ticket - CK',
			'discountCharged': 7.65,
			'discountRate': '1.89% + $0',
			'icAmount': 3.9,
			'icRate': '1.55% + $0.04',
			'income': 3.75,
			'transactionCount': 20,
			'volume': 198.56
		}, {
			'averageTicket': 41.7600,
			'cardType': 'VISA',
			'description': 'Regulated CPS Restaurant - CK',
			'discountCharged': 192.65,
			'discountRate': '1.79% + $0',
			'icAmount': 46.9,
			'icRate': '0.05% + $0.22',
			'income': 145.75,
			'transactionCount': 195,
			'volume': 8142.62
		}, {
			'averageTicket': 91.7100,
			'cardType': 'VISA',
			'description': 'Standard',
			'discountCharged': 13.08,
			'discountRate': '4.215% + $0.1',
			'icAmount': 5.15,
			'icRate': '2.7% + $0.10',
			'income': 7.93,
			'transactionCount': 2,
			'volume': 183.42
		}, {
			'averageTicket': 45.2200,
			'cardType': 'VISA',
			'description': 'Business Card Electronic',
			'discountCharged': 9.57,
			'discountRate': '4.215% + $0.1',
			'icAmount': 3.55,
			'icRate': '2.4% + $0.10',
			'income': 6.02,
			'transactionCount': 3,
			'volume': 135.65
		}, {
			'averageTicket': 25.9000,
			'cardType': 'VISA',
			'description': 'CPS Restaurant - CK',
			'discountCharged': 13.47,
			'discountRate': '1.89% + $0',
			'icAmount': 6.13,
			'icRate': '1.19% + $0.10',
			'income': 7.34,
			'transactionCount': 15,
			'volume': 388.55
		}, {
			'averageTicket': 40.6700,
			'cardType': 'VISA',
			'description': 'Signature Rewards 2',
			'discountCharged': 64.59,
			'discountRate': '4.175% + $0.1',
			'icAmount': 21.44,
			'icRate': '1.95% + $0.10',
			'income': 43.15,
			'transactionCount': 24,
			'volume': 976
		}, {
			'averageTicket': 39.7500,
			'cardType': 'VISA',
			'description': 'Visa Infinite Electronic',
			'discountCharged': 19.8,
			'discountRate': '4.215% + $0.1',
			'icAmount': 7.37,
			'icRate': '2.4% + $0.10',
			'income': 12.43,
			'transactionCount': 7,
			'volume': 278.23
		}, {
			'averageTicket': 84.6900,
			'cardType': 'VISA',
			'description': 'Standard - Sig. Pref.',
			'discountCharged': 31.34,
			'discountRate': '4.215% + $0.1',
			'icAmount': 12.99,
			'icRate': '2.95% + $0.10',
			'income': 18.35,
			'transactionCount': 5,
			'volume': 423.47
		}, {
			'averageTicket': 1240.8900,
			'cardType': 'VISA',
			'description': 'Purchasing Card - Travel Service',
			'discountCharged': 1430.48,
			'discountRate': '4.215% + $0.1',
			'icAmount': 539.62,
			'icRate': '2.45% + $0.10',
			'income': 890.86,
			'transactionCount': 17,
			'volume': 21095.12
		}, {
			'averageTicket': 768.0300,
			'cardType': 'VISA',
			'description': 'Business Elect T&E T4',
			'discountCharged': 221.32,
			'discountRate': '4.215% + $0.1',
			'icAmount': 91.43,
			'icRate': '2.95% + $0.20',
			'income': 129.89,
			'transactionCount': 4,
			'volume': 3072.12
		}, {
			'averageTicket': 10.1900,
			'cardType': 'VISA',
			'description': 'Regulated CPS Small Ticket - CK',
			'discountCharged': 37.89,
			'discountRate': '1.79% + $0',
			'icAmount': 20.93,
			'icRate': '0.05% + $0.22',
			'income': 16.96,
			'transactionCount': 93,
			'volume': 947.68
		}, {
			'averageTicket': 11.6900,
			'cardType': 'VISA',
			'description': 'Non Reg CPS Small Ticket - CK',
			'discountCharged': 0.83,
			'discountRate': '4.175% + $0.1',
			'icAmount': 0.24,
			'icRate': '1.6% + $0.05',
			'income': 0.59,
			'transactionCount': 1,
			'volume': 11.69
		}, {
			'averageTicket': 15.8200,
			'cardType': 'VISA',
			'description': 'Commercial Card Retail - Prepaid',
			'discountCharged': 1.21,
			'discountRate': '4.215% + $0.1',
			'icAmount': 0.44,
			'icRate': '2.15% + $0.10',
			'income': 0.77,
			'transactionCount': 1,
			'volume': 15.82
		}, {
			'averageTicket': 11.0000,
			'cardType': 'VISA',
			'description': 'CPS Small Ticket',
			'discountCharged': 20.31,
			'discountRate': '4.175% + $0.1',
			'icAmount': 5.77,
			'icRate': '1.65% + $0.04',
			'income': 14.54,
			'transactionCount': 26,
			'volume': 286.01
		}, {
			'averageTicket': 17.1900,
			'cardType': 'VISA',
			'description': 'Interregional Issuer Chip',
			'discountCharged': 1.06,
			'discountRate': '1.89% + $0',
			'icAmount': 0.41,
			'icRate': '1.2% + $0.00',
			'income': 0.65,
			'transactionCount': 2,
			'volume': 34.38
		}, {
			'averageTicket': 280.4500,
			'cardType': 'VISA',
			'description': 'Corporate Card - Travel Service',
			'discountCharged': 210.9,
			'discountRate': '4.215% + $0.1',
			'icAmount': 79.77,
			'icRate': '2.55% + $0.10',
			'income': 131.13,
			'transactionCount': 11,
			'volume': 3085
		}, {
			'averageTicket': 75.9700,
			'cardType': 'VISA',
			'description': 'EIRF - CK',
			'discountCharged': 4.8,
			'discountRate': '4.175% + $0.1',
			'icAmount': 1.53,
			'icRate': '1.75% + $0.20',
			'income': 3.27,
			'transactionCount': 1,
			'volume': 75.97
		}, {
			'averageTicket': 7.4800,
			'cardType': 'VISA',
			'description': 'Business Card - Card Present Debit',
			'discountCharged': 0.64,
			'discountRate': '4.175% + $0.1',
			'icAmount': 0.23,
			'icRate': '1.7% + $0.10',
			'income': 0.41,
			'transactionCount': 1,
			'volume': 7.48
		}, {
			'averageTicket': 44.7100,
			'cardType': 'VISA',
			'description': 'Electronic - Sig. Pref.',
			'discountCharged': 830.51,
			'discountRate': '4.215% + $0.1',
			'icAmount': 308.53,
			'icRate': '2.4% + $0.10',
			'income': 521.98,
			'transactionCount': 263,
			'volume': 11759.84
		}, {
			'averageTicket': 25.9500,
			'cardType': 'VISA',
			'description': 'Signature Business - Electronic',
			'discountCharged': 10.66,
			'discountRate': '4.215% + $0.1',
			'icAmount': 4.69,
			'icRate': '2.85% + $0.20',
			'income': 5.97,
			'transactionCount': 5,
			'volume': 129.75
		}, {
			'averageTicket': 39.0600,
			'cardType': 'MASTERCARD',
			'description': "Comm'l T&E I - Business",
			'discountCharged': 51.73,
			'discountRate': '4.215% + $0.1',
			'icAmount': 18.55,
			'icRate': '2.5% + $0.00',
			'income': 33.18,
			'transactionCount': 19,
			'volume': 742.18
		}, {
			'averageTicket': 73.3200,
			'cardType': 'MASTERCARD',
			'description': "Comm'l T&E I - Level 3/Business World Elite",
			'discountCharged': 25.89,
			'discountRate': '4.215% + $0.1',
			'icAmount': 9.94,
			'icRate': '2.71% + $0.00',
			'income': 15.95,
			'transactionCount': 5,
			'volume': 366.59
		}, {
			'averageTicket': 60.8700,
			'cardType': 'MASTERCARD',
			'description': 'Merit 3 - CK',
			'discountCharged': 48.82,
			'discountRate': '1.79% + $0',
			'icAmount': 20.49,
			'icRate': '1.05% + $0.15',
			'income': 28.33,
			'transactionCount': 26,
			'volume': 1582.55
		}, {
			'averageTicket': 23.6500,
			'cardType': 'MASTERCARD',
			'description': 'World Restaurant',
			'discountCharged': 95.81,
			'discountRate': '4.175% + $0.1',
			'icAmount': 30.56,
			'icRate': '1.73% + $0.10',
			'income': 65.25,
			'transactionCount': 60,
			'volume': 1419.2
		}, {
			'averageTicket': 11.5900,
			'cardType': 'MASTERCARD',
			'description': 'Small Ticket - CK',
			'discountCharged': 14.48,
			'discountRate': '1.89% + $0',
			'icAmount': 7.25,
			'icRate': '1.55% + $0.04',
			'income': 7.23,
			'transactionCount': 33,
			'volume': 382.54
		}, {
			'averageTicket': 44.4200,
			'cardType': 'MASTERCARD',
			'description': "Comm'l T&E I - Business",
			'discountCharged': 15.41,
			'discountRate': '4.215% + $0.1',
			'icAmount': 5.55,
			'icRate': '2.5% + $0.00',
			'income': 9.86,
			'transactionCount': 5,
			'volume': 222.11
		}, {
			'averageTicket': 41.2200,
			'cardType': 'MASTERCARD',
			'description': "LF Comm'l T&E I - Business",
			'discountCharged': 2.08,
			'discountRate': '4.215% + $0.1',
			'icAmount': 0.24,
			'icRate': '0.05% + $0.22',
			'income': 1.84,
			'transactionCount': 1,
			'volume': 41.22
		}, {
			'averageTicket': 25.7700,
			'cardType': 'MASTERCARD',
			'description': 'Restaurant - CK',
			'discountCharged': 31.25,
			'discountRate': '1.89% + $0',
			'icAmount': 14.2,
			'icRate': '1.19% + $0.10',
			'income': 17.05,
			'transactionCount': 35,
			'volume': 901.88
		}, {
			'averageTicket': 20.2800,
			'cardType': 'MASTERCARD',
			'description': 'World High Value Restaurant',
			'discountCharged': 15.01,
			'discountRate': '4.215% + $0.1',
			'icAmount': 5.46,
			'icRate': '2.2% + $0.10',
			'income': 9.55,
			'transactionCount': 10,
			'volume': 202.82
		}, {
			'averageTicket': 44.4900,
			'cardType': 'MASTERCARD',
			'description': 'Merit 3',
			'discountCharged': 27.95,
			'discountRate': '1.89% + $0',
			'icAmount': 13.65,
			'icRate': '1.58% + $0.10',
			'income': 14.3,
			'transactionCount': 17,
			'volume': 756.36
		}, {
			'averageTicket': 1023.3300,
			'cardType': 'MASTERCARD',
			'description': "Comm'l T&E I - Purchasing",
			'discountCharged': 212.59,
			'discountRate': '4.215% + $0.1',
			'icAmount': 82.89,
			'icRate': '2.65% + $0.00',
			'income': 129.7,
			'transactionCount': 3,
			'volume': 3070
		}, {
			'averageTicket': 24.1400,
			'cardType': 'MASTERCARD',
			'description': 'LF Restaurant - CK',
			'discountCharged': 11.96,
			'discountRate': '1.79% + $0',
			'icAmount': 4.18,
			'icRate': '0.05% + $0.22',
			'income': 7.78,
			'transactionCount': 18,
			'volume': 434.56
		}, {
			'averageTicket': 27.6900,
			'cardType': 'MASTERCARD',
			'description': 'World Elite Restaurant',
			'discountCharged': 189.7,
			'discountRate': '4.215% + $0.1',
			'icAmount': 68.07,
			'icRate': '2.2% + $0.10',
			'income': 121.63,
			'transactionCount': 96,
			'volume': 2657.88
		}, {
			'averageTicket': 26.5600,
			'cardType': 'MASTERCARD',
			'description': 'MC Key Entered - CK',
			'discountCharged': 7.13,
			'discountRate': '4.175% + $0.1',
			'icAmount': 2.3,
			'icRate': '1.6% + $0.15',
			'income': 4.83,
			'transactionCount': 4,
			'volume': 106.22
		}, {
			'averageTicket': 58.6300,
			'cardType': 'MASTERCARD',
			'description': 'Interregional Consumer Premium Electronic',
			'discountCharged': 3.63,
			'discountRate': '4.175% + $0.1',
			'icAmount': 1.08,
			'icRate': '1.85% + $0.00',
			'income': 2.55,
			'transactionCount': 1,
			'volume': 58.63
		}, {
			'averageTicket': 11.4400,
			'cardType': 'MASTERCARD',
			'description': 'LF Small Ticket - CK',
			'discountCharged': 8.22,
			'discountRate': '1.79% + $0',
			'icAmount': 4.33,
			'icRate': '0.05% + $0.22',
			'income': 3.89,
			'transactionCount': 19,
			'volume': 217.29
		}, {
			'averageTicket': 63.8600,
			'cardType': 'MASTERCARD',
			'description': 'LF Merit 3 - CK',
			'discountCharged': 9.77,
			'discountRate': '1.79% + $0',
			'icAmount': 1.77,
			'icRate': '0.05% + $0.22',
			'income': 8,
			'transactionCount': 7,
			'volume': 447.02
		}, {
			'averageTicket': 92.0200,
			'cardType': 'MASTERCARD',
			'description': 'World Elite T&E',
			'discountCharged': 191.7,
			'discountRate': '4.215% + $0.1',
			'icAmount': 76.32,
			'icRate': '2.75% + $0.10',
			'income': 115.38,
			'transactionCount': 29,
			'volume': 2668.68
		}, {
			'averageTicket': 55.2500,
			'cardType': 'MASTERCARD',
			'description': 'Enhanced Key Entered',
			'discountCharged': 3.66,
			'discountRate': '4.215% + $0.1',
			'icAmount': 1.23,
			'icRate': '2.04% + $0.10',
			'income': 2.43,
			'transactionCount': 1,
			'volume': 55.25
		}, {
			'averageTicket': 35.1300,
			'cardType': 'MASTERCARD',
			'description': 'Enhanced Merit 3',
			'discountCharged': 54.57,
			'discountRate': '4.175% + $0.1',
			'icAmount': 16.97,
			'icRate': '1.73% + $0.10',
			'income': 37.6,
			'transactionCount': 24,
			'volume': 843.18
		}, {
			'averageTicket': 91.1300,
			'cardType': 'MASTERCARD',
			'description': 'World High Value T&E',
			'discountCharged': 26.18,
			'discountRate': '4.215% + $0.1',
			'icAmount': 10.42,
			'icRate': '2.75% + $0.10',
			'income': 15.76,
			'transactionCount': 4,
			'volume': 364.53
		}, {
			'averageTicket': 89.0700,
			'cardType': 'MASTERCARD',
			'description': 'World MC T&E',
			'discountCharged': 42.02,
			'discountRate': '4.215% + $0.1',
			'icAmount': 15.04,
			'icRate': '2.3% + $0.10',
			'income': 26.98,
			'transactionCount': 7,
			'volume': 623.5
		}, {
			'averageTicket': 36.1300,
			'cardType': 'MASTERCARD',
			'description': 'LF MC Key Entered - CK',
			'discountCharged': 3.72,
			'discountRate': '4.215% + $0.1',
			'icAmount': 0.47,
			'icRate': '0.05% + $0.22',
			'income': 3.25,
			'transactionCount': 2,
			'volume': 72.26
		}, {
			'averageTicket': 690.6800,
			'cardType': 'MASTERCARD',
			'description': "Comm'l T&E I - Corporate",
			'discountCharged': 47.86,
			'discountRate': '4.215% + $0.1',
			'icAmount': 18.65,
			'icRate': '2.5% + $0.00',
			'income': 29.21,
			'transactionCount': 1,
			'volume': 690.68
		}, {
			'averageTicket': 77.9000,
			'cardType': 'MASTERCARD',
			'description': 'Business T&E Rate I - Level 4',
			'discountCharged': 33.44,
			'discountRate': '4.215% + $0.1',
			'icAmount': 13.14,
			'icRate': '2.81% + $0.00',
			'income': 20.3,
			'transactionCount': 6,
			'volume': 467.4
		}, {
			'averageTicket': 20.9900,
			'cardType': 'MASTERCARD',
			'description': 'Business T&E Rate I - Level 4',
			'discountCharged': 10.8,
			'discountRate': '4.215% + $0.1',
			'icAmount': 3.91,
			'icRate': '2.81% + $0.00',
			'income': 6.89,
			'transactionCount': 7,
			'volume': 146.91
		}, {
			'averageTicket': 42.8400,
			'cardType': 'DISCOVER',
			'description': 'Premium Plus PSL Restaurants',
			'discountCharged': 3,
			'discountRate': '4.215% + $0.1',
			'icAmount': 1.09,
			'icRate': '2.3% + $0.10',
			'income': 1.91,
			'transactionCount': 1,
			'volume': 42.84
		}, {
			'averageTicket': 10.6900,
			'cardType': 'DISCOVER',
			'description': 'Rewards PSL Express Services',
			'discountCharged': 1.51,
			'discountRate': '4.175% + $0.1',
			'icAmount': 0.42,
			'icRate': '1.95% + $0.00',
			'income': 1.09,
			'transactionCount': 2,
			'volume': 21.38
		}, {
			'averageTicket': 13.8900,
			'cardType': 'DISCOVER',
			'description': 'Premium Plus PSL Express Services',
			'discountCharged': 0.97,
			'discountRate': '4.215% + $0.1',
			'icAmount': 0.28,
			'icRate': '2.05% + $0.00',
			'income': 0.69,
			'transactionCount': 1,
			'volume': 13.89
		}, {
			'averageTicket': 38.4100,
			'cardType': 'DISCOVER',
			'description': 'Commercial Electronic',
			'discountCharged': 18.91,
			'discountRate': '4.215% + $0.1',
			'icAmount': 6.88,
			'icRate': '2.3% + $0.10',
			'income': 12.03,
			'transactionCount': 7,
			'volume': 268.87
		}, {
			'averageTicket': 58.9900,
			'cardType': 'DISCOVER',
			'description': 'Rewards PSL Restaurants',
			'discountCharged': 15.13,
			'discountRate': '4.175% + $0.1',
			'icAmount': 4.88,
			'icRate': '1.9% + $0.10',
			'income': 10.25,
			'transactionCount': 4,
			'volume': 235.96
		}, {
			'averageTicket': 687.7200,
			'cardType': 'DISCOVER',
			'description': 'Rewards Key Entry',
			'discountCharged': 42.46,
			'discountRate': '4.175% + $0.1',
			'icAmount': 13.65,
			'icRate': '1.97% + $0.10',
			'income': 28.81,
			'transactionCount': 1,
			'volume': 687.72
		}, {
			'averageTicket': 71.6700,
			'cardType': 'DISCOVER',
			'description': 'Premium PSL Restaurants',
			'discountCharged': 4.8,
			'discountRate': '4.215% + $0.1',
			'icAmount': 1.68,
			'icRate': '2.2% + $0.10',
			'income': 3.12,
			'transactionCount': 1,
			'volume': 71.67
		}, {
			'averageTicket': 417.3400,
			'cardType': 'AMERICAN EXPRESS',
			'description': 'AM Restaurant Tier 3          ',
			'discountCharged': 1362,
			'discountRate': '3.625% + $0',
			'icAmount': 590.44,
			'icRate': '2.75% + $0.10',
			'income': 771.56,
			'transactionCount': 51,
			'volume': 21284.37
		}, {
			'averageTicket': 17.5000,
			'cardType': 'AMERICAN EXPRESS',
			'description': 'AM Prepaid Tier 1             ',
			'discountCharged': 2.04,
			'discountRate': '3.625% + $0.05',
			'icAmount': 0.67,
			'icRate': '1.35% + $0.10',
			'income': 1.37,
			'transactionCount': 2,
			'volume': 34.99
		}, {
			'averageTicket': 59.1100,
			'cardType': 'AMERICAN EXPRESS',
			'description': 'AM Restaurant Tier 2          ',
			'discountCharged': 553.62,
			'discountRate': '3.625% + $0',
			'icAmount': 232.23,
			'icRate': '2.45% + $0.10',
			'income': 321.39,
			'transactionCount': 150,
			'volume': 8865.91
		}, {
			'averageTicket': 15.2600,
			'cardType': 'AMERICAN EXPRESS',
			'description': 'AM Restaurant Tier 1          ',
			'discountCharged': 83.85,
			'discountRate': '3.625% + $0.05',
			'icAmount': 32.59,
			'icRate': '1.85% + $0.10',
			'income': 51.26,
			'transactionCount': 85,
			'volume': 1296.69
		}, {
			'averageTicket': 0.0000,
			'cardType': 'AMERICAN EXPRESS',
			'description': 'AM Returns',
			'discountCharged': 0.05,
			'discountRate': '0% + $0.05',
			'icAmount': 0,
			'icRate': '0% + $0.00',
			'income': 0.05,
			'transactionCount': 0,
			'volume': 0
		}, {
			'averageTicket': 3.2000,
			'cardType': 'AMERICAN EXPRESS',
			'description': 'AM BLUE RESTAURANT MICRO',
			'discountCharged': 0.43,
			'discountRate': '3.625% + $0',
			'icAmount': 0.2,
			'icRate': '1.9% + $0.04',
			'income': 0.23,
			'transactionCount': 2,
			'volume': 6.39
		}],
		'thirdPartyInfo': [{ 'payout': 2.3500, 'transactionCount': 7, 'vendor': 'HT Gift Cards', 'volume': 0 }]
	};
});