define('genesis-residual-system/mirage/fixtures/merchant-list', ['exports'], function (exports) {
	var merchants = [{
		'id': '0014132427',
		'name': 'JABULA INTERNATIONAL NETWORK - N',
		'payoutAmount': 35.4,
		'refunds': 2,
		'isRejected': false,
		'sales': 0,
		'salesRepId': 'BJORD',
		'split': 'Pick-And-Choose Tier 2 - 60% Split',
		'volume': 0
	}, {
		'id': '0018522499',
		'name': 'FRUITRIDGE QUICK MART',
		'payoutAmount': 73.12,
		'refunds': 0,
		'isRejected': false,
		'sales': 402,
		'salesRepId': 'PEG99',
		'split': 'Perkwave Program Tier 2 - 60% Split',
		'volume': 2917.59
	}, {
		'id': '0021370450',
		'name': 'ORCHARD CITY PARKING',
		'payoutAmount': 77.31,
		'refunds': 0,
		'isRejected': false,
		'sales': 700,
		'salesRepId': 'PPROS',
		'split': 'HarborPay Option 3 - 75% Split',
		'volume': 6859
	}, {
		'id': '0020508744',
		'name': 'DR. JOSEPH VOLPICELLI, MD PHD',
		'payoutAmount': 22.6875,
		'refunds': 0,
		'isRejected': false,
		'sales': 0,
		'salesRepId': 'LANE4',
		'split': 'HarborPay Option 3 - 75% Split',
		'volume': 0
	}, {
		'id': '0018517695',
		'name': 'DIAMOND FOOD MART',
		'payoutAmount': 17.46,
		'refunds': 0,
		'isRejected': false,
		'sales': 0,
		'salesRepId': 'PEG99',
		'split': 'Perkwave Program Tier 1 - 50% Split',
		'volume': 0
	}, {
		'id': '0018472743',
		'name': 'THE MILL',
		'payoutAmount': 57.925,
		'refunds': 0,
		'isRejected': false,
		'sales': 111,
		'salesRepId': 'RYANE',
		'split': 'Perkwave Program Tier 1 - 50% Split',
		'volume': 6816.43
	}, {
		'id': '0016997615',
		'name': "RICO'S PIZZA",
		'payoutAmount': 138.225,
		'refunds': 0,
		'isRejected': false,
		'sales': 622,
		'salesRepId': 'PPROS',
		'split': 'Perkwave Program Tier 1 - 50% Split',
		'volume': 21739.46
	}, {
		'id': '0018459960',
		'name': 'THE VIEW RESTAURANT & TAVERN',
		'payoutAmount': 57.425,
		'refunds': 0,
		'isRejected': false,
		'sales': 57,
		'salesRepId': 'RYANE',
		'split': 'Perkwave Program Tier 1 - 50% Split',
		'volume': 2548.56
	}, {
		'id': '0018526301',
		'name': 'LO STELLA RISTORANTE',
		'payoutAmount': 1041.4,
		'refunds': 0,
		'isRejected': false,
		'sales': 1121,
		'salesRepId': 'PEG00',
		'split': 'Perkwave Program Tier 1 - 50% Split',
		'volume': 143447.74
	}, {
		'id': '0020165569',
		'name': "DOLLYN'S CAFE",
		'payoutAmount': 57.8385,
		'refunds': 0,
		'isRejected': false,
		'sales': 131,
		'salesRepId': 'LANE1',
		'split': 'Echo / Apple Pay Option 1 - 55% Split',
		'volume': 3481.86
	}, {
		'id': '0020167949',
		'name': 'GAUCHOS VILLAGE',
		'payoutAmount': 1204.807,
		'refunds': 0,
		'isRejected': false,
		'sales': 813,
		'salesRepId': 'PPROS',
		'split': 'Echo / Apple Pay Option 1 - 55% Split',
		'volume': 108453.66
	}, {
		'id': '0019974910',
		'name': "PETE'S HOT DOG SHOP",
		'payoutAmount': 45.289,
		'refunds': 0,
		'isRejected': false,
		'sales': 118,
		'salesRepId': 'LANE4',
		'split': 'Echo / Apple Pay Option 1 - 55% Split',
		'volume': 1532.45
	}, {
		'id': '0020128920',
		'name': 'EL JARDIN - COLUMBIA',
		'payoutAmount': 45.3195,
		'refunds': 0,
		'isRejected': false,
		'sales': 482,
		'salesRepId': 'PPRO1',
		'split': 'Echo / Apple Pay Option 1 - 55% Split',
		'volume': 17759.61
	}, {
		'id': '0020126391',
		'name': '515 KITCHEN & COCKTAILS',
		'payoutAmount': 732.5415,
		'refunds': 0,
		'isRejected': false,
		'sales': 1752,
		'salesRepId': 'PPRO1',
		'split': 'Echo / Apple Pay Option 1 - 55% Split',
		'volume': 95890.51
	}, {
		'id': '0020194572',
		'name': 'DEEP BLUE AQUARIUMS',
		'payoutAmount': 52.1875,
		'refunds': 0,
		'isRejected': true,
		'sales': 0,
		'salesRepId': 'PPROS',
		'split': 'Echo / Apple Pay Option 1 - 55% Split',
		'volume': 0
	}];

	exports['default'] = merchants;
});