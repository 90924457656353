define('genesis-residual-system/mixins/sidebar-mixin', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
	exports['default'] = _ember['default'].Mixin.create({
		getSidebarTilesConfig: function getSidebarTilesConfig(officeHeader, officeInfo) {
			var archiveDate = officeHeader.officeEarnings.archiveDate,
			    merchantPortfolioData = undefined;
			if (officeHeader.archives && officeHeader.archives > 0) {
				merchantPortfolioData = officeHeader.archives.map(function (item) {
					return { title: 'ACH Date: ' + item.archiveDate, value: item.amount };
				});
				var last = merchantPortfolioData[merchantPortfolioData.length - 1];
				last.percentChangeMoM = officeHeader.officeEarnings.monthOverMonthResidualPercentChange;
				last.percentChangeYoY = officeHeader.officeEarnings.yearOverYearResidualPercentChange;
			} else {
				if (!archiveDate || (0, _moment['default'])(archiveDate, 'MMM DD YYYY').format('MM/DD/YYYY') === 'Invalid date') {
					archiveDate = 'N/A';
				} else {
					archiveDate = (0, _moment['default'])(archiveDate, 'MMM DD YYYY').format('MM/DD/YYYY');
				}
				merchantPortfolioData = [{ title: 'ACH Date: ' + archiveDate, value: officeHeader.officeEarnings.currentResidual,
					percentChangeMoM: officeHeader.officeEarnings.monthOverMonthResidualPercentChange,
					percentChangeYoY: officeHeader.officeEarnings.yearOverYearResidualPercentChange }];
			}

			return [{
				id: 'merchant-portfolio',
				title: 'Residuals Payout',
				value: officeHeader.officeEarnings.currentResidual,
				subTitle: 'Merchant Portfolio',
				link: 'dashboards.residuals.merchants',
				mainData: merchantPortfolioData,
				isExpanded: this.currentModel !== undefined && this.currentModel.sidebarTiles[0].isExpanded,
				subSection: {
					id: 'misc-adjustments',
					title: 'Total Misc Adjustment',
					value: officeInfo.get('totalMiscAdjustment'),
					mainData: [{ title: 'Revenue Share', value: officeInfo.get('totalRevenueShareMiscAdjustments'), link: 'dashboards.residuals.merchants', params: { tab: 'misc-adjustments', type: 'Revenue Share' } }, { title: 'Monthly Bonuses', value: officeInfo.get('totalBonusMiscAdjustments'), link: 'dashboards.residuals.merchants', params: { tab: 'misc-adjustments', type: 'Bonus' } }, { title: 'Bonus Retractions', value: officeInfo.get('totalBonusRetractionMiscAdjustments'), link: 'dashboards.residuals.merchants', params: { tab: 'misc-adjustments', type: 'Bonus Retraction' } }, { title: 'Miscellaneous', value: officeInfo.get('totalMiscellaneousMiscAdjustments'), link: 'dashboards.residuals.merchants', params: { tab: 'misc-adjustments', type: 'Miscellaneous' } }].filter(function (item) {
						return item.value !== 0;
					}),
					isExpanded: this.currentModel !== undefined && this.currentModel.sidebarTiles[0].subSection.isExpanded
				}
			}, {
				id: 'bonuses',
				title: 'Total Bonus Payout',
				value: officeHeader.officeEarnings.dailyBonuses,
				mainData: [{
					title: 'Daily Bonuses',
					link: 'dashboards.residuals.merchants',
					value: officeHeader.officeEarnings.dailyBonuses,
					percentChangeMoM: officeHeader.officeEarnings.monthOverMonthBonusPercentChange,
					percentChangeYoY: officeHeader.officeEarnings.yearOverYearBonusPercentChange, params: { tab: 'bonuses' } }, { title: 'Monthly Bonuses', value: officeHeader.officeEarnings.monthlyBonuses, link: 'dashboards.residuals.merchants', params: { tab: 'misc-adjustments', type: 'Bonus' }, textColor: 'text-muted',
					popover: {
						content: '<small>This amount is included in Misc Adjustment</small>',
						placement: 'top'
					} }],
				isExpanded: this.currentModel !== undefined && this.currentModel.sidebarTiles[1].isExpanded
			}, {
				id: 'third-party-residuals',
				title: 'Third Party Payout',
				value: officeInfo.get('totalThirdPartyPayout'),
				mainData: officeInfo.get('thirdPartyReport').map(function (item) {
					return { title: item.vendor, value: item.payout, link: 'dashboards.residuals.third-party-residual', params: { segment: item.vendor } };
				}),
				isExpanded: this.currentModel !== undefined && this.currentModel.sidebarTiles[2].isExpanded
			}];
		},
		getSidebarTotalConfig: function getSidebarTotalConfig(officeHeader, officeInfo) {
			return {
				total: { text: 'Portfolio Earnings', value: officeHeader.officeEarnings.currentResidual + officeHeader.officeEarnings.dailyBonuses + officeInfo.get('totalThirdPartyPayout') }
			};
		},
		getSidebarAccountsInfoConfig: function getSidebarAccountsInfoConfig(officeHeader) {
			return { text: 'Active Merchants', value: officeHeader.officeEarnings.merchantCount };
		}
	});
});