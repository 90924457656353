define('genesis-residual-system/mixins/data-table/data-table-mixin', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Mixin.create({
		getTabConfig: function getTabConfig(tab) {
			return {
				id: tab.id,
				tabId: tab.tabId,
				active: tab.isActive,
				name: tab.name,
				cats: tab.headers,
				filters: tab.filters,
				note: tab.note,
				columns: tab.columns,
				data: tab.data,
				columnDefs: tab.columnDefs,
				hasTotal: tab.hasTotal,
				totalColumns: tab.totalColumns,
				orderColumn: tab.orderColumn,
				createdRowCallback: tab.createdRowCallback
			};
		}
	});
});