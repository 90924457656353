define('genesis-residual-system/routes/dashboards/residuals/processing-details', ['exports', 'ember', 'genesis-residual-system/mixins/residuals-route-mixin', 'genesis-residual-system/mixins/routes/dashboards/residuals/processing-details-mixin'], function (exports, _ember, _genesisResidualSystemMixinsResidualsRouteMixin, _genesisResidualSystemMixinsRoutesDashboardsResidualsProcessingDetailsMixin) {
	exports['default'] = _ember['default'].Route.extend(_genesisResidualSystemMixinsResidualsRouteMixin['default'], _genesisResidualSystemMixinsRoutesDashboardsResidualsProcessingDetailsMixin['default'], {
		queryParams: {
			date: {
				refreshModel: true
			}
		},
		session: _ember['default'].inject.service('session'),
		model: function model(params) {
			_ember['default'].$('.loading').fadeIn();
			var model = this.modelFor('dashboards.residuals.merchant'),
			    filterParams = this.getFilterParams(params);

			if (!model) {
				this.transitionTo('dashboards.residuals');
			}

			_ember['default'].$('.loading').fadeOut();
			return this.buildModel(model.merchantHeader, model.merchantProcessing, model.residualMonths, filterParams);
		},
		actions: {
			setDate: function setDate(date) {
				var _this = this;

				_ember['default'].$('.loading').fadeIn();
				var params = this.getOfficeAndMerchantFilterParams(this.currentModel, date),
				    requests = this.getOfficeAndMerchantDetailsRequests(this.store, params);

				_ember['default'].RSVP.hash(requests).then(function (response) {
					var model = _this.buildModel(response.merchantHeader.data, {
						list: response.merchantProcessing.data.list,
						filterData: response.merchantProcessing.get('uniqCardTypes')
					}, response.residualMonths.data.list, params);
					_this.updateModel(model);
					var sidebarFilterParams = _this.getSidebarFilterParams(params);
					_this.send('updateSidebar', response.officeHeader.data, response.officeInfo, response.residualMonths.data.list, sidebarFilterParams);
					_this.send('updateExportDataConfig', model.exportData);
					_ember['default'].$('.loading').fadeOut();
				});
			},
			redirect: function redirect(url) {
				this.transitionTo(url);
			}
		}
	});
});