define('genesis-residual-system/router', ['exports', 'ember', 'genesis-residual-system/config/environment'], function (exports, _ember, _genesisResidualSystemConfigEnvironment) {
	var computed = _ember['default'].computed;
	var isPresent = _ember['default'].isPresent;

	var Router = _ember['default'].Router.extend({
		location: _genesisResidualSystemConfigEnvironment['default'].locationType,
		rootURL: computed(function () {
			var baseRootURL = _genesisResidualSystemConfigEnvironment['default'].rootURL || '';
			var path = window.location.pathname;

			var revisionMatch = new RegExp('^${baseRootURL}/(rev-[^/])').exec(path);

			if (revisionMatch && isPresent(revisionMatch[1])) {
				return baseRootURL + '/' + revisionMatch[1] + '/';
			}
			return '' + baseRootURL;
		})
	});

	Router.map(function () {
		this.route('login');
		this.route('error');

		this.route('dashboards', function () {
			this.route('residuals', function () {
				this.route('merchant', { path: '/merchant/:merchantId' });
				this.route('third-party-residual', { path: '/third-party-residual/:type' });
				this.route('merchants');
				this.route('processing-details', { path: 'merchant/processing-details/:merchantId' });
			});
		});
	});

	exports['default'] = Router;
});