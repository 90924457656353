define('genesis-residual-system/highcharts/portfolio-by-industry-highchart', ['exports', 'ember', 'genesis-residual-system/highcharts/highchart'], function (exports, _ember, _genesisResidualSystemHighchartsHighchart) {
	exports['default'] = _ember['default'].Object.extend(_genesisResidualSystemHighchartsHighchart['default'], {
		inverted: true,
		marginLeft: 125,
		perShapeGradient: {
			x1: 1,
			y1: 0,
			x2: 0,
			y2: 0
		},
		color: _ember['default'].computed('perShapeGradient', function () {
			return {
				linearGradient: this.get('perShapeGradient'),
				stops: [[0, 'rgb(93, 173, 250)'], [1, 'rgb(4, 102, 193)']]
			};
		}),
		getChartDataFormat: function getChartDataFormat(item) {
			return { name: item.industry, y: item.merchantCount, color: this.get('color') };
		},
		getHighchartConfig: function getHighchartConfig() {
			var config = this._super.apply(this, arguments);
			config.chartType = this.data.length > 0 ? this.data[0].chartType : 'Portfolio By Industry';

			return config;
		}
	});
});