define('genesis-residual-system/routes/error', ['exports', 'ember', 'genesis-residual-system/config/environment'], function (exports, _ember, _genesisResidualSystemConfigEnvironment) {
	exports['default'] = _ember['default'].Route.extend({
		model: function model(params, transition) {
			if (transition.queryParams.error) {
				var error = transition.queryParams.error.split(':');
				return { supportEmail: _genesisResidualSystemConfigEnvironment['default'].supportEmail, subject: error[0], message: error[1], details: transition.queryParams.message };
			} else if (transition.queryParams.message) {
				return { supportEmail: _genesisResidualSystemConfigEnvironment['default'].supportEmail, subject: 'Error', details: transition.queryParams.message };
			}

			return { supportEmail: _genesisResidualSystemConfigEnvironment['default'].supportEmail, subject: 'System Error' };
		},
		afterModel: function afterModel() {
			_ember['default'].$('body').removeClass('login');
			_ember['default'].$('.loading').fadeOut();
		}
	});
});