define('genesis-residual-system/services/resolution', ['exports', 'ember', 'genesis-residual-system/mixins/resize-sidebar-mixin'], function (exports, _ember, _genesisResidualSystemMixinsResizeSidebarMixin) {
	exports['default'] = _ember['default'].Service.extend(_genesisResidualSystemMixinsResizeSidebarMixin['default'], {
		init: function init() {
			var self = this;
			//Call to set environment variables at application start
			self.setSidebarHeight();
			_ember['default'].$(window).resize(function () {
				_ember['default'].run.debounce(self, self.setSidebarHeight, 1000);
			});
		}
	});
});