define('genesis-residual-system/highcharts/product-mix-highchart', ['exports', 'ember', 'genesis-residual-system/components/charts-theme/default-theme', 'genesis-residual-system/highcharts/highchart'], function (exports, _ember, _genesisResidualSystemComponentsChartsThemeDefaultTheme, _genesisResidualSystemHighchartsHighchart) {
	exports['default'] = _ember['default'].Object.extend(_genesisResidualSystemHighchartsHighchart['default'], {
		getChartConfig: function getChartConfig() {
			var config = this._super.apply(this, arguments);

			config.height = 276;

			return config;
		},
		getTooltipConfig: function getTooltipConfig() {
			return {
				style: {
					fontSize: '14px'
				},
				headerFormat: '<span>Products</span><br>',
				pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.z}</b> merchants<br/>'
			};
		},
		getXAxisConfig: function getXAxisConfig() {
			return {
				type: 'category'
			};
		},
		getYAxisConfig: function getYAxisConfig() {
			return {
				title: {
					text: 'Net Profit'
				}
			};
		},
		getPlotOptions: function getPlotOptions() {
			return {
				series: {
					borderWidth: 0,
					dataLabels: {
						enabled: true,
						format: '${point.y:,.2f}'
					}
				}
			};
		},
		getThemeConfig: function getThemeConfig() {
			return _genesisResidualSystemComponentsChartsThemeDefaultTheme['default'];
		},
		getChartDataFormat: function getChartDataFormat(item) {
			return { name: item.product, y: item.residual, z: item.merchantCount };
		},
		getChartDataConfig: function getChartDataConfig() {
			return [{
				name: 'Residuals based on Product Mix',
				colorByPoint: true,
				data: this.getChartData()
			}];
		},
		getChartOptionsConfig: function getChartOptionsConfig() {
			var config = this._super.apply(this, arguments);
			config.plotOptions = this.getPlotOptions();

			return config;
		}
	});
});