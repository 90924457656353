define('genesis-residual-system/mixins/navbar-mixin', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Mixin.create({
		setActiveItem: function setActiveItem(item, subitem, selectedItem) {
			var hasTabParam = selectedItem.params.tabId && subitem.link === selectedItem.router && subitem.params.tab === selectedItem.params.tabId;
			if (selectedItem.type) {
				if (subitem.link === selectedItem.router && subitem.params.segment === selectedItem.type) {
					subitem.cssClass = 'active';
					item.cssClass = 'active';
					return true;
				}
			} else if (selectedItem.params.type) {
				if (hasTabParam && subitem.params.type === selectedItem.params.type) {
					subitem.cssClass = 'active';
					item.cssClass = 'active';
					return true;
				}
			} else if (hasTabParam) {
				subitem.cssClass = 'active';
				item.cssClass = 'active';
				return true;
			}

			return false;
		},
		setActiveSubmenuElement: function setActiveSubmenuElement(item, selectedItem) {
			var _iteratorNormalCompletion = true;
			var _didIteratorError = false;
			var _iteratorError = undefined;

			try {
				for (var _iterator = item.submenu[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
					var subitem = _step.value;

					if (this.setActiveItem(item, subitem, selectedItem)) {
						return true;
					}
				}
			} catch (err) {
				_didIteratorError = true;
				_iteratorError = err;
			} finally {
				try {
					if (!_iteratorNormalCompletion && _iterator['return']) {
						_iterator['return']();
					}
				} finally {
					if (_didIteratorError) {
						throw _iteratorError;
					}
				}
			}

			return false;
		},
		setActiveMenuElement: function setActiveMenuElement(item, selectedItem) {
			if (selectedItem.router === item.link) {
				if (selectedItem.params.tabId) {
					if (selectedItem.params.tabId === item.params.tab) {
						item.cssClass = 'active';
					}
				} else {
					item.cssClass = 'active';
				}
			}
		},
		setActiveNavbarElement: function setActiveNavbarElement(config, selectedItem) {
			var res = undefined;
			var _iteratorNormalCompletion2 = true;
			var _didIteratorError2 = false;
			var _iteratorError2 = undefined;

			try {
				for (var _iterator2 = config[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
					var item = _step2.value;

					if (item.link) {
						res = this.setActiveMenuElement(item, selectedItem);
					} else {
						res = this.setActiveSubmenuElement(item, selectedItem);
					}

					if (res) {
						break;
					}
				}
			} catch (err) {
				_didIteratorError2 = true;
				_iteratorError2 = err;
			} finally {
				try {
					if (!_iteratorNormalCompletion2 && _iterator2['return']) {
						_iterator2['return']();
					}
				} finally {
					if (_didIteratorError2) {
						throw _iteratorError2;
					}
				}
			}

			var activeItem = config.find(function (item) {
				return item.cssClass !== '';
			});
			if (activeItem === undefined) {
				// set active Merchant Portfolio
				config[1].cssClass = 'active';
			}
		},
		getSecondaryNavbarConfig: function getSecondaryNavbarConfig(officeInfo, selectedItem) {
			var config = [{ name: 'Dashboard', link: 'dashboards.residuals.index', params: {}, cssClass: '' }, { name: 'Merchant Portfolio', link: 'dashboards.residuals.merchants', params: { tab: 'merchant-portfolio' }, cssClass: '' }, { name: 'Misc Adjustments',
				submenu: [{ name: 'Revenue Share', link: 'dashboards.residuals.merchants', params: { tab: 'misc-adjustments', type: 'Revenue Share' } }, { name: 'Monthly Bonuses', link: 'dashboards.residuals.merchants', params: { tab: 'misc-adjustments', type: 'Bonus' } }, { name: 'Bonus Retractions', link: 'dashboards.residuals.merchants', params: { tab: 'misc-adjustments', type: 'Bonus Retraction' } }, { name: 'Miscellaneous', link: 'dashboards.residuals.merchants', params: { tab: 'misc-adjustments', type: 'Miscellaneous' } }], cssClass: '' }, { name: 'Bonuses',
				submenu: [{ name: 'Daily Bonuses', link: 'dashboards.residuals.merchants', params: { tab: 'bonuses' } }, { name: 'Monthly Bonuses', link: 'dashboards.residuals.merchants', params: { tab: 'misc-adjustments', type: 'Bonus' } }], cssClass: '' }, { name: 'Third Party',
				submenu: officeInfo.get('thirdPartyReport').map(function (item) {
					return { name: item.vendor, link: 'dashboards.residuals.third-party-residual', params: { segment: item.vendor } };
				}), cssClass: '' }];

			if (typeof selectedItem !== 'undefined') {
				this.setActiveNavbarElement(config, selectedItem);
			}

			return config;
		}
	});
});