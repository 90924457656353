define('genesis-residual-system/mixins/intro-js/third-party-intro-js-mixin', ['exports', 'ember', 'genesis-residual-system/mixins/intro-js/intro-js-mixin'], function (exports, _ember, _genesisResidualSystemMixinsIntroJsIntroJsMixin) {
	exports['default'] = _ember['default'].Mixin.create(_genesisResidualSystemMixinsIntroJsIntroJsMixin['default'], {
		tutorialModel: 'thirdPartyResiduals',
		steps: _ember['default'].computed(function () {
			return [{
				intro: 'This page breaks details the Third Party Residuals earned over the month for the selected vendor.'
			}, {
				element: _ember['default'].$('.filterHeader:visible')[0],
				intro: 'You are able to select individual Sales Reps by using the dropdown. You are also able to search for a Merchant using the search bar.',
				scrollTo: 'tooltip'

			}, {
				element: _ember['default'].$('.table-responsive .dataTable:visible')[0],
				intro: 'You can also sort your Payouts by: Salesrep ID, MID, Merchant Name, Transaction, Trans Inc, Monthly Fee Inc, Monthly Min Inc, Misc Inc, and Total Payout, by clicking the appropriate column header.',
				scrollTo: 'tooltip'
			}];
		})
	});
});