define("genesis-residual-system/mirage/scenarios/default", ["exports"], function (exports) {
  /**
   *
   * @param {server} server The server
   */

  exports["default"] = function (server) {

    /*
       Seed your development database using your factories.
       This data will not be loaded in your tests.
        Make sure to define a factory for each model you want to create.
     */

    server.loadFixtures();
  };
});