define('genesis-residual-system/mixins/intro-js/merchant-intro-js-mixin', ['exports', 'ember', 'genesis-residual-system/mixins/intro-js/intro-js-mixin'], function (exports, _ember, _genesisResidualSystemMixinsIntroJsIntroJsMixin) {
	exports['default'] = _ember['default'].Mixin.create(_genesisResidualSystemMixinsIntroJsIntroJsMixin['default'], {
		tutorialModel: 'merchantDetail',
		steps: _ember['default'].computed(function () {
			return [{
				intro: 'The Merchant Detail Page provides an overview of an individual merchants contribution for the Residuals Month.'
			}, {
				element: '#merchantInfo',
				intro: 'You can see the merchants Information here.',
				scrollTo: 'tooltip'

			}, {
				element: '.approvalDate',
				intro: 'The Approval date indicated here allows you to assess the risk of the merchant. If a merchant has been processing for less than a year this text will be orange, and if the merchant has been processing more than a year this text will be green.',
				scrollTo: 'tooltip'
			}, {
				element: '#residualsPayout',
				intro: 'This widget details exactly how the residuals are calculated for the merchant.',
				scrollTo: 'tooltip'
			}, {
				element: '#volumeByCard',
				intro: 'This chart details the merchants volumes by card type for the residuals month.',
				scrollTo: 'tooltip'
			}, {
				element: '#categoriesByProfit',
				intro: 'This chart simply helps you identify your Top 10 Interchange Categories by Profit earned.',
				scrollTo: 'tooltip'
			}, {
				element: '#merchantInfo .btn',
				intro: 'The Processing detail button takes you to the Merchants processing detail.',
				scrollTo: 'tooltip'
			}, {
				element: _ember['default'].$('.downloadReport button:visible')[0],
				intro: 'You can download The Merchant Statements and processing information using the download button here.'
			}];
		})
	});
});