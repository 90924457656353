define('genesis-residual-system/models/merchant-list', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		list: _emberData['default'].attr(''),
		sortDefinition: ['salesRepId'],
		sortVolumeDefinition: ['volume:desc'],
		sortByVolumeList: _ember['default'].computed.sort('list', 'sortVolumeDefinition'),
		sortSalesRep: _ember['default'].computed.sort('list', 'sortDefinition'),
		salesRep: _ember['default'].computed.mapBy('sortSalesRep', 'salesRepId'),
		uniqSalesRep: _ember['default'].computed.uniq('salesRep')
	});
});