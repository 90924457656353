define('genesis-residual-system/adapters/application', ['exports', 'ember', 'ember-data', 'genesis-residual-system/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _ember, _emberData, _genesisResidualSystemConfigEnvironment, _emberSimpleAuthMixinsDataAdapterMixin) {
	exports['default'] = _emberData['default'].RESTAdapter.extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], {
		authorizer: 'authorizer:custom',
		session: _ember['default'].inject.service(),
		officeId: _ember['default'].computed.alias('session.data.authenticated.user'),
		host: _genesisResidualSystemConfigEnvironment['default'].host,
		namespace: _genesisResidualSystemConfigEnvironment['default'].namespace,
		headers: {
			'Cache-Control': 'no-cache',
			'Pragma': 'no-cache',
			'Expires': 0
		}
	});
});