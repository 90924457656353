define('genesis-residual-system/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {

	var INVALID_TOKEN_ERROR = 'Invalid Token';
	var EXPIRED_TOKEN_ERROR = 'Token has expired';

	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
		session: _ember['default'].inject.service('session'),
		isSessionExpired: true,
		model: function model() {
			return {
				currentYear: new Date().getFullYear(),
				branding: {},
				session: this.get('session')
			};
		},
		sessionInvalidated: function sessionInvalidated() {
			if (this.isSessionExpired) {
				this.transitionTo('login', {
					queryParams: {
						sessionExpired: true,
						message: this.message
					}
				});
			} else {
				this._super.apply(this, arguments);
			}
		},
		actions: {
			logout: function logout(isSessionExpired, message) {
				this.isSessionExpired = isSessionExpired;
				this.message = message;
				this.get('session').invalidate();
			},
			error: function error(_error, transition) {
				var errors = [EXPIRED_TOKEN_ERROR, INVALID_TOKEN_ERROR];
				var errorDetail = _error.errors && _error.errors.length > 0 ? _error.errors[0].detail : '';

				if (errors.some(function (item) {
					return _error.message.includes(item);
				}) || errors.some(function (item) {
					return errorDetail.includes(item);
				})) {
					transition.abort();
					transition.send('logout', true);
				} else {
					this.transitionTo('error', {
						queryParams: {
							error: errorDetail,
							message: _error.message
						}
					});
				}
			}
		}
	});
});