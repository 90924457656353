define('genesis-residual-system/mixins/export-data/merchant-residual-detail-export-data-mixin', ['exports', 'ember', 'genesis-residual-system/mixins/export-data/export-data-mixin'], function (exports, _ember, _genesisResidualSystemMixinsExportDataExportDataMixin) {
	function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

	exports['default'] = _ember['default'].Mixin.create(_genesisResidualSystemMixinsExportDataExportDataMixin['default'], {
		addMerchantResidualDetailOverviewList: function addMerchantResidualDetailOverviewList(merchantHeader, params) {
			var list = [].concat(_toConsumableArray(this.addMerchantResidualDetailOverviewHeader(merchantHeader.merchantPortfolio)), [[]], _toConsumableArray(this.addMerchantResidualDetail(merchantHeader.merchantPayout)));

			list.push.apply(list, _toConsumableArray(this.addVolumeByCardTypeTable(merchantHeader.cardTypeVolumes, params)));
			list.push([]);
			list.push.apply(list, _toConsumableArray(this.addCategoriesByProfitTable(merchantHeader.profitInterchangeCategories)));

			return list;
		},
		addCategoriesByProfitTable: function addCategoriesByProfitTable(profitInterchangeCategories) {
			var attributes = [{ key: 'cardType', name: 'Card Type' }, { key: 'category', name: 'Category' }, { key: 'profit', name: 'Profit', formatMoney: true }, { key: 'salesCount', name: 'Sales' }, { key: 'volume', name: 'Volume' }];

			return [[this.addTitleCell('Top 10 IC Categories by Profit')]].concat(_toConsumableArray(this.addTable(profitInterchangeCategories, attributes)));
		},
		addMerchantResidualDetail: function addMerchantResidualDetail(merchantPayout) {
			return [[this.addTitleCell('Income')], [{ value: 'Processing' }, { value: merchantPayout.processingIncome, formatMoney: true }], [{ value: 'Authorization' }, { value: merchantPayout.authIncome, formatMoney: true }], [{ value: 'Miscellaneous' }, { value: merchantPayout.miscIncome, formatMoney: true }], [], [this.addTitleCell('Expense'), { value: merchantPayout.expense, formatMoney: true }], [], [this.addTitleCell('Fixed Expense'), { value: merchantPayout.fixedExpense, formatMoney: true }], [], [this.addTitleCell('Fixed Income'), { value: merchantPayout.fixedIncome, formatMoney: true }], [], [this.addTitleCell('Total Income'), { value: merchantPayout.totalIncome, formatMoney: true }], []];
		},
		addMerchantResidualDetailOverviewHeader: function addMerchantResidualDetailOverviewHeader(merchantPortfolio) {
			return [[{ value: merchantPortfolio.name, bold: true, fontSize: '14px' }], [{ value: merchantPortfolio.salesRepId }], [{ value: merchantPortfolio.split }], [{ value: merchantPortfolio.productType }]];
		},
		addProcessingList: function addProcessingList(merchantProcessing) {
			var attributes = [{ key: 'cardType', name: 'Card Type' }, { key: 'transactionCount', name: 'Transactions' }, { key: 'volume', name: 'Volume', formatMoney: '$#,##0.000' }, { key: 'description', name: 'IC Category' }, { key: 'averageTicket', name: 'AVG Ticket', formatMoney: '$#,##0.000' }, { key: 'icRate', name: 'IC Rate' }, { key: 'icAmount', name: 'IC Expense', formatMoney: '$#,##0.000' }, { key: 'discountRate', name: 'Discount Rate' }, { key: 'discountCharged', name: 'Discount Charged', formatMoney: '$#,##0.000' }, { key: 'income', name: 'Income', formatMoney: '$#,##0.000' }];

			return this.addTable(merchantProcessing.monetaryInfo, attributes);
		},
		addAuthorizationList: function addAuthorizationList(merchantProcessing) {
			var attributes = [{ key: 'vendor', name: 'Vendor' }, { key: 'cardType', name: 'Card Type' }, { key: 'authCount', name: '# of Auth' }, { key: 'merchantRate', name: 'Merchant Rate', formatMoney: '$#,##0.0000' }, { key: 'billedToMerchant', name: 'Merchant Income', formatMoney: '$#,##0.000' }, { key: 'isoRate', name: 'ISO Cost', formatMoney: '$#,##0.0000' }, { key: 'billedToIso', name: 'ISO Expense', formatMoney: '$#,##0.000' }, { key: 'income', name: 'Income', formatMoney: '$#,##0.000' }];

			return this.addTable(merchantProcessing.authInfo, attributes);
		},
		addMiscellaneousList: function addMiscellaneousList(merchantProcessing) {
			var attributes = [{ key: 'description', name: 'Item' }, { key: 'itemCount', name: 'Item Count' }, { key: 'billedToMerchant', name: 'Billed to Merchant', formatMoney: '$#,##0.000' }, { key: 'expenseRate', name: 'Expense Rate', formatMoney: '$#,##0.000000' }, { key: 'billedToIso', name: 'Expense', formatMoney: '$#,##0.000' }, { key: 'income', name: 'Income', formatMoney: '$#,##0.000' }];

			return this.addTable(merchantProcessing.miscInfo, attributes);
		},
		addFixedIncomeExpenseList: function addFixedIncomeExpenseList(merchantProcessing) {
			var attributes = [{ key: 'description', name: 'Item' }, { key: 'unitCount', name: 'Item Count' }, { key: 'income', name: 'Income', formatMoney: '$#,##0.000' }, { key: 'cost', name: 'Expense Rate', formatMoney: '$#,##0.000' }, { key: 'billedToIso', name: 'Expense', formatMoney: '$#,##0.000' }, { key: 'netIncomeExpense', name: 'Net Income / Expense', formatMoney: '$#,##0.000' }];

			return this.addTable(merchantProcessing.fixedExpenses, attributes);
		},
		addThirdPartyPayoutList: function addThirdPartyPayoutList(merchantProcessing) {
			var attributes = [{ key: 'vendor', name: 'Name' }, { key: 'payout', name: 'Payout Amount', formatMoney: '$#,##0.000' }, { key: 'volume', name: 'Volume', formatMoney: '$#,##0.000' }, { key: 'transactionCount', name: '# of Transactions' }];

			return this.addTable(merchantProcessing.thirdPartyInfo, attributes);
		},
		getMerchantResidualDetailExportDataConfig: function getMerchantResidualDetailExportDataConfig(merchantHeader, merchantProcessing, params) {
			var conf = {
				title: 'Download Excel',
				format: 'xlsx',
				fileName: 'Merchant Residual Detail.xlsx',
				cols: {
					'Overview': [{ wch: 30 }, { wch: 25 }, { wch: 12 }, { wch: 12 }, { wch: 10 }],
					'Processing': [{ wch: 16 }, { wch: 12 }, { wch: 12 }, { wch: 36 }, { wch: 12 }, { wch: 13 }, { wch: 12 }, { wch: 13 }, { wch: 16 }, { wch: 10 }],
					'Authorization': [{ wch: 10 }, { wch: 16 }, { wch: 10 }, { wch: 14 }, { wch: 15 }, { wch: 10 }, { wch: 12 }, { wch: 10 }],
					'Miscellaneous': [{ wch: 32 }, { wch: 11 }, { wch: 17 }, { wch: 12 }, { wch: 10 }, { wch: 10 }],
					'Fixed Income_Expense': [{ wch: 30 }, { wch: 10 }, { wch: 10 }, { wch: 13 }, { wch: 10 }, { wch: 20 }],
					'Third Party Payout': [{ wch: 15 }, { wch: 14 }, { wch: 12 }, { wch: 16 }]
				},
				data: {
					'Overview': [],
					'Processing': [],
					'Authorization': [],
					'Miscellaneous': [],
					'Fixed Income_Expense': [],
					'Third Party Payout': []
				}
			};

			conf.data.Overview = this.addMerchantResidualDetailOverviewList(merchantHeader, params);
			conf.data.Processing = this.addProcessingList(merchantProcessing);
			conf.data.Authorization = this.addAuthorizationList(merchantProcessing);
			conf.data.Miscellaneous = this.addMiscellaneousList(merchantProcessing);
			conf.data['Fixed Income_Expense'] = this.addFixedIncomeExpenseList(merchantProcessing);
			conf.data['Third Party Payout'] = this.addThirdPartyPayoutList(merchantProcessing);

			return [conf, { title: 'Download Merchant Statement', link: merchantHeader.merchantPortfolio.statementUrl }];
		}
	});
});