define('genesis-residual-system/mirage/fixtures/office-header', ['exports'], function (exports) {
	var officeHeader = {
		'officeEarnings': {
			'officeId': 'CARUK',
			'currentResidual': 28180.15,
			'monthOverMonthResidualPercentChange': -9.06,
			'yearOverYearResidualPercentChange': 18.76,
			'merchantCount': 179,
			'monthOverMonthMerchantPercentChange': -3.24,
			'yearOverYearMerchantPercentChange': -14.35,
			'archiveDate': 'Feb 25 2020 12:00AM',
			'volume': 4410033.75,
			'monthOverMonthVolumePercentChange': -14.6,
			'yearOverYearVolumePercentChange': 8.57,
			'bonusTotal': 50,
			'monthOverMonthBonusPercentChange': -96.55,
			'yearOverYearBonusPercentChange': -99.07,
			'monthlyBonuses': 0,
			'dailyBonuses': 50
		},
		'monthlyVolume': [{
			'cardType': 'Visa',
			'monthOverMonthPercentChange': -14.93,
			'volume': 3083618.63,
			'yearOverYearPercentChange': 9.68
		}, {
			'cardType': 'Mastercard',
			'monthOverMonthPercentChange': -11.57,
			'volume': 870023.32,
			'yearOverYearPercentChange': 9.33
		}, {
			'cardType': 'Discover',
			'monthOverMonthPercentChange': -17.55,
			'volume': 65929.41,
			'yearOverYearPercentChange': 3.44
		}, {
			'cardType': 'American Express',
			'monthOverMonthPercentChange': -19.19,
			'volume': 330003.83,
			'yearOverYearPercentChange': 14.13
		}, {
			'cardType': 'Debit Card',
			'monthOverMonthPercentChange': -16.04,
			'volume': 32865.47,
			'yearOverYearPercentChange': -30.3
		}, {
			'cardType': 'EBT',
			'monthOverMonthPercentChange': -2.02,
			'volume': 27593.09,
			'yearOverYearPercentChange': -49.33
		}, {
			'cardType': 'Total',
			'monthOverMonthPercentChange': -14.6,
			'volume': 4410033.75,
			'yearOverYearPercentChange': 8.57
		}],
		'productResiduals': [{
			'merchantCount': 7,
			'product': 'Echo',
			'residual': 311.9695
		}, {
			'merchantCount': 112,
			'product': 'POS',
			'residual': 23636.2175
		}, {
			'merchantCount': 60,
			'product': 'Terminal',
			'residual': 2290.7285
		}],
		'archives': [{
			'archiveDate': 'Feb 25 2020 12:00AM',
			'amount': 20
		}],
		'merchantCountReport': [{
			'count': 195,
			'month': 'May',
			'year': '2019'
		}, {
			'count': 209,
			'month': 'January',
			'year': '2019'
		}, {
			'count': 185,
			'month': 'December',
			'year': '2019'
		}, {
			'count': 216,
			'month': 'September',
			'year': '2018'
		}, {
			'count': 202,
			'month': 'April',
			'year': '2019'
		}, {
			'count': 236,
			'month': 'February',
			'year': '2018'
		}, {
			'count': 199,
			'month': 'June',
			'year': '2019'
		}, {
			'count': 229,
			'month': 'March',
			'year': '2018'
		}, {
			'count': 192,
			'month': 'September',
			'year': '2019'
		}, {
			'count': 218,
			'month': 'August',
			'year': '2018'
		}, {
			'count': 220,
			'month': 'July',
			'year': '2018'
		}, {
			'count': 189,
			'month': 'October',
			'year': '2019'
		}, {
			'count': 222,
			'month': 'October',
			'year': '2018'
		}, {
			'count': 191,
			'month': 'August',
			'year': '2019'
		}, {
			'count': 221,
			'month': 'May',
			'year': '2018'
		}, {
			'count': 219,
			'month': 'November',
			'year': '2018'
		}, {
			'count': 232,
			'month': 'December',
			'year': '2018'
		}, {
			'count': 193,
			'month': 'July',
			'year': '2019'
		}, {
			'count': 188,
			'month': 'November',
			'year': '2019'
		}, {
			'count': 225,
			'month': 'June',
			'year': '2018'
		}, {
			'count': 207,
			'month': 'February',
			'year': '2019'
		}, {
			'count': 201,
			'month': 'March',
			'year': '2019'
		}, {
			'count': 227,
			'month': 'April',
			'year': '2018'
		}, {
			'count': 243,
			'month': 'January',
			'year': '2018'
		}, {
			'count': 179,
			'month': 'January',
			'year': '2020'
		}],
		'industryBreakdowns': [{
			'chartType': 'POS Portfolio Breakdown',
			'industry': 'Harbortouch Bar & Restaurant',
			'merchantCount': 1
		}, {
			'chartType': 'POS Portfolio Breakdown',
			'industry': 'Harbortouch POS Echo',
			'merchantCount': 7
		}, {
			'chartType': 'POS Portfolio Breakdown',
			'industry': 'Harbortouch POS Hospitality',
			'merchantCount': 107
		}, {
			'chartType': 'POS Portfolio Breakdown',
			'industry': 'Harbortouch POS QSR & Delivery',
			'merchantCount': 1
		}, {
			'chartType': 'POS Portfolio Breakdown',
			'industry': 'Harbortouch POS Retail',
			'merchantCount': 2
		}, {
			'chartType': 'POS Portfolio Breakdown',
			'industry': 'Harbortouch Salon & Spa',
			'merchantCount': 3
		}],
		'brand': {
			'name': 'Harbortouch',
			'logoUrl': 'https://s3.amazonaws.com/shift4-brand-logos/HRB-GRAPHIC001.jpg',
			'footerText': 'Harbortouch® is a registered trademark of Shift4 Payments, LLC. All Rights Reserved. Shift4 Payments, LLC is a registered ISO/MSP of Westamerica Bank, 3750 Westwind Blvd., Suite #210, Santa Rosa, CA 95403.',
			'headerColor': '000000',
			'buttonColor': '0498F3',
			'buttonHighlightColor': '0498F3'
		}
	};

	exports['default'] = officeHeader;
});