define('genesis-residual-system/mixins/intro-js/index-intro-js-mixin', ['exports', 'ember', 'genesis-residual-system/mixins/intro-js/intro-js-mixin'], function (exports, _ember, _genesisResidualSystemMixinsIntroJsIntroJsMixin) {
	exports['default'] = _ember['default'].Mixin.create(_genesisResidualSystemMixinsIntroJsIntroJsMixin['default'], {
		tutorialModel: 'dashboard',
		steps: _ember['default'].computed(function () {
			return [{
				intro: 'Welcome to the Partner Compensation Portal. This walkthrough will help you learn the site.'
			}, {
				element: '.sidebar',
				intro: 'This tray breaks down your residual information. Your Portfolio Earnings is at the top. This includes your monthly residuals ACH(s), The Daily Bonuses Earned over the month (ACHed separately), and Third Party Residuals Earnings. Clicking each header expands the section to provide more detail.',
				step: 'portfolio-earning-step'
			}, {
				element: '.merchantsChart',
				intro: 'This chart detail your active merchants. It include The current year and previous year so you can analyze how your portfolio is growing over time.',
				step: 'merchant-chart-step'
			}, {
				element: '.main > .container-fluid',
				intro: 'The charts on this page give you a high level overview of the performance of your Portfolio and your sales reps.'
			}, {
				element: '.monthFilter',
				intro: 'The Date Selector drives the entire portal. By changing the date the entire site updates to reflect the selected residuals month. Residuals are viewable in this way for 13 months after which you will have to contact your Relationship Manager to view older residuals.'
			}, {
				element: _ember['default'].$('.downloadReport button:visible')[0],
				intro: 'The download button will allow you to download all the information found in this site to an Excel document. Merchant level information can be downloaded as well by navigating to the merchant’s detail page and downloading with this button.'
			}, {
				element: '.relaunchIntro span',
				intro: 'You can relaunch the walkthrough for every page by clicking the Help button.',
				step: 'relaunch-intro-info'
			}];
		}),
		setDashboardVisibilityItems: function setDashboardVisibilityItems(step, hide) {
			_ember['default'].$('#navbar').toggleClass('in', hide ? false : step.step === 'relaunch-intro-info');
		},
		setDashboardHelperNumberPosition: function setDashboardHelperNumberPosition(step) {
			if (step === 'portfolio-earning-step' || step === 'merchant-chart-step') {
				_ember['default'].$('body').addClass('intorjs-tooltip');
			} else {
				_ember['default'].$('body').removeClass('intorjs-tooltip');
			}
		},
		removeIntroJsCustomCSSClass: function removeIntroJsCustomCSSClass() {
			_ember['default'].$('body').removeClass('intorjs-tooltip');
		},
		actions: {
			introBeforeChange: function introBeforeChange(previousStep, nextStep) {
				this.setDashboardVisibilityItems(nextStep);
			},
			introOnAfterChange: function introOnAfterChange(step) {
				this.setDashboardHelperNumberPosition(step.step);
			},
			introOnComplete: function introOnComplete(step) {
				this.setDashboardVisibilityItems(step, true);
				this.removeIntroJsCustomCSSClass();
				this._super.apply(this, arguments);
			}
		}
	});
});