define('genesis-residual-system/mixins/data-table/merchants-data-table-mixin', ['exports', 'ember', 'genesis-residual-system/helpers/format-money', 'genesis-residual-system/helpers/format-number', 'moment', 'genesis-residual-system/mixins/data-table/data-table-mixin'], function (exports, _ember, _genesisResidualSystemHelpersFormatMoney, _genesisResidualSystemHelpersFormatNumber, _moment, _genesisResidualSystemMixinsDataTableDataTableMixin) {
	exports['default'] = _ember['default'].Mixin.create(_genesisResidualSystemMixinsDataTableDataTableMixin['default'], {
		getMerchantPortfolioTabColumns: function getMerchantPortfolioTabColumns(params) {
			return [{ 'sClass': 'text-left', 'mData': 'id', 'mRender': function mRender(data) {
					return '<a data-href="/dashboards/residuals/merchant/' + data + '?date=' + params.accountingPeriod + '" href="javascript:void(0);">' + data + '</a>';
				} }, { 'sClass': 'text-left', 'mData': 'name' }, { 'sClass': 'text-right', 'mData': 'payoutAmount', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data]);
				} }, { 'sClass': 'text-right', 'mData': 'volume', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data]);
				} }, { 'sClass': 'text-right', 'mData': 'sales', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatNumber.formatNumber)(data);
				} }, { 'sClass': 'text-right', 'mData': 'refunds', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatNumber.formatNumber)(data);
				} }, { 'sClass': 'text-left', 'mData': 'salesRepId' }, { 'sClass': 'text-left', 'mData': 'split' }];
		},
		getMerchantPortfolioTabTotalColumns: function getMerchantPortfolioTabTotalColumns(merchantList) {
			var list = merchantList.list.filter(function (item) {
				return !item.isRejected;
			});

			return [{
				2: {
					sum: list.reduce(function (prev, curr) {
						return { payoutAmount: prev.payoutAmount + curr.payoutAmount };
					}, { payoutAmount: 0 }).payoutAmount, formatMoney: true
				}
			}, {
				3: {
					sum: list.reduce(function (prev, curr) {
						return { volume: prev.volume + curr.volume };
					}, { volume: 0 }).volume, formatMoney: true
				}
			}, {
				4: list.reduce(function (prev, curr) {
					return { sales: prev.sales + curr.sales };
				}, { sales: 0 }).sales
			}, {
				5: list.reduce(function (prev, curr) {
					return { refunds: prev.refunds + curr.refunds };
				}, { refunds: 0 }).refunds
			}];
		},
		getMerchantPortfolioTabConfig: function getMerchantPortfolioTabConfig(merchantList, params) {
			var tab = {
				id: 1,
				tabId: 'merchant-portfolio',
				isActive: true,
				name: 'Merchant Portfolio',
				hasTotal: merchantList.list.length > 0,
				totalColumns: this.getMerchantPortfolioTabTotalColumns(merchantList),
				orderColumn: 2,
				headers: ['Merchant ID', 'Merchant Name', 'Payout Amount', 'Volume', 'Sales', 'Refunds', 'Salesrep ID', 'Split'],
				filters: [{ name: 'All Salesrep IDs', id: 'salesRepId', column: 6, type: 'dropdown', data: merchantList.filterData }, { name: 'MID, Merchant Name or Salesrep ID', id: 'searchId', type: 'text' }],
				note: '<span class="redtext">Red</span> text indicates that a Sales Rep has at least one merchant with <span class="redtext">Rejected</span> status',
				columns: this.getMerchantPortfolioTabColumns(params),
				createdRowCallback: function createdRowCallback(nRow, aData) {
					_ember['default'].$('td', nRow).addClass(aData.isRejected ? 'redtext' : '');
				},
				data: merchantList.list,
				columnDefs: [{ width: '10%', targets: 0 }, { width: '26%', targets: 1 }, { width: '10%', targets: [2, 3] }, { width: '7%', targets: [4] }, { width: '6%', targets: [5, 6] }, { width: '25%', targets: [7] }]
			};
			return this.getTabConfig(tab);
		},
		getSalesReportColumns: function getSalesReportColumns() {
			return [{ 'sClass': 'text-left', 'mData': 'id' }, { 'sClass': 'text-left', 'mData': 'name' }, { 'sClass': 'text-left', 'mData': 'merchantCount' }, { 'sClass': 'text-right', 'mData': 'totalPayout', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data]);
				} }];
		},
		getSalesReportTabConfig: function getSalesReportTabConfig(officeInfo, date) {
			var tab = {
				id: 2,
				tabId: 'sales-report',
				name: 'Sales Rep Report for ' + date,
				orderColumn: 2,
				headers: ['Salesrep ID', 'Salesrep Name', '# of Accounts', 'Total Payout'],
				filters: [{ name: 'MID, Merchant Name or Salesrep ID', id: 'searchId', type: 'text' }],
				columns: this.getSalesReportColumns(),
				data: officeInfo.list.get('salesRepReport')
			};
			return this.getTabConfig(tab);
		},
		getMiscAdjustmentTabColumns: function getMiscAdjustmentTabColumns() {
			return [{ 'sClass': 'text-left', 'mData': 'description' }, { 'sClass': 'text-left', 'mData': 'type' }, { 'sClass': 'text-right ', 'mData': 'amount', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, true]);
				} }];
		},
		getMiscAdjustmentTabTotalColumns: function getMiscAdjustmentTabTotalColumns(officeInfo) {
			return [{
				2: { sum: officeInfo.list.get('miscAdjustments').reduce(function (prev, curr) {
						return { amount: prev.amount + curr.amount };
					}, { amount: 0 }).amount, formatMoney: true }
			}];
		},
		getMiscAdjustmentTabConfig: function getMiscAdjustmentTabConfig(officeInfo, date, params) {
			var tab = {
				id: 3,
				tabId: 'misc-adjustments',
				name: 'Miscellaneous Adjustments for ' + date,
				columnDefs: [{ width: '15%', targets: [1, 2] }, { width: '60%', targets: 0 }],
				hasTotal: officeInfo.list.get('miscAdjustments').length > 0,
				totalColumns: this.getMiscAdjustmentTabTotalColumns(officeInfo),
				headers: ['Description', 'Type', 'Amount'],
				filters: [{ name: 'All Misc Adjustments', id: 'typeId', column: 1, type: 'dropdown', data: officeInfo.miscAdjustmentsFilterData, selected: params.type }, { name: 'MID, Merchant Name or Salesrep ID', id: 'searchId', type: 'text' }],
				note: 'Items in <span class="greentxt">Green</span> are positive and items in <span class="redtext">Red</span> are negative',
				columns: this.getMiscAdjustmentTabColumns(),
				createdRowCallback: function createdRowCallback(nRow, aData) {
					_ember['default'].$('td:eq(2)', nRow).addClass(aData.amount >= 0 ? 'greentxt' : 'redtext');
				},
				data: officeInfo.list.get('miscAdjustments')
			};
			return this.getTabConfig(tab);
		},
		getBonusesTabColumns: function getBonusesTabColumns(params) {
			return [{ 'sClass': 'text-left', 'mData': 'merchantId', 'mRender': function mRender(data) {
					return '<a data-href="/dashboards/residuals/merchant/' + data + '?date=' + params.accountingPeriod + '" href="javascript:void(0);">' + data + '</a>';
				} }, { 'sClass': 'text-left', 'mData': 'merchantName' }, { 'sClass': 'text-left', 'mData': 'type' }, { 'sClass': 'text-right', 'mData': 'amount', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, true]);
				} }, { 'sClass': 'text-left', 'mData': 'datePaid', 'mRender': function mRender(data) {
					return (0, _moment['default'])(data).format('MM/DD/YYYY');
				} }, { 'sClass': 'text-left', 'mData': 'salesRepId' }];
		},
		getBonusesTabTotalColumns: function getBonusesTabTotalColumns(officeInfo) {
			return [{
				3: { sum: officeInfo.list.get('bonuses').reduce(function (prev, curr) {
						return { amount: prev.amount + curr.amount };
					}, { amount: 0 }).amount, formatMoney: true }
			}];
		},
		getBonusesTabConfig: function getBonusesTabConfig(officeInfo, date, params) {
			var tab = {
				id: 4,
				tabId: 'bonuses',
				name: 'Bonuses for ' + date,
				columnDefs: [{ width: '12%', targets: [0, 3, 4, 5] }, { width: '30%', targets: 1 }, { width: '22%', targets: 2 }],
				hasTotal: officeInfo.list.get('bonuses').length > 0,
				totalColumns: this.getBonusesTabTotalColumns(officeInfo),
				orderColumn: 3,
				headers: ['Merchant ID', 'Merchant Name', 'Bonus Type', 'Payout Amount', 'Payout Date', 'Salesrep ID'],
				filters: [{ name: 'All Daily Bonuses', id: 'bonusTypeId', column: 2, type: 'dropdown', data: officeInfo.bonusesFilterData }, { name: 'MID, Merchant Name or Salesrep ID', id: 'searchId', type: 'text' }],
				columns: this.getBonusesTabColumns(params),
				data: officeInfo.list.get('bonuses')
			};
			return this.getTabConfig(tab);
		},
		getThirdPartyResidualsTabColumns: function getThirdPartyResidualsTabColumns(params) {
			return [{ 'sClass': 'text-left', 'mData': 'vendor', 'mRender': function mRender(data) {
					return '<a data-href="/dashboards/residuals/third-party-residual/' + data + '?date=' + params.accountingPeriod + '" href="javascript:void(0);">' + data + '</a>';
				} }, { 'sClass': 'text-right', 'mData': 'payout', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, true]);
				} }, { 'sClass': 'text-right', 'mData': 'volume', 'mRender': function mRender(data) {
					return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([data, true]);
				} }, { 'sClass': 'item', 'mData': 'Merchants' }];
		},
		getThirdPartyResidualsTabTotalColumns: function getThirdPartyResidualsTabTotalColumns(officeInfo) {
			return [{
				1: { sum: officeInfo.totalThirdPartyPayout, formatMoney: true }
			}];
		},
		getThirdPartyResidualsTabConfig: function getThirdPartyResidualsTabConfig(officeInfo, date, params) {
			var tab = {
				id: 5,
				tabId: 'third-party-residuals',
				name: 'Third Party Residuals for ' + date,
				hasTotal: officeInfo.list.get('thirdPartyReport').length > 0,
				totalColumns: this.getThirdPartyResidualsTabTotalColumns(officeInfo),
				orderColumn: 2,
				headers: ['Name', 'Payout Amount', 'Volume', '# of Merchants'],
				columns: this.getThirdPartyResidualsTabColumns(params),
				data: officeInfo.list.get('thirdPartyReport')
			};
			return this.getTabConfig(tab);
		},
		getResponsiveTabsConfig: function getResponsiveTabsConfig(merchantList, officeInfo, params) {
			var currentDate = (0, _moment['default'])(params.accountingPeriod, 'YYYY-MM').format('MMMM YYYY');

			return {
				selectedDate: params.accountingPeriod,
				tabId: params.tabId,
				type: params.type,
				tabs: [this.getMerchantPortfolioTabConfig(merchantList, params), this.getSalesReportTabConfig(officeInfo, currentDate), this.getMiscAdjustmentTabConfig(officeInfo, currentDate, params), this.getBonusesTabConfig(officeInfo, currentDate, params), this.getThirdPartyResidualsTabConfig(officeInfo, currentDate, params)]
			};
		}
	});
});