define('genesis-residual-system/highcharts/sales-rep-performance-highchart', ['exports', 'ember', 'genesis-residual-system/helpers/format-money', 'genesis-residual-system/highcharts/highchart'], function (exports, _ember, _genesisResidualSystemHelpersFormatMoney, _genesisResidualSystemHighchartsHighchart) {
	exports['default'] = _ember['default'].Object.extend(_genesisResidualSystemHighchartsHighchart['default'], {
		perShapeGradient: {
			x1: 1,
			y1: 0,
			x2: 0,
			y2: 0
		},
		color: _ember['default'].computed('perShapeGradient', function () {
			return {
				linearGradient: this.get('perShapeGradient'),
				stops: [[0, 'rgb(93, 173, 250)'], [1, 'rgb(4, 102, 193)']]
			};
		}),
		getChartData: function getChartData() {
			var result = this._super.apply(this, arguments);
			return result.sort(function (a, b) {
				return b.y - a.y;
			});
		},
		getChartDataFormat: function getChartDataFormat(item) {
			return { name: item.id, y: item.totalPayout, z: item.merchantCount, color: this.get('color') };
		},
		getTooltipConfig: function getTooltipConfig() {
			return {
				style: {
					fontSize: '14px'
				},
				formatter: function formatter() {
					return this.key + ': ' + (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([this.y]);
				}
			};
		},
		getYAxisLabelsConfig: function getYAxisLabelsConfig() {
			var config = this.getAxisLabelsConfig();
			config.formatter = function () {
				return (0, _genesisResidualSystemHelpersFormatMoney.formatMoney)([this.value]);
			};
			return config;
		}
	});
});