define('genesis-residual-system/highcharts/merchant-count-highchart', ['exports', 'ember', 'genesis-residual-system/highcharts/highchart'], function (exports, _ember, _genesisResidualSystemHighchartsHighchart) {
	var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

	exports['default'] = _ember['default'].Object.extend(_genesisResidualSystemHighchartsHighchart['default'], {
		categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
		filteredCategories: null,
		colors: ['#7cb5ec', '#90ed7d', '#f7a35c'],
		getChartConfig: function getChartConfig() {
			return {
				type: 'line',
				style: {
					fontFamily: 'SofiaPro'
				}
			};
		},
		getLegendConfig: function getLegendConfig() {
			return {
				itemStyle: {
					color: '#666'
				}
			};
		},
		getTooltipConfig: function getTooltipConfig() {
			return {
				style: {
					fontSize: '14px',
					color: '#666'
				},
				formatter: function formatter() {
					return this.y;
				}
			};
		},
		getXAxisConfig: function getXAxisConfig() {
			return {
				categories: this.filteredCategories
			};
		},
		getYAxisConfig: function getYAxisConfig() {
			return {
				title: null
			};
		},
		getPlotOptions: function getPlotOptions() {
			return {
				series: {
					borderWidth: 0,
					marker: {
						fillColor: '#FFFFFF',
						lineWidth: 2,
						// inherit from series
						lineColor: null
					}
				}
			};
		},
		getChartOptionsConfig: function getChartOptionsConfig() {
			var config = this._super.apply(this, arguments);
			config.colors = this.colors;
			config.plotOptions = this.getPlotOptions();
			return config;
		},
		setLineColor: function setLineColor(series) {
			var colors = ['#7cb5ec', '#90ed7d', '#f7a35c'];
			var index = 0;
			var _iteratorNormalCompletion = true;
			var _didIteratorError = false;
			var _iteratorError = undefined;

			try {
				for (var _iterator = series[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
					var item = _step.value;

					_ember['default'].$.extend(item, { color: colors[index++] });
				}
			} catch (err) {
				_didIteratorError = true;
				_iteratorError = err;
			} finally {
				try {
					if (!_iteratorNormalCompletion && _iterator['return']) {
						_iterator['return']();
					}
				} finally {
					if (_didIteratorError) {
						throw _iteratorError;
					}
				}
			}
		},
		setCategories: function setCategories(start, end) {
			this.filteredCategories = this.categories.slice(start, end);
		},
		setEmptyData: function setEmptyData(data, min, max) {
			for (var i = 0; i < max - min; i++) {
				if (!data[i]) {
					data[i] = null;
				}
			}
		},
		getSeries: function getSeries(map, min, max) {
			var series = [];
			var _iteratorNormalCompletion2 = true;
			var _didIteratorError2 = false;
			var _iteratorError2 = undefined;

			try {
				for (var _iterator2 = map[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
					var _step2$value = _slicedToArray(_step2.value, 2);

					var key = _step2$value[0];
					var value = _step2$value[1];

					var data = [];

					var _iteratorNormalCompletion3 = true;
					var _didIteratorError3 = false;
					var _iteratorError3 = undefined;

					try {
						for (var _iterator3 = value[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
							var item = _step3.value;

							data[item.index - min] = item.count;
						}
					} catch (err) {
						_didIteratorError3 = true;
						_iteratorError3 = err;
					} finally {
						try {
							if (!_iteratorNormalCompletion3 && _iterator3['return']) {
								_iterator3['return']();
							}
						} finally {
							if (_didIteratorError3) {
								throw _iteratorError3;
							}
						}
					}

					this.setEmptyData(data, min, max);

					series.push({ name: key, data: data, lineWidth: 3.5 });
				}
			} catch (err) {
				_didIteratorError2 = true;
				_iteratorError2 = err;
			} finally {
				try {
					if (!_iteratorNormalCompletion2 && _iterator2['return']) {
						_iterator2['return']();
					}
				} finally {
					if (_didIteratorError2) {
						throw _iteratorError2;
					}
				}
			}

			series = series.sort(function (a, b) {
				return a.name < b.name ? 1 : a.name > b.name ? -1 : 0;
			});
			return series;
		},
		createMapAndCalculateMinAndMax: function createMapAndCalculateMinAndMax() {
			var min = this.categories.length,
			    max = 0;
			var map = new Map();

			var _iteratorNormalCompletion4 = true;
			var _didIteratorError4 = false;
			var _iteratorError4 = undefined;

			try {
				for (var _iterator4 = this.data[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
					var item = _step4.value;

					var _name = item.year,
					    index = this.categories.indexOf(item.month);

					if (typeof map.get(_name) === 'undefined') {
						map.set(_name, []);
					}

					map.get(_name).push({ index: index, count: item.count });

					if (index < min) {
						min = index;
					}
					if (index > max) {
						max = index;
					}
				}
			} catch (err) {
				_didIteratorError4 = true;
				_iteratorError4 = err;
			} finally {
				try {
					if (!_iteratorNormalCompletion4 && _iterator4['return']) {
						_iterator4['return']();
					}
				} finally {
					if (_didIteratorError4) {
						throw _iteratorError4;
					}
				}
			}

			return {
				map: map,
				min: min,
				max: max
			};
		},
		getChartData: function getChartData() {
			var result = this.createMapAndCalculateMinAndMax(),
			    series = this.getSeries(result.map, result.min, result.max);

			this.setLineColor(series);
			this.setCategories(result.min, result.max - result.min + 1);

			return series;
		},
		getChartDataConfig: function getChartDataConfig() {
			return this.getChartData();
		},
		getHighchartConfig: function getHighchartConfig() {
			return {
				chartData: this.getChartDataConfig(),
				chartOptions: this.getChartOptionsConfig()
			};
		}
	});
});