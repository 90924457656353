define('genesis-residual-system/routes/dashboards/residuals/merchant', ['exports', 'ember', 'genesis-residual-system/mixins/residuals-route-mixin', 'genesis-residual-system/mixins/routes/dashboards/residuals/merchant-mixin'], function (exports, _ember, _genesisResidualSystemMixinsResidualsRouteMixin, _genesisResidualSystemMixinsRoutesDashboardsResidualsMerchantMixin) {
	exports['default'] = _ember['default'].Route.extend(_genesisResidualSystemMixinsResidualsRouteMixin['default'], _genesisResidualSystemMixinsRoutesDashboardsResidualsMerchantMixin['default'], {
		queryParams: {
			date: {
				refreshModel: true
			}
		},
		session: _ember['default'].inject.service('session'),
		model: function model(params) {
			var _this = this;

			_ember['default'].$('.loading').fadeIn();

			var model = this.modelFor('dashboards.residuals');

			if (!model) {
				this.transitionTo('dashboards.residuals');
			}

			var filterParams = this.getFilterParams(params);

			return _ember['default'].RSVP.hash({
				merchantHeader: this.store.queryRecord('merchant-header', filterParams.merchantFilterParams),
				merchantProcessing: this.store.queryRecord('merchant-processing', filterParams.merchantFilterParams)
			}).then(function (response) {
				return _this.buildModel(response.merchantHeader.data, {
					list: response.merchantProcessing.data.list,
					filterData: response.merchantProcessing.get('uniqCardTypes')
				}, model.residualMonths, filterParams);
			});
		},
		setupController: function setupController(controller, model) {
			controller.set('missingResiduals', false);
			controller.set('model', model);
		},
		actions: {
			setDate: function setDate(date) {
				var _this2 = this;

				_ember['default'].$('.loading').fadeIn();

				var params = this.getOfficeAndMerchantFilterParams(this.currentModel, date),
				    requests = this.getOfficeAndMerchantDetailsRequests(this.store, params);

				_ember['default'].RSVP.hash(requests).then(function (response) {
					var model = _this2.buildModel(response.merchantHeader.data, {
						list: response.merchantProcessing.data.list,
						filterData: response.merchantProcessing.get('uniqCardTypes')
					}, response.residualMonths.data.list, params);

					if (response.merchantHeader.data.merchantPortfolio.id != null) {
						_this2.updateModel(model);
					} else {
						_this2.get('controller').set('missingResiduals', true);
					}

					var sidebarFilterParams = _this2.getSidebarFilterParams(params);
					_this2.send('updateSidebar', response.officeHeader.data, response.officeInfo, response.residualMonths.data.list, sidebarFilterParams);
					_this2.send('updateExportDataConfig', model.exportData);

					_ember['default'].$('.loading').fadeOut();
				});
			},
			hideMissingResidualsModal: function hideMissingResidualsModal() {
				this.get('controller').set('missingResiduals', false);
			}
		}
	});
});